import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
// import Slider from '@material-ui/lab/Slider';
// import deburr from 'lodash/deburr';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormGroup from '@material-ui/core/FormGroup';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
// import { Range } from 'rc-slider';
// import 'rc-slider/assets/index.css';
import NumberFormat from 'react-number-format';
import { multilingual } from '@hoc/multilingual';
import { request } from 'lib/http-services';
const { currencyFormating, toHyphens } = require('lib/helper');
const config = require('config');
// const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.fullTitle, query);
    const parts = parse(suggestion.fullTitle, matches);
  
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
            {parts.map((part, index) =>
                part.highlight ? (
                    <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                    </span>
                ) : (
                    <strong key={String(index)} style={{ fontWeight: 300 }}>
                        {part.text}
                    </strong>
                ),
            )}
        </div>
      </MenuItem>
    );
}

function getSuggestionValue(suggestion) {
    return suggestion.fullTitle;
}

function StartAdornment(props) {
    return (
        <span style={{marginRight: '1rem'}}>{props.children}</span>
    )
}
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            // suffix=" vnđ"
        />
    );
}
NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const styles = theme => ({
    root: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        ...theme.mixins.gutters(),
        // paddingTop: theme.spacing.unit * 2,
        // paddingBottom: theme.spacing.unit * 2,
        // padding: '0 12px 0 24px !important',
        // 'border-bottom': '1px solid #FFED38',
        'border-radius': 0,
        padding: '0 !important',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'space-between',
        // width: 'auto',
        margin: '0 auto',
        backgroundColor: 'transparent',
        'box-shadow': 'none',
        width: '800px'
    },
    mobileRoot: {
        padding: '1rem 1rem 0',
        backgroundColor: 'transparent',
        'box-shadow': 'none',
        width: 'calc(100% - 15px * 2)'
    },
    searchbox: {
        // width: '400px'
    },
    tabsRoot: {
        // borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
        backgroundColor: '#1890ff',
        display: 'none'
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing.unit * 4,
        // backgroundColor: theme.palette.background.paper,
        // backgroundColor: 'rgba(255, 255, 255, 0.5)',
        // backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        color: '#fff',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            // color: '#40a9ff',
            // opacity: 1,
            fontWeight: 'bold',
        },
        '&$tabSelected': {
            // color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        // '&:focus': {
        //     color: '#40a9ff',
        // },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
    tabContent: {
        // backgroundColor: theme.palette.background.paper,
        // backgroundColor: 'rgba(255, 255, 255, 0.5)',
        // backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    // textField: {
    //     // marginLeft: theme.spacing.unit,
    //     marginRight: theme.spacing.unit,
    //     // width: 200,
    //     backgroundColor: 'rgba(255, 255, 255, 0.7)',
    //     textAlign: 'left',
    //     paddingLeft: '1rem',
    //     marginLeft: `calc(${theme.spacing.unit}px - 1rem)`
    // },
    margin: {
        margin: theme.spacing.unit,
    },
    withoutLabel: {
        marginTop: `${theme.spacing.unit * 3}px`,
    },
    textField: {
        flexBasis: 200,
        '& *': {
            color: '#ddd',
            cursor: 'pointer !important'
        }
    },
    textFieldInside: {
        color: '#ddd',
        fontSize: '0.875rem',
        '&::before': {
            // borderBottom: `1px solid ${theme.palette.primary.main} !important`,
            borderBottom: '1px solid #ddd !important'
        },
        // '&::after': {
        //     display: 'none'
        // },
        '& *:focus': {
            backgroundColor: 'transparent'
        }
    },
    searchButton: {
        float: 'right',
        marginLeft: '24px',
        backgroundColor: '#FFED38',
        'text-transform': 'inherit',
        // 'border-radius': 0
    },
    slider: {
        // '& .rc-slider-track': {
        //     'background-color': '#f00'
        // },
        // '& .rc-slider-handle': {
        //     border: 'solid 2px #f00'
        // },
        // '& .rc-slider-handle:active': {
        //     'border-color': '#f00',
        //     'box-shadow': '0 0 5px #f00'
        // },
        // '& .rc-slider-handle:hover': {
        //     'border-color': '#f00'
        // },
        // '& .rc-slider-handle:focus': {
        //     'border-color': '#f00'
        // }
    },
    sliderLabel: {
        'font-weight': 'bold',
        color: '#fff',
        'margin-bottom': '5px',
        '&>span': {
            color: '#ccc'
        }
    },
    sliderWrapper: {
        padding: '12px 7px !important',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'flex-start',
        'justify-content': 'flex-end'
    },
    radioGroup: {
        display: 'flex',
        'flex-direction': 'row',
        [theme.breakpoints.up('sm')]: {
            margin: `${theme.spacing.unit}px 0`,
        },
        '& *': {
            color: '#ddd',
        }
    },
    hDivider: {
        width: '1px',
        // height: '50px',
        height: '45px',
        margin: '0 1rem',
        backgroundColor: '#ddd'
    },
    vDivider: {
        margin: '1rem 0 0',
        // 'background-color': '#ddd',
        margin: '1rem 0 0',
        'background-color': '#FFED38',
        width: '100%',
        'background-color': '#fff',
        [theme.breakpoints.up('sm')]: {
            width: '800px',
            margin: '0 0 1rem 0',
            'background-color': '#FFED38',
        }
    },
    underline: {
        // borderBottom: '2px solid #f00',
        '&:after': {
            // The source seems to use this but it doesn't work
            borderBottom: '2px solid #f00',
        },
    },
    icon: {
        color: '#767676'
    },
    suggestionsContainer: {
        position: 'relative',
        [theme.breakpoints.up(600)]: {
            width: '450px'
        },
        '& *': {
            color: '#ddd',
        }
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
        '&>div>div': {
            'white-space': 'nowrap',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'max-width': '100%'
        }
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        '& *': {
            color: '#000'
        }
    },
    filterIcon: {
        color: theme.palette.primary.main
    },
    popper: {
        // padding: '1rem 1rem 1rem 1.5rem',
        // padding: '1.5rem 1rem 1rem 1.5rem',
        padding: '1.5rem 1rem 1rem'
    },
    popperTitle: {
        // 'margin-bottom': '1rem',
        // 'margin-bottom': '2rem'
        // padding: '.6rem calc(1rem - 10px) .6rem 1rem',
        padding: '.6rem calc(1rem - 10px) .6rem 0',
        height: '29.63px'
    },
    popperFlexActionRow: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'flex-end',
        'margin-top': '1rem',
        // 'margin-top': '2rem'
    },
    button: {
        'border-radius': 0,
        '&:not(:last-of-type)': {
            'margin-right': '5px'
        }
    },
    applyButton: {
        // 'border-color': '#FF0000',
        // color: '#fff',
        // 'margin-top': '24px',
        backgroundColor: '#FFED38',
        // '&>span>span': {
        //     'margin-right': '1rem'
        // },
        '&:hover': {
            'background-color': '#eedc27',
            // color: '#fff'
        }
    },
    filterButton: {
        padding: '.6rem calc(1rem - 10px) .6rem 1rem',
        'background-color': 'inherit',
        border: 'none',
        'box-shadow': 'none',
        color: '#4E4E4E',
        'text-transform': 'inherit',
        'font-weight': 'normal',
        margin: 0,
        width: 'auto',
        '&>*': {
            fontSize: '0.875rem'
        },
        '&>span': {
            padding: '2.8px'
        },
        '&>span>span': {
            'margin-left': 'calc(1rem - 10px)'
        }
    },
    sliderWrapper: {
        padding: `${theme.spacing.unit / 2}px 7px`
    },
    slider: {
        // width: '300px'
    },
    marginNormal: {
        'margin-top': '16px',
        'margin-bottom': '8px'
    },
});

function TabContainer({ children, className }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }} className={className}>
            {children}
        </Typography>
    );
}

@multilingual
class SearchOverlay extends Component {
    state = {
        tab: 0,
        area: [0, 50],
        price: [0, 400000000000],
        propertyTypes: [],
        provinces: [],
        province: null,
        propertyType: '-1',
        numOfBathrooms: -1,
        numOfBedrooms: -1,
        propertyGroup: 'sale',
        suggestions: [],
        keyword: '',
        priceRangeAnchorEl: null,
        currencies: [],
        currency: null,
        units: [],
        priceUnit: null,
        filteredCurrency: 'vnd',
        filteredPriceUnit: 'sqm',
        filteredPrice: [0, 400000000000],
        filteredPriceMin: 0,
        filteredPriceMax: 400000000000,
    }

    componentDidMount() {
        this.getPropertyTypes();
        this.getProvinces();
        this.getCurrencies();
        this.getUnits();
    }

    shouldComponentUpdate() {
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.getPropertyTypes();
    }

    render() {
        const { className, classes, dictionary } = this.props;
        const {
            propertyGroup,
            suggestions,
            keyword,
            propertyType,
            propertyTypes,
            // numOfBathrooms,
            numOfBedrooms,
            price,
            priceRangeAnchorEl,
            priceUnit,
            filteredPriceUnit,
            currencies,
            currency,
            filteredCurrency,
            units,
            // filteredPrice,
            filteredPriceMin,
            filteredPriceMax,
        } = this.state;
        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
        };
        // const selectedPriceUnit = units.find(u => u._id === priceUnit);
        // const selectedCurrency = currencies.find(c => c._id === currency);

        return (
            <React.Fragment>
                <Hidden smDown>
                    <Paper className={classes.root} elevation={1}>
                        <RadioGroup
                            aria-label="Property group"
                            name="propertyGroup"
                            className={classes.radioGroup}
                            value={propertyGroup}
                            onChange={this.handleChangePropertyGroup}
                        >
                            <FormControlLabel value="sale" control={<Radio />} label={dictionary['Sales']} />
                            <FormControlLabel value="rent" control={<Radio />} label={dictionary['Lease']} />
                        </RadioGroup>
                        <Divider className={classes.hDivider} />
                        <Autosuggest
                            {...autosuggestProps}
                            inputProps={{
                                classes,
                                placeholder: dictionary['Search by location or keyword'],
                                value: keyword,
                                onChange: this.handleChangeKeyword,
                            }}
                            theme={{
                                container: classes.suggestionsContainer,
                                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                suggestionsList: classes.suggestionsList,
                                suggestion: classes.suggestion,
                            }}
                            renderSuggestionsContainer={options => (
                                <Paper {...options.containerProps} square>
                                    {options.children}
                                </Paper>
                            )}
                            onSuggestionSelected={this.onSuggestionSelected}
                        />
                        <Button variant="contained" size="large" className={classes.searchButton} onClick={this.search({})}>
                            {dictionary['Find'] || ''}
                        </Button>
                    </Paper>
                    <Divider className={classes.vDivider} />
                    <Paper className={classes.root} elevation={1}>
                        <Grid container spacing={24} direction="row" alignItems="center" justify="space-between">
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    select
                                    // label="Loại bất động sản"
                                    className={classNames(classes.textField, 'select-invert')}
                                    value={propertyType}
                                    onChange={this.handleChange('propertyType')}
                                    InputProps={{
                                        className: classes.textFieldInside,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className={classes.filterIcon}>home</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value="-1">
                                        {dictionary['Type of property']}
                                    </MenuItem>
                                    {propertyTypes.map(option => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    select
                                    // label="Loại bất động sản"
                                    className={classNames(classes.textField, 'select-invert')}
                                    value={numOfBedrooms}
                                    onChange={this.handleChange('numOfBedrooms', 'number')}
                                    InputProps={{
                                        className: classes.textFieldInside,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className={classes.filterIcon}>home</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Number of bedrooms']}
                                    </MenuItem>
                                    {Array.from(Array(5).keys()).map(v => v + 1).map(v => Object({
                                        value: v,
                                        label: v
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    select
                                    // label="Loại bất động sản"
                                    className={classNames(classes.textField, 'select-invert')}
                                    value="-1"
                                    // onChange={this.handleChange('numOfBathrooms')}
                                    InputProps={{
                                        className: classes.textFieldInside,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className={classes.filterIcon}>home</Icon>
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    disabled
                                    onClick={this.handleOpenPriceFilter}
                                >
                                    <MenuItem value="-1">
                                        {dictionary['Price range']}
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Paper>
                </Hidden>
                <Hidden mdUp>
                    <Paper className={classes.mobileRoot} elevation={1}>
                        <Grid container direction="column" alignItems="center" justify="space-between">
                            <Grid item xs={12} container alignItems="center" justify="space-between">
                                <Autosuggest
                                    {...autosuggestProps}
                                    inputProps={{
                                        classes,
                                        placeholder: dictionary['Search by location or keyword'],
                                        value: keyword,
                                        onChange: this.handleChangeKeyword,
                                    }}
                                    theme={{
                                        container: classes.suggestionsContainer,
                                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                        suggestionsList: classes.suggestionsList,
                                        suggestion: classes.suggestion,
                                    }}
                                    renderSuggestionsContainer={options => (
                                        <Paper {...options.containerProps} square>
                                            {options.children}
                                        </Paper>
                                    )}
                                    onSuggestionSelected={this.onSuggestionSelected}
                                />
                                <Button variant="contained" size="large" className={classes.searchButton} onClick={this.search({})}>
                                    {dictionary['Find'] || ''}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item item xs={12}>
                            <Divider className={classes.vDivider} />
                        </Grid>
                        <Grid item item xs={12} container alignItems="center" justify="space-around">
                            <RadioGroup
                                aria-label="Property group"
                                name="propertyGroup"
                                className={classes.radioGroup}
                                value={propertyGroup}
                                onChange={this.handleChangePropertyGroup}
                            >
                                <FormControlLabel value="sale" control={<Radio />} label={dictionary['Sales']} />
                                <FormControlLabel value="rent" control={<Radio />} label={dictionary['Lease']} />
                            </RadioGroup>
                        </Grid>
                    </Paper>
                </Hidden>
                <Popover 
                    open={Boolean(priceRangeAnchorEl)}
                    anchorEl={priceRangeAnchorEl}
                    onClose={this.handleClosePriceFilter}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <FormControl className={classes.popper}>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item>
                                {/* <Typography id="label" align="left" className={classes.popperTitle}><span>{dictionary['Price']}: </span>{(dictionary['From * to *'] || '').replace(/\$0/g, filteredPriceMin).replace(/\$1/g, filteredPriceMax)} {selectedCurrency ? selectedCurrency.code : ''}{selectedPriceUnit ? ` / ${selectedPriceUnit.title}` : ''}</Typography> */}
                                <Typography id="label" align="left" className={classes.popperTitle}>{dictionary['Price']}: </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                            {/* <Grid item>
                                <Typography id="label" align="left" className={classNames(classes.popperTitle, classes.marginNormal)}><span>{dictionary['Price']}: </span>{(dictionary['From * to *'] || '').replace(/\$0/g, filteredPrice[0].toLocaleString('en')).replace(/\$1/g, filteredPrice[1].toLocaleString('en'))}{selectedCurrency ? selectedCurrency.name : ''}{this.props.params.group === 'rent' && selectedPriceUnit ? ` /${selectedPriceUnit.tile}` : ''}</Typography>
                            </Grid> */}
                            {/* <Grid item>
                                <Typography id="label" align="left" className={classes.filterButton}><span>{dictionary['Price']}: </span></Typography>
                            </Grid> */}
                            <Grid item>
                                <Input
                                    id="priceMin"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredPriceMin}
                                    // error={'price' in errors}
                                    onBlur={e => {
                                        let changedPrice = {}
                                        const changedPriceMin = Number(e.target.value.replace(/,/g, ''));
                                        changedPrice.filteredPriceMin = changedPriceMin;
                                        if (filteredPriceMax < changedPriceMin) changedPrice.filteredPriceMax = changedPriceMin;
                                        this.setState({
                                            ...changedPrice
                                        })
                                    }}
                                    startAdornment={<StartAdornment>{dictionary['From']}</StartAdornment>}
                                    inputComponent={NumberFormatCustom}
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    id="priceMax"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredPriceMax}
                                    // error={'priceMax' in errors}
                                    onBlur={e => {
                                        this.setState({
                                            filteredPriceMax: Number(e.target.value.replace(/,/g, ''))
                                        })
                                    }}
                                    startAdornment={<StartAdornment>{dictionary['To']}</StartAdornment>}
                                    inputComponent={NumberFormatCustom}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredCurrency || -1}
                                    onChange={e => {
                                        this.setState({
                                            filteredCurrency: e.target.value
                                        });
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Currency'] || ''}
                                    </MenuItem>
                                    {currencies.map(p => ({
                                        value: p._id,
                                        label: p.name
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredPriceUnit || -1}
                                    onChange={e => {
                                        this.setState({
                                            filteredPriceUnit: e.target.value
                                        });
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Item Unit'] || ''}
                                    </MenuItem>
                                    {units.map(p => ({
                                        value: p._id,
                                        label: p.title
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <div className={classes.popperFlexActionRow}>
                            <Button variant="text" className={classNames(classes.button, classes.dismissButton)} onClick={() => this.setState({
                                // filteredPrice: price,
                                filteredPriceMin: price[0],
                                filteredPriceMax: price[1],
                                filteredPriceUnit: priceUnit,
                                filteredCurrency: currency
                            }, this.handleClosePriceFilter)}>
                                {dictionary['Cancel'] || ''}
                            </Button>
                            <Button variant="contained" className={classNames(classes.button, classes.applyButton)} onClick={() => {
                                this.setState({
                                    // price: filteredPrice,
                                    price: [filteredPriceMin, filteredPriceMax],
                                    priceUnit: filteredPriceUnit,
                                    currency: filteredCurrency
                                })
                                this.handleClosePriceFilter();
                            }}>
                                {dictionary['Apply'] || ''}
                            </Button>
                        </div>
                    </FormControl>
                </Popover>
            </React.Fragment>
        );
    }

    renderInputComponent = (inputProps) => {
        const { dictionary } = this.props
        const { classes, inputRef = () => {}, ref, ...other } = inputProps;
      
        return (
            <Input
                fullWidth
                // inputProps={{
                //     inputRef: node => {
                //         ref(node);
                //         inputRef(node);
                //         this.fSearchbox = node
                //     },
                //     classes: {
                //         input: classes.input,
                //     },
                // }}
                inputRef={node => {
                    ref(node);
                    inputRef(node);
                    this.fSearchbox = node
                }}
                placeholder={dictionary['Search by location or keyword']}
                className={classes.searchbox}
                disableUnderline
                // classes={{
                //     root: classes.searchbox,
                //     underline: classes.underline
                // }}
                required
                autoComplete="off"
                // fullWidth
                // defaultValue={queries.title || ''}
                // onKeyPress={e => e.key === 'Enter' && this.search()}
                {...other}
            />
        );
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        const {
            propertyGroup
        } = this.state;
        // const { page, limit } = this.state.pagination;
        let q = {
            page: 1,
            limit: 10,
            keyword: value
        };
        // if(propertyGroup) q['group'] = propertyGroup;
        request('/projects', {
            params: q,
            headers: {
                'language': localStorage.getItem('lang'),
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    suggestions: res.body.result.map(el => ({
                        ...el,
                        fullTitle: `${el.title}${el.location && el.location.formattedAddress ? ` - ${el.location.formattedAddress || ''}` : ''}`
                    })),
                    // pagination: res.body.meta || {}
                });
            }
        }).catch(err => {
        });
    };
    
    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        // this.showDetail(suggestion);
        // this.search({ project: suggestion, keyword: suggestion.title })();
        this.search({ project: suggestion })();
    }
    
    handleChangeKeyword = (event, { newValue }) => {
        this.setState({
            keyword: newValue,
        });
    };
    
    getPropertyTypes = () => {
        request('/property-types', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    propertyTypes: res.body.result
                });
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getProvinces = () => {
        return request('/provinces', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        provinces: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getCurrencies = () => {
        return request('/currencies', {
            headers: {
                'language': localStorage.getItem('lang'),
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        currencies: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getUnits = () => {
        return request('/units', {
            headers: {
                'language': localStorage.getItem('lang'),
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        units: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    }

    // handleAreaChange = (event, value) => {
    //     this.setState({ area: value });
    // }

    // handlePriceChange = (event, value) => {
    //     this.setState({ price: value });
    // }

    handleAreaChange = area => {
        this.setState({ area });
    }

    handlePriceChange = price => {
        this.setState({ filteredPrice: price });
    }

    handleChange = (field, type) => e => this.setState({
        [field]: e.target.value
    })

    // handleChangePropertyGroup = propertyGroup => {
    //     this.setState({ propertyGroup });
    // }

    handleChangePropertyGroup = event => {
        this.setState({ propertyGroup: event.target.value });
    }

    search = ({ keyword, project }) => () => {
        const {
            propertyGroup,
            propertyType,
            // province,
            // numOfBathrooms,
            numOfBedrooms,
            // area,
            price
            // suggestions
        } = this.state;
        let q = {};
        if(propertyGroup) q['group'] = propertyGroup;
        if(project) q['project'] = project._id;
        // else {
        //     let qq = {
        //         page: 1,
        //         limit: 10,
        //         keyword
        //     };
        //     // if(propertyGroup) q['group'] = propertyGroup;
        //     fetch(`${restEndpoint}/projects${!!qq && Object.keys(qq).length > 0 ? '?' + Object.keys(qq).map(k => `${k}=${qq[k]}`).join('&') : ''}`, {
        //         headers: {
        //             'language': localStorage.getItem('lang'),
        //             'x-api-key': apiKey
        //         }
        //     })
        //     .then(res => res.json().then(data => ({
        //         status: res.status,
        //         body: data
        //     })))
        //     .then(res => {
        //         if(!res.stack) {
        //             if(res.status >= 400);
        //             else {
        //                 console.log(res.body.result)
        //             }
        //         }
        //     }).catch(err => {
        //     });
        // }
        q['keyword'] = keyword || this.fSearchbox.value;
        // if(keyword) q['keyword'] = keyword;
        if(propertyType && propertyType !== '-1') q['type'] = propertyType;
        // if(province) q['province'] = province;
        // if(numOfBathrooms && numOfBathrooms !== -1) q['numOfBathrooms'] = numOfBathrooms;
        if(numOfBedrooms && numOfBedrooms !== -1) q['numOfBedrooms'] = numOfBedrooms;
        // if(area) q['area'] = area.join(',');
        if(price) q['price'] = price.join(',');
        browserHistory.push(`/real-estates${Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${encodeURIComponent(q[k])}`).join('&') : ''}`);
    }
    
    showDetail = ({ _id, title }) => {
        browserHistory.push(`/real-estate/${toHyphens(title)}-${_id}`);
    }
    
    handleOpenPriceFilter = event => {
        this.setState({
            priceRangeAnchorEl: event.currentTarget,
        });
    };
    
    handleClosePriceFilter = () => {
        this.setState({
            priceRangeAnchorEl: null,
        });
    }

}

export default withStyles(styles)(SearchOverlay);