import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import NumberFormat from 'react-number-format';
import { Range } from 'rc-slider';
import Map from 'common/map';
import Footer from 'components/footer';
// import 'rc-slider/assets/index.css';
import s from './style.scss';
import { Divider } from '@material-ui/core';
import { request } from 'lib/http-services';
import { multilingual } from '@hoc/multilingual';
const { currencyFormating, toHyphens } = require('lib/helper');
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
const noPhoto = require('assets/img/no-photo.jpg');
// const lang = 'vi';

const icons = {
    numOfBedrooms: 'airline_seat_individual_suite',
    numOfBathrooms: 'airline_seat_legroom_normal',
    area: 'photo_size_select_small'
}
const bedroomNumbers = [
    {
        key: 'bed-1',
        name: '1 phòng',
        value: 1
    },
    {
        key: 'bed-2',
        name: '2 phòng',
        value: 2
    },
    {
        key: 'bed-3',
        name: '3 phòng',
        value: 3
    },
    {
        key: 'bed-4',
        name: '4 phòng',
        value: 4
    },
    {
        key: 'bed-5+',
        name: '5+ phòng',
        value: 5
    },
];
const bathroomNumbers = [
    {
        key: 'bed-1',
        name: '1 phòng',
        value: 1
    },
    {
        key: 'bed-2',
        name: '2 phòng',
        value: 2
    },
    {
        key: 'bed-3',
        name: '3 phòng',
        value: 3
    },
    {
        key: 'bed-4',
        name: '4 phòng',
        value: 4
    },
    {
        key: 'bed-5+',
        name: '5+ phòng',
        value: 5
    },
];
function StartAdornment(props) {
    return (
        <span style={{marginRight: '1rem'}}>{props.children}</span>
    )
}
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            // suffix=" vnđ"
        />
    );
}
NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    filterPopover: {
        maxWidth: '400px',
    },
    relative: {
        position: 'relative'
    },
    flexRow: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'space-between',
        '&.divided': {
            borderBottom: '1px solid #ABABAB'
        },
    },
    overlayContainer: {
        // ...container,
        // alignItems: 'center',
        margin: '0 auto',
        // padding: '2rem',
        // padding: '0 12px',
        padding: 0,
        // 'background-color': '#fff',
        position: 'relative',
        // 'minHeight': 'calc(100vh - 51px - 103px)'
        'minHeight': 'calc(100vh - (83px + 0.625rem))'
    },
    map: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 0
    },
    productListColumn: {
        zIndex: 1
    },
    // textField: {
    //     flexBasis: 200,
    // },
    languageSelect: {
        float: 'left'
    },
    languageSelectInside: {
        color: '#4E4E4E',
        fontSize: '0.875rem',
        '&::before': {
            display: 'none'
        }
    },
    formWrapper: {
        'border-radius': 0,
        padding: '0',
        overflow: 'hidden',
        'box-shadow': '0px -5px 5px 0px rgba(0, 0, 0, 0.5)',
        'border-bottom': '1px solid #ddd'
    },
    clearRow: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'flex-start',
        // backgroundColor: '#F2F5F8',
        '&>*:nth-child(2)': {
            borderLeft: 'none',
            borderRadius: 0,
            [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
                borderLeft: '1px solid #ABABAB',
            },
        },
        '&>.select': {
            padding: '.6rem calc(1rem - 10px) .6rem 1rem'
        },
        '&>span': {
            color: '#666665',
            padding: '.963rem',
            fontSize: '0.875rem'
        }
    },
    expandedButton: {
        float: 'left',
        color: '#4E4E4E',
        // display: 'none',
        transform: 'rotate(90deg)',
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            // display: 'initial'
            transform: 'rotate(0)'
        },
    },
    secondaryBackground: {
        backgroundColor: '#F2F5F8',
    },
    productList: {
        // padding: '12px',
        padding: '12px 12px 0',
        // overflow: 'hidden',
        // 'overflow-x': 'hidden',
        // 'overflow-y': 'auto',
        // 'min-height': 'calc(100vh - 51px - 103px)',
        // 'max-height': 'calc(100vh - 51px - 103px + 27px)',
        // height: 'calc(100vh - 51px - 103px + 8px)',
        // height: 'calc(100vh - 51px - 103px + 26px)'
        height: 'calc(100vh - (83px + 0.625rem) + 26px)'
    },
    expanded: {
        '& $product': {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 'calc((100% - 12px * 2) / 3)',
                '&:not(:nth-child(2n))': {
                    marginRight: 'inherit'
                },
                '&:not(:nth-child(3n))': {
                    marginRight: '12px'
                }
            },
        }
    },
    product: {
        float: 'left',
        position: 'relative',
        marginBottom: '12px',
        cursor: 'pointer',
        overflow: 'hidden',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'calc((100% - 12px) / 2)',
            '&:not(:nth-child(2n))': {
                marginRight: '12px'
            },
        },
        '& .product-detail': {
            position: 'absolute',
            overflow: 'hidden',
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            color: '#fff',
            // 'background-color': 'rgba(0, 0, 0, .5)',
            background: 'linear-gradient(to top, rgba(0,0,0,.5) 0%, rgba(0,0,0,.2) 50%, rgba(0,0,0,0) 100%)',
            padding: '.5rem 1rem',
            alignItems: 'flex-end',
            // '&>div': {
            //     padding: '.2rem'
            // },
            // '& .row': {
            //     position: 'relative',
            //     overflow: 'hidden',
            //     width: '100%',
            //     display: 'flex',
            //     'flex-direction': 'row',
            //     'align-items': 'flex-end',
            //     'justify-content': 'space-between'
            // },
            '& .product-name': {
                'font-size': '1rem',
                'font-weight': 'bold',
                float: 'left'
            },
            '& .product-address': {
                'font-size': '.8rem',
                'line-height': 'calc(.8rem * 2)',
                width: '100%',
                'text-align': 'left'
            },
            '& .product-area': {
                'font-size': '.9rem',
                'font-weight': 'bold',
                width: '100%',
                'text-align': 'right'
            },
            '& .product-type': {
                ...theme.typography.button,
                backgroundColor: '#FFED38',
                // padding: theme.spacing.unit,
                padding: '.3rem .5rem',
                width: 'max-content',
                'font-size': '1rem',
                'font-weight': 'inherit',
                transform: 'translateY(-20px)',
                'text-transform': 'inherit'
            },
            '& .product-price': {
                'font-size': '.9rem',
                'font-weight': 'bold',
                'text-align': 'right',
                color: '#FFED38'
            },
            '& .product-utils': {
                'font-size': '.8rem',
                '& .util-icon': {
                    'font-size': '1rem',
                    'margin-right': '.5rem',
                    '&+.util-value': {
                        'margin-right': '1rem',
                    }
                },
            },
        },
    },
    productPhoto: {
        width: '100%',
        height: 0,
        paddingTop: '70%'
    },
    viewModeButton: {
        color: '#909297',
        '&.active': {
            // color: '#4E4E4E'
            color: 'inherit'
        }
    },
    marginNormal: {
        'margin-top': '16px',
        'margin-bottom': '8px'
    },
    popper: {
        // padding: '1rem 1rem 1rem 1.5rem',
        // padding: '1.5rem 1rem 1rem 1.5rem',
        padding: '1.5rem 1rem 1rem'
    },
    popperTitle: {
        // 'margin-bottom': '1rem',
        // 'margin-bottom': '2rem'
        // padding: '.6rem calc(1rem - 10px) .6rem 1rem',
        padding: '.6rem calc(1rem - 10px) .6rem 0',
        // height: '29.63px',
    },
    popperFlexActionRow: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'flex-end',
        'margin-top': '1rem',
        // 'margin-top': '2rem'
    },
    button: {
        'border-radius': 0,
        '&:not(:last-of-type)': {
            'margin-right': '5px'
        }
    },
    applyButton: {
        // 'border-color': '#FF0000',
        // color: '#fff',
        // 'margin-top': '24px',
        backgroundColor: '#FFED38',
        // '&>span>span': {
        //     'margin-right': '1rem'
        // },
        '&:hover': {
            'background-color': '#eedc27',
            // color: '#fff'
        }
    },
    filterButton: {
        padding: '.6rem calc(1rem - 10px) .6rem 1rem',
        'background-color': 'inherit',
        border: 'none',
        'box-shadow': 'none',
        color: '#4E4E4E',
        'text-transform': 'inherit',
        'font-weight': 'normal',
        margin: 0,
        width: 'auto',
        '&>*': {
            fontSize: '0.875rem'
        },
        '& input': {
            width: '100px'
        },
        '&>span': {
            padding: '2.8px'
        },
        '&>span>span': {
            'margin-left': 'calc(1rem - 10px)'
        }
    },
    checkbox: {
        '&.compact': {
            width: '38px',
            height: '38px'
        }
    },
    radio: {
        '&.compact': {
            width: '38px',
            height: '38px'
        }
    },
    formControlLabel: {
        'margin-left': 0,
        'margin-right': '2rem'
    },
    lineSpacing: {
        padding: `${theme.spacing.unit / 2}px 0`
    },
    sliderWrapper: {
        padding: `${theme.spacing.unit / 2}px 7px`
    },
    slider: {
        // width: '300px'
    },
    mapWrapper: {
        // height: 'calc(100vh - (83px + 0.625rem) + 49.78px + 31.81px)',
        // height: 'calc(49.81px * 2 + 614px)',
        height: 'calc(100vh + 0.625rem + 12px * 2)',
        position: 'relative',
        'box-shadow': 'none'
    },
    fullWidth: {
        width: '100%'
    },
    responsiceMap: {
        display: 'none',
        [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
            display: 'initial',
            '&.hide': {
                display: 'none'
            }
        },
    },
    chip: {
        margin: theme.spacing.unit,
    },
});

@multilingual
class Buy extends Component {
    state = {
        pagination: {},
        realEstates: [],
        // lastUpdate: Date.now(),
        viewMode: 'grid',
        expanded: false,
        // typePopupOpen: false,
        typeAnchorEl: null,
        // priceRangePopupOpen: false,
        priceRangeAnchorEl: null,
        bedroomAnchorEl: null,
        bathroomAnchorEl: null,
        otherFilterAnchorEl: null,
        regionPopupOpen: false,
        morePopupOpen: false,
        filteredArea: [0, 50],
        filteredPrice: [0, 4000000],
        filteredPriceMin: 0,
        filteredPriceMax: 4000000,
        // lastBounds: null,
        area: [0, 50],
        price: [0, 4000000],
        propertyTypes: [],
        filteredPropertyTypes: {},
        provinces: [],
        province: null,
        propertyType: null,
        numOfBathrooms: null,
        numOfBedrooms: null,
        propertyGroup: 'rent',
        filter: {
            area: [0, 50],
            price: [0, 4000000],
        },
        filteredTypes: {},
        lang: localStorage.getItem('lang'),
        sortByPrice: -1,
        currencies: [],
        currency: null,
        filteredCurrency: 'vnd',
        units: [],
        areaUnit: null,
        priceUnit: null,
        filteredAreaUnit: 'sqm',
        filteredPriceUnit: 'sqm'
    }

    fetchOnBoundChanged = false
    
    componentDidMount() {
        this.map.sync(() => {
            this.init(this.props.location.query);
        });
        this.getPropertyTypes();
        this.getProvinces();
        this.getCurrencies();
        this.getUnits();
    }

    shouldComponentUpdate() {
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // if(nextProps.params.group !== this.props.params.group) {
        //     this.init(nextProps.params.group);
        // }
        // if(nextProps.location.query !== this.props.location.query) {
        //     const { group, type, province, numOfBathrooms, numOfBedrooms, area, price } = nextProps.location.query;
        //     this.setState({
        //         filter: {
        //             group,
        //             type,
        //             province,
        //             numOfBathrooms,
        //             numOfBedrooms,
        //             area: [...decodeURIComponent(area).split(',').map(a => parseInt(a))],
        //             price: [...decodeURIComponent(price).split(',').map(p => parseInt(p))]
        //         }
        //     }, () => this.map.sync(() => this.init(this.props.location.query)));
        // }
    }

    componentDidUpdate(prevProps) {
        if(this.props.location.query !== prevProps.location.query) {
            this.map.sync(() => this.init(this.props.location.query))
        }
        // if(this.props.params.group !== prevProps.params.group) {
        //     this.map.sync(() => this.init(this.props.params.group));
        // }
    }

    componentWillUnmount() {
        
    }

    render() {
        const { classes, dictionary } = this.props;
        const {
            viewMode,
            expanded,
            // typePopupOpen,
            // priceRangePopupOpen,
            // regionPopupOpen,
            // morePopupOpen
            typeAnchorEl,
            priceRangeAnchorEl,
            bedroomAnchorEl,
            bathroomAnchorEl,
            otherFilterAnchorEl,
            filteredArea,
            // filteredPrice,
            filteredPriceMin,
            filteredPriceMax,
            pagination,
            realEstates,
            propertyTypes,
            filter,
            filteredPropertyTypes,
            filteredTypes,
            numOfBathrooms,
            numOfBedrooms,
            provinces,
            province,
            sortByPrice,
            price,
            area,
            currencies,
            currency,
            filteredCurrency,
            units,
            areaUnit,
            priceUnit,
            filteredAreaUnit,
            filteredPriceUnit
            // lastUpdate
        } = this.state;
        const filteredPropertyTypesCount = Object.values(filteredPropertyTypes).filter(t => !!t).length;
        // const selectedAreaUnit = units.find(u => u._id === areaUnit);
        // const selectedPriceUnit = units.find(u => u._id === priceUnit);
        // const selectedCurrency = currencies.find(c => c._id === currency);

        return (
            <React.Fragment>
                <Grid container justify="flex-end" alignItems="flex-start" className={classes.overlayContainer}>
                    {/* <Grid item xs={12} md={5} className={classNames(classes.responsiceMap, expanded && 'hide')}> */}
                    <Grid item xs={12} md={5} className={expanded ? 'hide' : ''}>
                        <Paper className={classNames(classes.formWrapper, classes.mapWrapper)}>
                            <div className={classes.map}>
                                <Map
                                    key={`properties-for-${this.props.params.group}-map`}
                                    onRef={m => {
                                        this.map = m;
                                        // this.props.bindProps({
                                        //     map: m
                                        // });
                                    }}
                                    // onMount={() => this.init(this.props.params.group)}
                                />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={expanded ? 12 : 7} className={classNames(classes.productListColumn, expanded && classes.expanded)}>
                        <Paper className={classes.formWrapper}>
                            <div className={classes.productListForm}>
                                <div className={classNames(classes.flexRow, classes.secondaryBackground, 'divided')}>
                                    <div className={classes.clearRow}>
                                        <IconButton color="inherit" className={classes.expandedButton} aria-label="ToggleExpand" onClick={this.handleToggleExpanded}>
                                            <Icon>{expanded ? 'chevron_right' : 'chevron_left'}</Icon>
                                        </IconButton>
                                        {/* <Icon>chevron_left</Icon> */}
                                        {/* <span onClick={this.handleToggleExpanded}>
                                            Loại hình
                                            <Icon>{expanded ? 'chevron_right' : 'chevron_left'}</Icon>
                                        </span> */}
                                        <Button
                                            variant="contained"
                                            onClick={this.handleTypeFilterClick}
                                            className={classes.filterButton}
                                        >
                                            {dictionary['Type'] || ''}
                                            {filteredPropertyTypesCount > 0 && <span>({filteredPropertyTypesCount})</span>}
                                            <Icon>arrow_drop_down</Icon>
                                        </Button>
                                        <TextField
                                            select
                                            // label="Loại căn hộ"
                                            className={classNames(classes.filterButton, 'non-underline')}
                                            value={province || -1}
                                            onChange={e => this.handleChangeQueries({
                                                'province': e.target.value
                                            })}
                                            SelectProps={{
                                                MenuProps: {
                                                    className: classes.menu,
                                                },
                                            }}
                                            // helperText="Please select your currency"
                                            margin="normal"
                                            fullWidth
                                        >
                                            <MenuItem value={-1}>
                                                {dictionary['Region'] || ''}
                                            </MenuItem>
                                            {provinces.map(p => ({
                                                value: p._id,
                                                label: p.name
                                            })).map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {/* <Button
                                            variant="contained"
                                            onClick={this.handleBedroomFilterClick}
                                            className={classes.filterButton}
                                        >
                                            {dictionary['Bedroom'] || ''}
                                            <Icon>arrow_drop_down</Icon>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={this.handleBathroomFilterClick}
                                            className={classes.filterButton}
                                        >
                                            {dictionary['Bathroom'] || ''}
                                            <Icon>arrow_drop_down</Icon>
                                        </Button> */}
                                        <Button
                                            variant="contained"
                                            onClick={this.handleOtherFilterClick}
                                            className={classes.filterButton}
                                        >
                                            {dictionary['More'] || ''}
                                            <Icon>arrow_drop_down</Icon>
                                        </Button>
                                        {/* <Button
                                            variant="contained"
                                            onClick={this.handlePriceFilterClick}
                                            className={classes.filterButton}
                                        >
                                            {dictionary['Price'] || ''}
                                            <Icon>arrow_drop_down</Icon>
                                        </Button> */}
                                    </div>
                                    {/* <div>
                                        <IconButton color="inherit" className={classNames(classes.viewModeButton, viewMode === 'list' && 'active')} aria-label="viewStream" onClick={() => this.handleChangeViewMode('list')}>
                                            <Icon>view_stream</Icon>
                                        </IconButton>
                                        <IconButton color="inherit" className={classNames(classes.viewModeButton, viewMode === 'grid' && 'active')} aria-label="viewModule" onClick={() => this.handleChangeViewMode('grid')}>
                                            <Icon>view_module</Icon>
                                        </IconButton>
                                    </div> */}
                                </div>
                                <div className={classNames(classes.flexRow, classes.secondaryBackground, 'divided')}>
                                    <div className={classes.clearRow}>
                                        {'keyword' in this.props.location.query && (
                                            <Chip
                                                label={<span>{dictionary['Search']}: {this.props.location.query.keyword}</span>}
                                                onDelete={this.handleRemoveKeyword}
                                                className={classes.chip}
                                                color="primary"
                                            />
                                        )}
                                        <span style={{borderLeft: 'none'}}><b>{pagination && pagination.total ? pagination.total : realEstates.length}</b> {(dictionary['Result'] || '').toLowerCase()}</span>
                                    </div>
                                    <div>
                                        <TextField
                                            select
                                            // label="Language"
                                            className={classNames(classes.margin, classes.textField, classes.languageSelect, 'select', 'non-underline')}
                                            // value={this.state.language}
                                            value={sortByPrice}
                                            onChange={this.handleChangeSort}
                                            InputProps={{
                                                className: classes.languageSelectInside,
                                                // startAdornment: (
                                                //     <InputAdornment position="start">
                                                //         <LanguageIcon />
                                                //     </InputAdornment>
                                                // ),
                                            }}
                                        >
                                            {[
                                                {
                                                    value: -1,
                                                    label: `${dictionary['Price'] || ''}: ${dictionary['Decrease'] || ''}`
                                                },
                                                {
                                                    value: 1,
                                                    label: `${dictionary['Price'] || ''}: ${dictionary['Ascending'] || ''}`
                                                }
                                            ].map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </div>
                                <div className={classNames(classes.productList, 'think-scroll')}>
                                    {realEstates.map(tile => (
                                        <div key={tile._id} className={classNames(classes.product, s.product)} onMouseEnter={this.openInfoWindow(tile)} onMouseLeave={this.closeInfoWindow} onClick={this.panToMarker(tile)}>
                                            <span className={s.btnViewDetail} onClick={() => this.showDetail(tile)}>Xem chi tiết</span>
                                            <div className={classNames(classes.productPhoto, 'cover')} style={{backgroundImage: `url(${tile.photos && tile.photos.length > 0 ? `${restEndpoint}${tile.photos[0].path}` : noPhoto})`}}></div>
                                            {/* <div className="product-detail">
                                                <div className="product-name">{tile.title}</div>
                                                <div className="row">
                                                    <div className="product-address">{tile.subTitle}</div>
                                                    <div className="product-area">{tile.area}</div>
                                                </div>
                                            </div> */}
                                            <Grid container direction="row" alignItems="center" justify="space-between" className="product-detail">
                                                <Grid item xs={12} sm={9}>
                                                    <div className="product-name truncate">{tile.title}</div>
                                                    <Grid container direction="column" alignItems="flex-start" justify="flex-end">
                                                        {tile.location && (Object.keys(tile.location).length > 1 || tile.location.address !== '') && <div className="product-address">{this.getFormattedAddress(tile.location)}</div>}
                                                        {/* <div className="product-area">{tile.area}</div> */}
                                                        <Grid container direction="row" alignItems="center" justify="flex-start" className="product-utils">
                                                            {/* {Object.keys(tile.utils).map(util => (
                                                                <React.Fragment key={util}>
                                                                    <Icon className="util-icon">{icons[util]}</Icon>
                                                                    <span className="util-value">
                                                                        {tile.utils[util]}
                                                                    </span>
                                                                </React.Fragment>
                                                            ))} */}
                                                            <Icon className="util-icon">{icons['numOfBedrooms']}</Icon>
                                                            <span className="util-value">
                                                                {tile.numOfBedrooms}
                                                            </span>
                                                            <Icon className="util-icon">{icons['numOfBathrooms']}</Icon>
                                                            <span className="util-value">
                                                                {tile.numOfBathrooms}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <Grid container direction="column" alignItems="flex-end" justify="flex-end" className={classes.relative}>
                                                        {/* <div className="product-type">{tile.type}</div> */}
                                                        {/* <div className="product-type">{tile.group && tile.group.title}</div> */}
                                                        <div className="product-price">{tile.price.value.toLocaleString('en')} {tile.price.currency.name}{tile.group && tile.group._id === 'rent' ? ` / ${tile.price.unit.title}` : ''}</div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
                <Popover 
                    open={Boolean(typeAnchorEl)}
                    anchorEl={typeAnchorEl}
                    onClose={this.handleTypeFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FormControl className={classes.popper}>
                        <FormLabel className={classes.popperTitle}><b>{dictionary['Select the type of real estate']}</b></FormLabel>
                        <Grid container className={classes.filterPopover}>
                            {/* <FormGroup> */}
                                {propertyTypes.map(t => (
                                    <Grid item xs={12} md={6} key={t._id} className={classes.leftAlign}>
                                        <FormControlLabel
                                            key={t._id}
                                            className={classes.formControlLabel}
                                            control={(
                                                <Checkbox
                                                    checked={filteredTypes[t._id] || false}
                                                    className={classes.checkbox}
                                                    value={t._id.toString()}
                                                    onChange={this.handleChangeFilteredTypes}
                                                />
                                            )}
                                            label={t.title}
                                            className={classes.fullWidth}
                                        />
                                    </Grid>
                                ))}
                            {/* </FormGroup> */}
                        </Grid>
                        <div className={classes.popperFlexActionRow}>
                            <Button
                                variant="text"
                                className={classNames(classes.button, classes.dismissButton)}
                                onClick={() => this.setState({
                                    filteredTypes: filteredPropertyTypes
                                }, this.handleTypeFilterClose)}
                            >
                                {dictionary['Cancel'] || ''}
                            </Button>
                            <Button
                                variant="contained"
                                className={classNames(classes.button, classes.applyButton)}
                                onClick={() => {
                                    // this.setState({
                                    //     filteredPropertyTypes: filteredTypes
                                    // }, () => {
                                    //     this.handleChangeFilters({ type: Object.keys(filteredTypes).filter(t => !!filteredTypes[t]).join(',') });
                                    //     this.handleTypeFilterClose();
                                    // })
                                    this.handleChangeQueries({ type: Object.keys(filteredTypes).filter(t => !!filteredTypes[t]).join(',') });
                                    this.handleTypeFilterClose();
                                }}>
                                {dictionary['Apply'] || ''}
                            </Button>
                        </div>
                    </FormControl>
                </Popover>
                <Popover 
                    open={Boolean(bedroomAnchorEl)}
                    anchorEl={bedroomAnchorEl}
                    onClose={this.handleBedroomFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FormControl className={classes.popper}>
                        <FormLabel className={classes.popperTitle}><b>Chọn số phòng ngủ</b></FormLabel>
                        <RadioGroup
                            aria-label="bedroom number"
                            name="bedroomFilter"
                            // className={classes.group}
                            value="bed-all"
                            // onChange={this.handleChange}
                        >
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={(
                                    <Radio
                                        checked={!numOfBedrooms}
                                        className={classes.radio}
                                        onChange={() =>this.setState({
                                            numOfBedrooms: null
                                        })}
                                        value="bed-all"
                                    />
                                )}
                                label="Tất cả"
                            />
                            {bedroomNumbers.map((t, i) => i < bedroomNumbers.length - 1 ? (
                                <FormControlLabel
                                    key={t.key}
                                    className={classes.formControlLabel}
                                    control={(
                                        <Radio
                                            checked={numOfBedrooms === t.value}
                                            className={classes.radio}
                                            onChange={() => this.setState({
                                                numOfBedrooms: t.value
                                            })}
                                            value={t.key}
                                        />
                                    )}
                                    label={t.name}
                                />
                            ) : (
                                <FormControlLabel
                                    key={t.key}
                                    className={classes.formControlLabel}
                                    control={(
                                        <Radio
                                            checked={numOfBedrooms >= t.value}
                                            className={classes.radio}
                                            onChange={() => this.setState({
                                                numOfBedrooms: t.value
                                            })}
                                            value={t.key}
                                        />
                                    )}
                                    label={t.name}
                                />
                            ))}
                        </RadioGroup>
                        <div className={classes.popperFlexActionRow}>
                            <Button variant="text" className={classNames(classes.button, classes.dismissButton)} onClick={() => this.setState({
                                numOfBedrooms: filter.numOfBedrooms
                            }, this.handleBedroomFilterClose)}>
                                Hùy
                            </Button>
                            <Button variant="contained" className={classNames(classes.button, classes.applyButton)} onClick={() => {
                                this.handleChangeFilters({ numOfBedrooms });
                                this.handleBedroomFilterClose();
                            }}>
                                Áp dụng
                            </Button>
                        </div>
                    </FormControl>
                </Popover>
                <Popover 
                    open={Boolean(bathroomAnchorEl)}
                    anchorEl={bathroomAnchorEl}
                    onClose={this.handleBathroomFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FormControl className={classes.popper}>
                        <FormLabel className={classes.popperTitle}><b>Chọn số phòng tắm</b></FormLabel>
                        <RadioGroup
                            aria-label="bathroom number"
                            name="bathroomFilter"
                            // className={classes.group}
                            value="bath-all"
                            // onChange={this.handleChange}
                        >
                            <FormControlLabel
                                className={classes.formControlLabel}
                                control={(
                                    <Radio
                                        checked={!numOfBathrooms}
                                        className={classes.radio}
                                        onChange={() =>this.setState({
                                            numOfBathrooms: null
                                        })}
                                        value="bath-all"
                                    />
                                )}
                                label="Tất cả"
                            />
                            {bathroomNumbers.map((t, i) => i < bathroomNumbers.length - 1 ? (
                                <FormControlLabel
                                    key={t.key}
                                    className={classes.formControlLabel}
                                    control={(
                                        <Radio
                                            checked={numOfBathrooms === t.value}
                                            className={classes.radio}
                                            onChange={() => this.setState({
                                                numOfBathrooms: t.value
                                            })}
                                            value={t.key}
                                        />
                                    )}
                                    label={t.name}
                                />
                            ) : (
                                <FormControlLabel
                                    key={t.key}
                                    className={classes.formControlLabel}
                                    control={(
                                        <Radio
                                            checked={numOfBathrooms >= t.value}
                                            className={classes.radio}
                                            onChange={() => this.setState({
                                                numOfBathrooms: t.value
                                            })}
                                            value={t.key}
                                        />
                                    )}
                                    label={t.name}
                                />
                            ))}
                        </RadioGroup>
                        <div className={classes.popperFlexActionRow}>
                            <Button variant="text" className={classNames(classes.button, classes.dismissButton)} onClick={() => this.setState({
                                numOfBathrooms: filter.numOfBathrooms
                            }, this.handleBedroomFilterClose)}>
                                Hùy
                            </Button>
                            <Button variant="contained" className={classNames(classes.button, classes.applyButton)} onClick={() => {
                                this.handleChangeFilters({ numOfBathrooms });
                                this.handleBathroomFilterClose();
                            }}>
                                Áp dụng
                            </Button>
                        </div>
                    </FormControl>
                </Popover>
                <Popover 
                    open={Boolean(otherFilterAnchorEl)}
                    anchorEl={otherFilterAnchorEl}
                    onClose={this.handleOtherFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FormControl className={classes.popper}>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item>
                                {/* <Typography id="label" align="left" className={classes.popperTitle}>{dictionary['Floor area']}: {(dictionary['From * to *'] || '').replace(/\$0/g, filteredArea[0]).replace(/\$1/g, filteredArea[1])} {selectedAreaUnit ? selectedAreaUnit.title : ''}</Typography> */}
                                <Typography id="label" align="left" className={classes.popperTitle}>{dictionary['Floor area']}: {(dictionary['From * to *'] || '').replace(/\$0/g, filteredArea[0]).replace(/\$1/g, filteredArea[1])} m<sup>2</sup></Typography>
                            </Grid>
                            {/* <Grid item>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    className={classNames(classes.filterButton, 'non-underline')}
                                    value={filteredAreaUnit || -1}
                                    onChange={e => {
                                        this.setState({
                                            filteredAreaUnit: e.target.value
                                        });
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Item Unit'] || ''}
                                    </MenuItem>
                                    {units.map(p => ({
                                        value: p._id,
                                        label: p.title
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}
                        </Grid>
                        <div className={classes.sliderWrapper}>
                            <Range min={0} max={1000} step={10} defaultValue={filteredArea} className={classes.slider} onChange={this.handleAreaChange} />
                        </div>
                        <Grid container alignItems="center" justify="space-between">
                            <Grid item>
                                {/* <Typography id="label" align="left" className={classes.popperTitle}><span>{dictionary['Price']}: </span>{(dictionary['From * to *'] || '').replace(/\$0/g, filteredPriceMin).replace(/\$1/g, filteredPriceMax)} {selectedCurrency ? selectedCurrency.code : ''}{selectedPriceUnit ? ` / ${selectedPriceUnit.title}` : ''}</Typography> */}
                                <Typography id="label" align="left" className={classes.popperTitle}>{dictionary['Price']}: </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                            {/* <Grid item>
                                <Typography id="label" align="left" className={classNames(classes.popperTitle, classes.marginNormal)}><span>{dictionary['Price']}: </span>{(dictionary['From * to *'] || '').replace(/\$0/g, filteredPrice[0].toLocaleString('en')).replace(/\$1/g, filteredPrice[1].toLocaleString('en'))}{selectedCurrency ? selectedCurrency.name : ''}{this.props.params.group === 'rent' && selectedPriceUnit ? ` /${selectedPriceUnit.tile}` : ''}</Typography>
                            </Grid> */}
                            {/* <Grid item>
                                <Typography id="label" align="left" className={classes.filterButton}><span>{dictionary['Price']}: </span></Typography>
                            </Grid> */}
                            <Grid item>
                                <Input
                                    id="priceMin"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredPriceMin}
                                    // error={'price' in errors}
                                    onBlur={e => {
                                        let changedPrice = {}
                                        const changedPriceMin = Number(e.target.value.replace(/,/g, ''));
                                        changedPrice.filteredPriceMin = changedPriceMin;
                                        if (filteredPriceMax < changedPriceMin) changedPrice.filteredPriceMax = changedPriceMin;
                                        this.setState({
                                            ...changedPrice
                                        })
                                    }}
                                    startAdornment={<StartAdornment>{dictionary['From']}</StartAdornment>}
                                    inputComponent={NumberFormatCustom}
                                />
                            </Grid>
                            <Grid item>
                                <Input
                                    id="priceMax"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredPriceMax}
                                    // error={'priceMax' in errors}
                                    onBlur={e => {
                                        this.setState({
                                            filteredPriceMax: Number(e.target.value.replace(/,/g, ''))
                                        })
                                    }}
                                    startAdornment={<StartAdornment>{dictionary['To']}</StartAdornment>}
                                    inputComponent={NumberFormatCustom}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredCurrency || -1}
                                    onChange={e => {
                                        this.setState({
                                            filteredCurrency: e.target.value
                                        });
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Currency'] || ''}
                                    </MenuItem>
                                    {currencies.map(p => ({
                                        value: p._id,
                                        label: p.name
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    // className={classNames(classes.filterButton, 'non-underline')}
                                    // className={classes.filterButton}
                                    value={filteredPriceUnit || -1}
                                    onChange={e => {
                                        this.setState({
                                            filteredPriceUnit: e.target.value
                                        });
                                    }}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Item Unit'] || ''}
                                    </MenuItem>
                                    {units.map(p => ({
                                        value: p._id,
                                        label: p.title
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        {/* <div className={classes.sliderWrapper}>
                            <Range min={0} max={this.props.params.group === 'rent' ? 200000000 : 100000000000} step={this.props.params.group === 'rent' ? 1 : 1000} defaultValue={filteredPrice} className={classes.slider} onChange={this.handlePriceChange} />
                        </div> */}
                        <div className={classes.popperFlexActionRow}>
                            <Button variant="text" className={classNames(classes.button, classes.dismissButton)} onClick={() => this.setState({
                                filteredPriceMin: price[0],
                                filteredPriceMax: price[1],
                                filteredArea: area,
                                // filteredAreaUnit: areaUnit,
                                // filteredPriceUnit: priceUnit,
                                // filteredCurrency: currency
                            }, this.handleOtherFilterClose)}>
                                {dictionary['Cancel'] || ''}
                            </Button>
                            <Button variant="contained" className={classNames(classes.button, classes.applyButton)} onClick={() => {
                                this.handleChangeQueries({
                                    price: [filteredPriceMin, filteredPriceMax].join(','),
                                    area: filteredArea,
                                    areaUnit: filteredAreaUnit,
                                    priceUnit: filteredPriceUnit,
                                    currency: filteredCurrency
                                });
                                this.handleOtherFilterClose();
                            }}>
                                {dictionary['Apply'] || ''}
                            </Button>
                        </div>
                    </FormControl>
                </Popover>
                <Footer dictionary={dictionary} />
            </React.Fragment>
        );
    }

    init = (query) => {
        this.fetchOnBoundChanged = false;
        // const { group, type, province, numOfBathrooms, numOfBedrooms, area, price } = query;
        const { type, area, price, numOfBathrooms, numOfBedrooms, keyword, bounds, province, ...otherQueries } = query;
        var _self = this;
        let filter = {
            ...otherQueries
        };
        let otherState = {}
        if(area) filter['area'] = [...decodeURIComponent(area).split(',').map(a => parseInt(a))];
        if(price) {
            const fprice = [...decodeURIComponent(price).split(',').map(p => parseInt(p))];
            filter['price'] = fprice;
            otherState['filteredPriceMin'] = fprice[0];
            otherState['filteredPriceMax'] = fprice[1];
        }
        let filteredPropertyTypes = {};
        Object.keys(_self.state.filteredPropertyTypes).forEach(t => filteredPropertyTypes[t] = false);
        if(type) {
            type.split(',').forEach(t => filteredPropertyTypes[t] = true);
            filter['type'] = type;
        }
        if(province && province !== '-1') filter['location.province'] = province;
        if(numOfBathrooms) filter['numOfBathrooms'] = parseInt(numOfBathrooms);
        if(numOfBedrooms) filter['numOfBedrooms'] = parseInt(numOfBedrooms);
        if(bounds) filter['bounds'] = [...decodeURIComponent(bounds).split(',').map(b => parseFloat(b))]
        _self.setState({
            filteredPropertyTypes,
            filteredTypes: filteredPropertyTypes,
            numOfBathrooms: filter['numOfBathrooms'],
            numOfBedrooms: filter['numOfBedrooms'],
            province,
            ...otherState,
            filter
        }, () => _self.fetchData({
            ...filter
        }));
        this.fetchOnBoundChanged = true
        window.google.maps.event.clearListeners(_self.map.map, 'idle');
        // _self.map.map.addListener('bounds_changed', async function() {
        _self.map.map.addListener('idle', function() {
            if(_self.fetchOnBoundChanged) {
                const _bounds = this.getBounds();
                const bounds = [_bounds.getSouthWest().lat(), _bounds.getNorthEast().lat(), _bounds.getSouthWest().lng(), _bounds.getNorthEast().lng()];
                // await _self.fetchData({ ..._self.state.filter, bounds });
                _self.handleChangeQueries({
                    ...query,
                    bounds: bounds.join(',')
                })
            }
        }); //bounds_changed
    }

    fetchData = async ({ bounds, ...query }) => {
        this.map.clearMarkers();
        this.map.clearInfoWindows();
        this.map.clearClusters();
        const queries = Object.keys(query).filter(k => !!query[k] && query[k] !== -1);
        let q = {};
        queries.forEach(k => {
            q[k] = query[k]
        });
        // q['type'] = Object.keys(this.state.filteredPropertyTypes).join(',');
        // if(group) q['group'] = group;
        if(bounds) {
            q['bounds'] = bounds.join(',');
            // if(bounds === this.state.lastBounds) return;
            // this.setState({
            //     lastBounds: bounds
            // });
        }
        await request('/properties', {
            params: {
                maps: true,
                ...q,
                sortByPrice: this.state.sortByPrice
            },
            headers: {
                // 'language': localStorage.getItem('lang'),
                'language': this.props.language,
            }
        })
        .then((res) => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    const { meta, result } = res.body;
                    this.setState({
                        pagination: meta,
                        realEstates: result
                    });
                    let markers = [];
                    const buildingImg = require('assets/img/clusters/m4.png');
                    this.map.clearInfoWindows();
                    this.map.addInfoWindow({
                        content: '',
                        options: {
                            id: 'property-marker-info',
                            disableAutoPan: true
                        }
                    });
                    result.forEach((element) => {
                        if(element.location && element.location.location) {
                            if(markers.some(m => m.lat === element.location.location.latitude && m.lng === element.location.location.longitude)) return;
                            let loc;
                            if(element.location && element.location.location) loc = {
                                lat: element.location.location.latitude,
                                lng: element.location.location.longitude
                            };
                            if(result.filter(m => m.location && m.location.location && m.location.location.latitude === element.location.location.latitude && m.location.location.longitude === element.location.location.longitude).length > 1) loc.icon = {
                                url: buildingImg,
                                anchor: new window.google.maps.Point(47 / 2, 47),
                                scaledSize: new window.google.maps.Size(47, 47)
                            };
                            if(loc) markers.push({
                                ...loc,
                                _id: element._id,
                                onClick: this.openInfoWindow(element)
                            });
                        }
                    });
                    // await forEachAsync(result, async p => {
                    //     // if(p.location && p.location.location) console.log(p.location.location);
                    //     if(p.location && p.location.location) await this.map.addMarker({
                    //         location: {
                    //             lat: p.location.location.latitude,
                    //             lng: p.location.location.longitude
                    //         },
                    //         options: {
                    //             id: `property-${p._id}`,
                    //             type: 'property'
                    //             // draggable: true
                    //         }
                    //     });
                    // });
                    // let markers = result.filter(p => p.location && p.location.location).map(p => {
                    //     return {
                    //         lat: p.location.location.latitude,
                    //         lng: p.location.location.longitude
                    //     }
                    // });
                    this.map.clusteringWithMarkers(markers);
                    if(!bounds) {
                        let bounds = new window.google.maps.LatLngBounds();
                        markers.forEach(m => {
                            bounds.extend(this.map.getLatLng(m.lat, m.lng));
                        });
                        this.map.map.fitBounds(bounds);
                    }
                }
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    handleChangeQueries = queries => {
        let finalQueries = {...this.props.location.query}
        Object.keys(queries).forEach(q => {
            if(queries[q] === -1 || queries[q] === '') {
                const {[q]: removed, ...otherQueries} = finalQueries
                finalQueries = {...otherQueries}
            }
        });
        let filteredQueries = Object.keys(queries).filter(q => queries[q] !== -1 && queries[q] !== '').reduce((obj, item) => {
            obj[item] = queries[item]
            return obj
          }, {})
        if('bounds' in filteredQueries) {
            finalQueries = {
                // ...this.props.location.query,
                ...finalQueries,
                ...filteredQueries
            }
        } else {
            const {bounds, ...otherQueries} = finalQueries
            finalQueries = {
                ...otherQueries,
                ...filteredQueries
            }
        }
        browserHistory.push(`/real-estates${Object.keys(finalQueries).length > 0 ? `?${Object.keys(finalQueries).map(q => `${q}=${encodeURIComponent(finalQueries[q])}`).join('&')}` : ''}`)
    }
    
    getPropertyTypes = () => {
        request('/property-types', {
            headers: {
                // 'language': localStorage.getItem('lang'),
                'language': this.props.language,
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    let filteredPropertyTypes = {};
                    res.body.result.forEach(a => filteredPropertyTypes[a._id] = false);
                    this.setState({
                        propertyTypes: res.body.result,
                        filteredPropertyTypes,
                        filteredTypes: filteredPropertyTypes
                    });
                }
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getProvinces = () => {
        return request('/provinces', {
            headers: {
                // 'language': localStorage.getItem('lang'),
                'language': this.props.language,
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        provinces: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getCurrencies = () => {
        return request('/currencies', {
            headers: {
                // 'language': localStorage.getItem('lang'),
                'language': this.props.language,
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        currencies: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getUnits = () => {
        return request('/units', {
            headers: {
                // 'language': localStorage.getItem('lang'),
                'language': this.props.language,
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        units: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    showDetail = ({ _id, title }) => {
        browserHistory.push(`/real-estate/${toHyphens(title)}-${_id}`);
    }

    handleRemoveKeyword = () => {
        const { keyword, ...otherQueries } = this.props.location.query
        browserHistory.push(`/real-estates${Object.keys(otherQueries).length > 0 ? '?' + Object.keys(otherQueries).map(k => `${k}=${encodeURIComponent(otherQueries[k])}`).join('&') : ''}`)
    }
    
    handleChangeViewMode = viewMode => this.setState({
        viewMode
    })

    handleToggleExpanded = () => this.setState({
        expanded: !this.state.expanded
    })

    handleTypeFilterClick = event => {
        this.setState({
            typeAnchorEl: event.currentTarget,
        });
    };
    
    handleTypeFilterClose = () => {
        this.setState({
            typeAnchorEl: null,
        });
    }

    handleBedroomFilterClick = event => {
        this.setState({
            bedroomAnchorEl: event.currentTarget,
        });
    };
    
    handleBedroomFilterClose = () => {
        this.setState({
            bedroomAnchorEl: null,
        });
    }

    handleBathroomFilterClick = event => {
        this.setState({
            bathroomAnchorEl: event.currentTarget,
        });
    };
    
    handleBathroomFilterClose = () => {
        this.setState({
            bathroomAnchorEl: null,
        });
    }

    handlePriceFilterClick = event => {
        this.setState({
            priceRangeAnchorEl: event.currentTarget,
        });
    };
    
    handlePriceFilterClose = () => {
        this.setState({
            priceRangeAnchorEl: null,
        });
    }

    handlePriceFilterChange = filteredPrice => {
        this.setState({ filteredPrice });
    }

    handleChangeFilteredTypes = (e, checked) => this.setState({
        filteredTypes: {
            ...this.state.filteredTypes,
            [e.target.value]: checked
        }
    })
    handleChangeFilters = queries => this.setState({
        filter: {
            ...this.state.filter,
            ...queries
        }
    }, () => {
        // this.handleTypeFilterClose();
        this.fetchData(this.state.filter);
    })
    getFormattedAddress = location => {
        let strs = []
        if(location.address) strs.push(location.address)
        if(location.district) strs.push(location.district.name)
        if(location.province) strs.push(location.province.name)
        return strs.join(', ')
    }

    handleChangeSort = e => this.handleChangeQueries({sortByPrice: e.target.value})

    handleOtherFilterClick = event => {
        this.setState({
            otherFilterAnchorEl: event.currentTarget,
        });
    };
    
    handleOtherFilterClose = () => {
        this.setState({
            otherFilterAnchorEl: null,
        });
    }

    openInfoWindow = (element) => () => {
        if(element.location && element.location.location) {
            if(this.map) {
                if(this.map.infoWindows['property-marker-info']) {
                    this.map.infoWindows['property-marker-info'].setContent(`
                        <div class="modal-detail ${s.devideDetailInfoWindow}">
                            <a class="modal-content no-padding no-border" href="/real-estate/${element._id}">
                                <div class="cover ${s.propertyPhoto}" style="background-image:url(${element.photos && element.photos.length > 0 ? `${restEndpoint}${element.photos[0].path}` : noPhoto})"></div>
                            </a>
                            <div class="${s.propertyInfo}">
                                <b><a href="/real-estate/${element._id}" class="${s.propertyTitle}">${element.title}</a></b>
                                <span>${element.type.title}</span>
                                <span>${element.price.value.toLocaleString(this.state.lang)} ${element.price.currency.name} / ${element.price.unit.title}</span>
                            </div>
                        </div>
                    `);
                    this.map.infoWindows['property-marker-info'].open(this.map.map, this.map.markers[element._id]);
                }
            }
        }
    }

    closeInfoWindow = () => {
        if(this.map && this.map.infoWindows['property-marker-info']) {
            this.map.infoWindows['property-marker-info'].close();
        }
    }

    panToMarker = (element) => (e) => {
        if(![s.btnViewDetail].some(cl => !!e.target.closest(`.${cl}`))) {
            this.fetchOnBoundChanged = false;
            if(this.map) {
                let bounds = new window.google.maps.LatLngBounds();
                bounds.extend(this.map.getLatLng(element.location.location.latitude, element.location.location.longitude));
                this.map.map.fitBounds(bounds);
                setTimeout(() => {
                    this.fetchOnBoundChanged = true;
                }, 500);
            }
        }
    }

    handleAreaChange = area => {
        this.setState({ filteredArea: area });
    }

    handlePriceChange = price => {
        this.setState({ filteredPrice: price });
    }

}

// Home.propTypes = {

// };

export default withStyles(styles)(Buy);