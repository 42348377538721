import React, { Component } from 'react';
import { browserHistory, withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
// import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import Popover from '@material-ui/core/Popover';
import Checkbox from '@material-ui/core/Checkbox';
// import Slider from '@material-ui/lab/Slider';
// import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
// import { Range } from 'rc-slider';
// import 'rc-slider/assets/index.css';
import { multilingual } from '@hoc/multilingual';
import { request } from 'lib/http-services';
import { container } from 'assets/jss/theme.global';
const { currencyFormating, toHyphens } = require('lib/helper');
const config = require('config');
// const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;

function renderSuggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion.fullTitle, query);
    const parts = parse(suggestion.fullTitle, matches);
  
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
            {parts.map((part, index) =>
                part.highlight ? (
                    <span key={String(index)} style={{ fontWeight: 500 }}>
                        {part.text}
                    </span>
                ) : (
                    <strong key={String(index)} style={{ fontWeight: 300 }}>
                        {part.text}
                    </strong>
                ),
            )}
        </div>
      </MenuItem>
    );
}

function getSuggestionValue(suggestion) {
    return suggestion.fullTitle;
}

const styles = theme => ({
    root: {
        backgroundColor: '#fff',
        padding: '16px 8px 8px',
        '&>div': {
            ...container,
            width: 'auto',
            'padding-bottom': '4px'
        }
    },
    searchRoot: {
        // flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
        ...theme.mixins.gutters(),
        // paddingTop: theme.spacing.unit * 2,
        // paddingBottom: theme.spacing.unit * 2,
        // padding: '0 12px 0 24px !important',
        padding: '8px !important',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'space-between',
        // width: 'auto',
        // margin: '0 auto',
        backgroundColor: '#f5f5f5',
        'box-shadow': 'none',
        height: '100%'
    },
    mobileSearchRoot: {
        padding: '8px',
        backgroundColor: '#f5f5f5',
        'box-shadow': 'none',
        height: '52px'
    },
    searchbox: {
        // width: '400px'
    },
    tabsRoot: {
        // borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
        backgroundColor: '#1890ff',
        display: 'none'
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing.unit * 4,
        // backgroundColor: theme.palette.background.paper,
        // backgroundColor: 'rgba(255, 255, 255, 0.5)',
        // backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        color: '#fff',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            // color: '#40a9ff',
            // opacity: 1,
            fontWeight: 'bold',
        },
        '&$tabSelected': {
            // color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        // '&:focus': {
        //     color: '#40a9ff',
        // },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
    tabContent: {
        // backgroundColor: theme.palette.background.paper,
        // backgroundColor: 'rgba(255, 255, 255, 0.5)',
        // backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    // textField: {
    //     // marginLeft: theme.spacing.unit,
    //     marginRight: theme.spacing.unit,
    //     // width: 200,
    //     backgroundColor: 'rgba(255, 255, 255, 0.7)',
    //     textAlign: 'left',
    //     paddingLeft: '1rem',
    //     marginLeft: `calc(${theme.spacing.unit}px - 1rem)`
    // },
    searchButton: {
        float: 'right',
        marginLeft: '24px',
        backgroundColor: '#FFED38',
        'text-transform': 'inherit',
        // 'border-radius': 0
    },
    slider: {
        // '& .rc-slider-track': {
        //     'background-color': '#f00'
        // },
        // '& .rc-slider-handle': {
        //     border: 'solid 2px #f00'
        // },
        // '& .rc-slider-handle:active': {
        //     'border-color': '#f00',
        //     'box-shadow': '0 0 5px #f00'
        // },
        // '& .rc-slider-handle:hover': {
        //     'border-color': '#f00'
        // },
        // '& .rc-slider-handle:focus': {
        //     'border-color': '#f00'
        // }
    },
    sliderLabel: {
        'font-weight': 'bold',
        color: '#fff',
        'margin-bottom': '5px',
        '&>span': {
            color: '#ccc'
        }
    },
    sliderWrapper: {
        padding: '12px 7px !important',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'flex-start',
        'justify-content': 'flex-end'
    },
    radioGroup: {
        display: 'flex',
        'flex-direction': 'row',
        margin: `${theme.spacing.unit}px 0`,
    },
    hDivider: {
        width: '1px',
        height: '50px',
        margin: '0 1rem'
    },
    underline: {
        // borderBottom: '2px solid #f00',
        '&:after': {
            // The source seems to use this but it doesn't work
            borderBottom: '2px solid #f00',
        },
    },
    icon: {
        color: '#767676'
    },
    suggestionsContainer: {
        position: 'relative',
        width: 'calc(100% - (64px + 24px))',
        '& input': {
            'padding-left': '12px'
        }
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing.unit,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
        '&>div>div': {
            'white-space': 'nowrap',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'max-width': '100%'
        }
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none'
    },
    popper: {
        // padding: '1rem 1rem 1rem 1.5rem',
        // padding: '1.5rem 1rem 1rem 1.5rem',
        padding: '1.5rem 1rem 1rem'
    },
    popperTitle: {
        // 'margin-bottom': '1rem',
        // 'margin-bottom': '2rem'
        // padding: '.6rem calc(1rem - 10px) .6rem 1rem',
        padding: '.6rem calc(1rem - 10px) .6rem 0',
        // height: '29.63px',
    },
    popperFlexActionRow: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'flex-end',
        'margin-top': '1rem',
        // 'margin-top': '2rem'
    },
    button: {
        'border-radius': 0,
        '&:not(:last-of-type)': {
            'margin-right': '5px'
        }
    },
    applyButton: {
        // 'border-color': '#FF0000',
        // color: '#fff',
        // 'margin-top': '24px',
        backgroundColor: '#FFED38',
        // '&>span>span': {
        //     'margin-right': '1rem'
        // },
        '&:hover': {
            'background-color': '#eedc27',
            // color: '#fff'
        }
    },
    filterButton: {
        // padding: '.6rem calc(1rem - 10px) .6rem 1rem',
        'background-color': '#f5f5f5',
        // border: 'none',
        'box-shadow': 'none',
        // color: '#4E4E4E',
        // 'text-transform': 'inherit',
        // 'font-weight': 'normal',
        // margin: 0,
        width: '100%',
        'font-weight': 'inherit',
        'text-transform': 'inherit',
        // '&>*': {
        //     fontSize: '0.875rem'
        // },
        // '&>span': {
        //     padding: '2.8px'
        // },
        // '&>span>span': {
        //     'margin-left': 'calc(1rem - 10px)'
        // },
        '&>div': {
            height: '100%'
        },
        '&.mobile-filter': {
            height: '52px'
        },
        '& span': {
            justifyContent: 'flex-start'
        }
    },
    filterSelect: {
        'background-color': '#f5f5f5',
        'box-shadow': 'none',
        width: '100%',
        // '&>*': {
        //     fontSize: '0.875rem'
        // },
        // '&>span': {
        //     padding: '2.8px'
        // },
        // '&>span>span': {
        //     'margin-left': 'calc(1rem - 10px)'
        // },
        '&>*': {
            fontSize: '0.875rem',
            // textTransform: 'uppercase',
            'text-transform': 'inherit',
            'font-weight': 'inherit'
        },
        '&>div': {
            height: '100%'
        },
        // '&>div>div': {
        //     width: 115,
        //     margin: '0 auto'
        // },
        '&>div>div': {
            height: '100%',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
        },
        '&>div>div>div': {
            // 'min-height': '24px',
            // height: '24px',
            // padding: 0,
            padding: '0 1rem',
            'line-height': 1.75,
            width: '100%',
            height: '100%',
            display: 'flex',
            'align-items': 'center',
            // 'justify-content': 'center',
            'justify-content': 'flex-start'
        },
        '&.mobile-filter': {
            '&>div': {
                // height: '36px'
                height: '52px'
            }
        }
    },
    checkbox: {
        '&.compact': {
            width: '38px',
            height: '38px'
        }
    },
    filterArrowIcon: {
        position: 'absolute',
        right: 0,
        color: 'rgba(0, 0, 0, 0.54)'
    }
});

@multilingual
class SearchOverlay extends Component {
    state = {
        tab: 0,
        area: [0, 50],
        price: [0, 4000000],
        projects: [],
        projectTypes: [],
        filteredTypes: {},
        filteredProjectTypes: {},
        provinces: [],
        province: null,
        districts: [],
        district: null,
        projectType: null,
        numOfBathrooms: null,
        numOfBedrooms: null,
        propertyGroup: 'sale',
        typeAnchorEl: null,
        suggestions: [],
        keyword: '',
        filter: {}
    }

    componentDidMount() {
        const { province } = this.props.location.query;
        this.init(this.props.location.query);
        this.getProjectTypes();
        this.getProvinces();
        if (province) this.getDistricts(province);
        else this.setState({
            districts: []
        });
    }

    shouldComponentUpdate() {
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // this.getProjectTypes();
    }

    componentDidUpdate(prevProps) {
        if(this.props.location.query !== prevProps.location.query) {
            const { province } = this.props.location.query;
            this.init(this.props.location.query);
            if (province) this.getDistricts(province);
            else this.setState({
                districts: []
            });
        }
        // if(this.props.params.group !== prevProps.params.group) {
        //     this.map.sync(() => this.init(this.props.params.group));
        // }
    }

    render() {
        const { className, classes, dictionary } = this.props;
        const {
            // propertyGroup,
            suggestions,
            keyword,
            provinces,
            province,
            districts,
            district,
            projectTypes,
            // projectType,
            filteredTypes,
            filteredProjectTypes,
            typeAnchorEl,
            filter
        } = this.state;
        const filteredProjectTypesCount = Object.values(filteredProjectTypes).filter(t => !!t).length;
        const autosuggestProps = {
            renderInputComponent: this.renderInputComponent,
            suggestions,
            onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
        };

        return (
            <div className={classes.root}>
                <div>
                    <Hidden smDown>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={6}>
                                <Paper className={classes.searchRoot} elevation={1}>
                                    <Autosuggest
                                        {...autosuggestProps}
                                        inputProps={{
                                            classes,
                                            placeholder: dictionary['Search by location or keyword'],
                                            value: keyword || '',
                                            onChange: this.handleChangeKeyword,
                                        }}
                                        theme={{
                                            container: classes.suggestionsContainer,
                                            suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                            suggestionsList: classes.suggestionsList,
                                            suggestion: classes.suggestion,
                                        }}
                                        renderSuggestionsContainer={options => (
                                            <Paper {...options.containerProps} square>
                                                {options.children}
                                            </Paper>
                                        )}
                                        onSuggestionSelected={this.onSuggestionSelected}
                                    />
                                    <Button variant="contained" size="medium" className={classes.searchButton} onClick={this.search({})}>
                                        {dictionary['Find'] || ''}
                                    </Button>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={2} container>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    className={classNames(classes.filterSelect, 'non-underline')}
                                    value={province || -1}
                                    // onChange={e => {
                                    //     this.setState({
                                    //         province: e.target.value
                                    //     }, () => {
                                    //         if(e.target.value === -1) {
                                    //             let f = {...filter}
                                    //             delete f['location.province']
                                    //             return this.setState({
                                    //                 filter: f
                                    //             }, () => this.search(f)())
                                    //         }
                                    //         this.handleChangeFilters({
                                    //             'location.province': e.target.value
                                    //         })
                                    //     });
                                    // }}terButton, 'non-underline')}
                                    onChange={e => this.handleChangeQueries({
                                        province: e.target.value
                                    })}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Region'] || ''}
                                    </MenuItem>
                                    {provinces.map(p => ({
                                        value: p._id,
                                        label: p.name
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={2} container>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    className={classNames(classes.filterSelect, 'non-underline')}
                                    value={district || -1}
                                    // onChange={e => {
                                    //     this.setState({
                                    //         province: e.target.value
                                    //     }, () => {
                                    //         if(e.target.value === -1) {
                                    //             let f = {...filter}
                                    //             delete f['location.province']
                                    //             return this.setState({
                                    //                 filter: f
                                    //             }, () => this.search(f)())
                                    //         }
                                    //         this.handleChangeFilters({
                                    //             'location.province': e.target.value
                                    //         })
                                    //     });
                                    // }}terButton, 'non-underline')}
                                    onChange={e => this.handleChangeQueries({
                                        district: e.target.value
                                    })}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['District'] || ''}
                                    </MenuItem>
                                    {districts.map(p => ({
                                        value: p._id,
                                        label: p.name
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={2} container>
                                <Button
                                    variant="contained"
                                    onClick={this.handleTypeFilterClick}
                                    className={classes.filterButton}
                                >
                                    {dictionary['Type of property'] || ''}
                                    {filteredProjectTypesCount > 0 && <span>({filteredProjectTypesCount})</span>}
                                    <Icon className={classes.filterArrowIcon}>arrow_drop_down</Icon>
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12} sm={3} container>
                                <Button
                                    variant="contained"
                                    // onClick={this.handleOtherFilterClick}
                                    className={classes.filterButton}
                                >
                                    {dictionary['Advanced search'] || ''}
                                    <Icon>arrow_drop_down</Icon>
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid container spacing={16}>
                            <Grid item xs={12} sm={6}>
                                <Paper className={classes.mobileSearchRoot} elevation={1}>
                                    <Grid container direction="column" alignItems="center" justify="space-between">
                                        <Grid item xs={12} container alignItems="center" justify="space-between">
                                            <Autosuggest
                                                {...autosuggestProps}
                                                inputProps={{
                                                    classes,
                                                    placeholder: dictionary['Search by location or keyword'],
                                                    value: keyword || '',
                                                    onChange: this.handleChangeKeyword,
                                                }}
                                                theme={{
                                                    container: classes.suggestionsContainer,
                                                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                                                    suggestionsList: classes.suggestionsList,
                                                    suggestion: classes.suggestion,
                                                }}
                                                renderSuggestionsContainer={options => (
                                                    <Paper {...options.containerProps} square>
                                                        {options.children}
                                                    </Paper>
                                                )}
                                                onSuggestionSelected={this.onSuggestionSelected}
                                            />
                                            <Button variant="contained" size="medium" className={classes.searchButton} onClick={this.search({})}>
                                                {dictionary['Find'] || ''}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={2} container>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    className={classNames(classes.filterSelect, 'non-underline', 'mobile-filter')}
                                    value={province || -1}
                                    // onChange={e => {
                                    //     this.setState({
                                    //         province: e.target.value
                                    //     }, () => this.handleChangeFilters({
                                    //         'location.province': e.target.value
                                    //     }));
                                    // }}
                                    onChange={e => this.handleChangeQueries({
                                        province: e.target.value
                                    })}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['Region'] || ''}
                                    </MenuItem>
                                    {provinces.map(p => ({
                                        value: p._id,
                                        label: p.name
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={2} container>
                                <TextField
                                    select
                                    // label="Loại căn hộ"
                                    className={classNames(classes.filterSelect, 'non-underline', 'mobile-filter')}
                                    value={district || -1}
                                    // onChange={e => {
                                    //     this.setState({
                                    //         province: e.target.value
                                    //     }, () => {
                                    //         if(e.target.value === -1) {
                                    //             let f = {...filter}
                                    //             delete f['location.province']
                                    //             return this.setState({
                                    //                 filter: f
                                    //             }, () => this.search(f)())
                                    //         }
                                    //         this.handleChangeFilters({
                                    //             'location.province': e.target.value
                                    //         })
                                    //     });
                                    // }}terButton, 'non-underline')}
                                    onChange={e => this.handleChangeQueries({
                                        district: e.target.value
                                    })}
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    // helperText="Please select your currency"
                                    // margin="normal"
                                    fullWidth
                                >
                                    <MenuItem value={-1}>
                                        {dictionary['District'] || ''}
                                    </MenuItem>
                                    {districts.map(p => ({
                                        value: p._id,
                                        label: p.name
                                    })).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={2} container>
                                <Button
                                    variant="contained"
                                    onClick={this.handleTypeFilterClick}
                                    // className={classes.filterButton}
                                    className={classNames(classes.filterButton, 'mobile-filter')}
                                >
                                    {dictionary['Type of property'] || ''}
                                    {filteredProjectTypesCount > 0 && <span>({filteredProjectTypesCount})</span>}
                                    <Icon className={classes.filterArrowIcon}>arrow_drop_down</Icon>
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12} sm={3} container>
                                <Button
                                    variant="contained"
                                    // onClick={this.handleOtherFilterClick}
                                    className={classes.filterButton}
                                >
                                    {dictionary['Advanced search'] || ''}
                                    <Icon>arrow_drop_down</Icon>
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Hidden>
                </div>
                <Popover 
                    open={Boolean(typeAnchorEl)}
                    anchorEl={typeAnchorEl}
                    onClose={this.handleTypeFilterClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <FormControl className={classes.popper}>
                        <FormLabel className={classes.popperTitle}><b>{dictionary['Select the type of real estate']}</b></FormLabel>
                        <Grid container className={classes.filterPopover}>
                            {/* <FormGroup> */}
                                {projectTypes.map(t => (
                                    <Grid item xs={12} md={6} key={t._id} className={classes.leftAlign}>
                                        <FormControlLabel
                                            key={t._id}
                                            className={classes.formControlLabel}
                                            control={(
                                                <Checkbox
                                                    checked={filteredTypes[t._id] || false}
                                                    className={classes.checkbox}
                                                    value={t._id.toString()}
                                                    onChange={this.handleChangeFilteredTypes}
                                                />
                                            )}
                                            label={t.title}
                                            className={classes.fullWidth}
                                        />
                                    </Grid>
                                ))}
                            {/* </FormGroup> */}
                        </Grid>
                        <div className={classes.popperFlexActionRow}>
                            <Button
                                variant="text"
                                className={classNames(classes.button, classes.dismissButton)}
                                onClick={() => this.setState({
                                    filteredTypes: filteredProjectTypes
                                }, this.handleTypeFilterClose)}
                            >
                                {dictionary['Cancel'] || ''}
                            </Button>
                            <Button
                                variant="contained"
                                className={classNames(classes.button, classes.applyButton)}
                                onClick={() => {
                                    // this.setState({
                                    //     filteredPropertyTypes: filteredTypes
                                    // }, () => {
                                    //     this.handleChangeFilters({ type: Object.keys(filteredTypes).filter(t => !!filteredTypes[t]).join(',') });
                                    //     this.handleTypeFilterClose();
                                    // })
                                    this.handleChangeQueries({ type: Object.keys(filteredTypes).filter(t => !!filteredTypes[t]).join(',') });
                                    this.handleTypeFilterClose();
                                }}>
                                {dictionary['Apply'] || ''}
                            </Button>
                        </div>
                    </FormControl>
                </Popover>
            </div>
        );
    }

    // init = (query) => {
    //     const { keyword, ...otherQueries } = query;
    //     var _self = this;
    //     let filter = {
    //         ...otherQueries
    //     };
    //     // if(region) filter['location.province'] = region;
    //     if(keyword) filter['keyword'] = decodeURIComponent(keyword);
    //     _self.setState({
    //         filter,
    //         keyword: keyword || ''
    //     }, () => _self.search(filter)());
    // }

    init = (query) => {
        // const { group, type, province, numOfBathrooms, numOfBedrooms, area, price } = query;
        const { type, keyword, province, district, ...otherQueries } = query;
        var _self = this;
        let filter = {
            ...otherQueries
        };
        let filteredProjectTypes = {};
        Object.keys(_self.state.filteredProjectTypes).forEach(t => filteredProjectTypes[t] = false);
        if(type) {
            type.split(',').forEach(t => filteredProjectTypes[t] = true);
            filter['type'] = type;
        }
        if(province && province !== '-1') filter['location.province'] = province;
        if(district && district !== '-1') filter['location.district'] = district;
        _self.setState({
            filteredProjectTypes,
            filteredTypes: filteredProjectTypes,
            province,
            district,
            filter
        }, () => _self.fetchData({
            ...filter
        }));
    }

    fetchData = (query) => {
        const { pages, total, ...otherQueries } = query;
        // const { page, limit } = this.state.pagination;
        const queries = Object.keys(otherQueries).filter(k => !!otherQueries[k] && otherQueries[k] !== -1);
        let q = {};
        queries.forEach(k => {
            q[k] = otherQueries[k]
        });
        // const q = {
        //     page,
        //     limit,
        //     ...otherQueries
        // };
        request('/projects', {
            params: {
                ...q
            },
            headers: {
                'language': localStorage.getItem('lang'),
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    const { onChange } = this.props
                    this.setState({
                        projects: res.body.result,
                        pagination: res.body.meta || {}
                    });
                    if (onChange) onChange(res.body)
                }
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    onSearch = (projects) => {
        const { onChange } = this.props;
        this.setState({
            projects
        });
        if (onChange) onChange(projects);
    }

    renderInputComponent = (inputProps) => {
        const { dictionary } = this.props
        const { classes, inputRef = () => {}, ref, ...other } = inputProps;
      
        return (
            <Input
                fullWidth
                // inputProps={{
                //     inputRef: node => {
                //         ref(node);
                //         inputRef(node);
                //         this.fSearchbox = node
                //     },
                //     classes: {
                //         input: classes.input,
                //     },
                // }}
                inputRef={node => {
                    ref(node);
                    inputRef(node);
                    this.fSearchbox = node
                }}
                placeholder={dictionary['Search by location or keyword']}
                className={classes.searchbox}
                disableUnderline
                // classes={{
                //     root: classes.searchbox,
                //     underline: classes.underline
                // }}
                required
                autoComplete="off"
                // fullWidth
                // defaultValue={queries.title || ''}
                // onKeyPress={e => e.key === 'Enter' && this.search()}
                {...other}
            />
        );
    }

    handleSuggestionsFetchRequested = ({ value }) => {
        const {
            filter
        } = this.state;
        // const { page, limit } = this.state.pagination;
        let q = {
            page: 1,
            limit: 10,
            ...filter,
            keyword: value
        };
        // if(propertyGroup) q['group'] = propertyGroup;
        request('/projects', {
            params: q,
            headers: {
                'language': localStorage.getItem('lang'),
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status < 400) {
                    const result = res.body.result.map(el => ({
                        ...el,
                        fullTitle: `${el.title}${el.location && el.location.formattedAddress ? ` - ${el.location.formattedAddress || ''}` : ''}`
                    }))
                    this.setState({
                        suggestions: result,
                        // pagination: res.body.meta || {}
                    });
                    this.onSearch(result);
                }
            }
        }).catch(err => {
        });
    };
    
    handleChangeFilteredTypes = (e, checked) => this.setState({
        filteredTypes: {
            ...this.state.filteredTypes,
            [e.target.value]: checked
        }
    })
    
    handleChangeQueries = queries => {
        let finalQueries = {...this.props.location.query}
        Object.keys(queries).forEach(q => {
            if(queries[q] === -1 || queries[q] === '') {
                const {[q]: removed, ...otherQueries} = finalQueries
                finalQueries = {...otherQueries}
            }
        });
        let filteredQueries = Object.keys(queries).filter(q => queries[q] !== -1 && queries[q] !== '').reduce((obj, item) => {
            obj[item] = queries[item]
            return obj
          }, {})
        finalQueries = {
            // ...this.props.location.query,
            ...finalQueries,
            ...filteredQueries
        }
        browserHistory.push(`/projects${Object.keys(finalQueries).length > 0 ? `?${Object.keys(finalQueries).map(q => `${q}=${encodeURIComponent(finalQueries[q])}`).join('&')}` : ''}`)
    }
    
    handleChangeFilters = queries => this.setState({
        filter: {
            ...this.state.filter,
            ...queries
        }
    }, () => this.search(this.state.filter)())
    
    handleSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        // this.showDetail(suggestion);
        // this.search({ project: suggestion, keyword: suggestion.title })();
        this.search({ project: suggestion })();
    }
    
    handleChangeKeyword = (event, { newValue }) => {
        this.setState({
            filter: {
                ...this.state.filter,
                keyword: newValue
            },
            keyword: newValue
        });
    };
    
    getProjectTypes = () => {
        request('/project-types', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    projectTypes: res.body.result
                });
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getProvinces = () => {
        return request('/provinces', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        provinces: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getDistricts = (province) => {
        return request(`/districts?province=${province}`, {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        districts: res.body.result
                    });
                }
            }
        }).catch(err => {
            // this.props.action.notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    handleTypeFilterClick = event => {
        this.setState({
            typeAnchorEl: event.currentTarget,
        });
    };
    
    handleTypeFilterClose = () => {
        this.setState({
            typeAnchorEl: null,
        });
    }

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    }

    // handleAreaChange = (event, value) => {
    //     this.setState({ area: value });
    // }

    // handlePriceChange = (event, value) => {
    //     this.setState({ price: value });
    // }

    handleAreaChange = area => {
        this.setState({ area });
    }

    handlePriceChange = price => {
        this.setState({ price });
    }

    handleChange = (field, type) => e => this.setState({
        [field]: e.target.value
    })

    // handleChangePropertyGroup = propertyGroup => {
    //     this.setState({ propertyGroup });
    // }

    handleChangePropertyGroup = event => {
        this.setState({ propertyGroup: event.target.value });
    }

    search = ({ keyword, project, ...queries }) => () => {
        let q = {...queries};
        if(project) q['project'] = project._id;
        // if('location.province' in queries) q['region'] = queries['location.province'];
        // else {
        //     let qq = {
        //         page: 1,
        //         limit: 10,
        //         keyword
        //     };
        //     // if(propertyGroup) q['group'] = propertyGroup;
        //     fetch(`${restEndpoint}/projects${!!qq && Object.keys(qq).length > 0 ? '?' + Object.keys(qq).map(k => `${k}=${qq[k]}`).join('&') : ''}`, {
        //         headers: {
        //             'language': localStorage.getItem('lang'),
        //             'x-api-key': apiKey
        //         }
        //     })
        //     .then(res => res.json().then(data => ({
        //         status: res.status,
        //         body: data
        //     })))
        //     .then(res => {
        //         if(!res.stack) {
        //             if(res.status >= 400);
        //             else {
        //                 console.log(res.body.result)
        //             }
        //         }
        //     }).catch(err => {
        //     });
        // }
        if(keyword) q['keyword'] = keyword;
        if(this.fSearchbox) q['keyword'] = this.fSearchbox.value;
        // if(keyword) q['keyword'] = keyword;
        // if(propertyType) q['type'] = propertyType;
        // if(province) q['province'] = province;
        // if(numOfBathrooms) q['numOfBathrooms'] = numOfBathrooms;
        // if(numOfBedrooms) q['numOfBedrooms'] = numOfBedrooms;
        // if(area) q['area'] = area.join(',');
        // if(price) q['price'] = price.join(',');
        if(project) return browserHistory.push(`/project/${toHyphens(project.title)}-${project._id}`);
        // browserHistory.push(`/projects${Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${encodeURIComponent(q[k])}`).join('&') : ''}`);
        request('/projects', {
            params: q,
            headers: {
                'language': localStorage.getItem('lang'),
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status < 400) {
                    const result = res.body.result.map(el => ({
                        ...el,
                        fullTitle: `${el.title}${el.location && el.location.formattedAddress ? ` - ${el.location.formattedAddress || ''}` : ''}`
                    }))
                    this.onSearch(result);
                }
            }
        }).catch(err => {
        });
    }
    
}

export default withRouter(withStyles(styles)(SearchOverlay));