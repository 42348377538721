import React, { Component } from "react";
// import PropTypes from 'prop-types';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { notify } from "common/toast/actions";
import classNames from "classnames";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
// import IconButton from '@material-ui/core/IconButton';
import { Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Parallax from "components/parallax";
import DetailTabs from "./sections/detail-tabs";
import { container } from "assets/jss/theme.global";
// import SwipeableViews from 'react-swipeable-views';
import { loadCSS } from "fg-loadcss/src/loadCSS";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Hidden from "@material-ui/core/Hidden";
// import Paper from '@material-ui/core/Paper';
// import Hero from './sections/hero';
import FormHelperText from "@material-ui/core/FormHelperText";
// import Recaptcha from 'react-recaptcha';
import PhotoGallery from "./sections/photo-gallery";
import RelatedRealEstate from "./sections/related-real-estate";
import MapLocation from "./sections/map-location";
import Footer from "components/footer";
import "./style.css";
// var $ = window.$;
import { multilingual } from "@hoc/multilingual";
import { request } from "lib/http-services";
const config = require("config");
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
const { currencyFormating } = require("lib/helper");

const icons = {
  floorArea: <Icon className="text-grey">home</Icon>,
  bedroom: (
    <img
      src={require("assets/img/icons/bed.png")}
      alt=""
      className="img-icon"
    />
  ),
  bathroom: (
    <img
      src={require("assets/img/icons/accesorii-baie.png")}
      alt=""
      className="img-icon"
    />
  ),
  garage: (
    <img
      src={require("assets/img/icons/garage.png")}
      alt=""
      className="img-icon"
    />
  ),
  direction: (
    <img
      src={require("assets/img/icons/direction.png")}
      alt=""
      className="img-icon"
    />
  ),
};

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      // light: yellow[400],
      main: "#FFE850",
      // dark: yellow[600],
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    padding: "0 !important",
    position: "relative",
  },
  mainContent: {
    ...container,
    // display: 'flex',
    display: "initial",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    alignItems: "center",
    // margin: '24px auto',
    // padding: '24px',
    "max-width": "100% !important",
    // margin: '0 auto',
    // padding: '10px 0 !important',
    padding: "0 !important",
  },
  galleryRoot: {
    ...container,
    maxWidth: "100%",
    flexGrow: 1,
    // maxWidth: '100% !important',
    alignItems: "center",
    // margin: '24px auto',
    // margin: '0 auto',
    margin: "5px auto",
    // padding: '24px'
    width: "auto",
  },
  img: {
    // position: 'relative',
    width: "100%",
    height: 0,
    paddingTop: "60%",
    // margin: '12px auto',
    // overflow: 'hidden'
  },
  imgItem: {
    // position: 'relative',
    width: "calc((100% - 40px) / 5)",
    height: 0,
    paddingTop: "calc(((100% - 40px) / 5) * 0.7)",
    float: "left",
    // margin: '12px auto',
    // overflow: 'hidden',
    cursor: "pointer",
    // 'background-color': 'rgba(0, 0, 0, .5)',
    // 'background-blend-mode': 'overlay',
    "&.active": {
      // 'background-blend-mode': 'inherit'
      "box-shadow": "inset 0 0 50px 25px rgba(0, 0, 0, 0.7)",
    },
    "&:hover": {
      // 'background-blend-mode': 'inherit'
      "box-shadow": "inset 0 0 50px 25px rgba(0, 0, 0, 0.7)",
    },
    "&:not(:nth-child(5n))": {
      marginRight: "10px",
    },
  },
  photoList: {
    width: "100%",
    marginTop: "10px",
  },
  row: {
    width: "100%",
    overflow: "hidden",
    display: "block",
    position: "relative",
    marginBottom: "10px",
  },
  util: {
    float: "left",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: ".5rem 1rem",
    "text-align": "center",
    "font-weight": "bold",
    // width: 'calc(((100% - 6px) / 4) - 2rem)',
    width: "calc((100% - 6px) / 4)",
    "&:not(:last-child)": {
      marginRight: "-1px",
    },
    [theme.breakpoints.up(600)]: {
      // width: 'calc(((100% - 6px) / 5) - 2rem)',
      width: "calc((100% - 6px) / 5)",
    },
  },
  utilDivider: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    margin: "0.5rem auto",
  },
  otherContainer: {
    ...container,
    alignItems: "flex-start",
    // margin: '24px auto',
    padding: "0 !important",
    maxWidth: "100% !important",
  },
  detailColumn: {
    padding: "10px",
    "font-size": "1rem",
    color: "#7A7979",
    position: "sticky",
    top: "calc((83px + 0.625rem) + 1rem)",
  },
  contactSummary: {
    backgroundColor: "#EAEAEA",
    padding: "24px",
    overflow: "hidden",
    "&>$avatar": {
      "background-color": "#73CC2E",
      width: "60px",
      height: "60px",
    },
    "& $saveContactButton": {
      width: "100%",
      color: "#7A7979",
      padding: "16px",
    },
  },
  avatar: {
    float: "left",
    marginRight: "24px",
    "&+div": {
      float: "left",
      textAlign: "left",
      maxWidth: "167px",
    },
  },
  saveContactButton: {},
  contactFormContainer: {
    padding: "0 24px 24px 24px",
    textAlign: "left",
    '& [role="document"] .rc-anchor-light': {
      background: "transparent",
      border: "none",
      "box-shadow": "none",
    },
    "& .captcha": {
      "margin-top": "24px",
      "margin-left": "-25px",
      "margin-bottom": "-27px",
    },
  },
  button: {
    borderRadius: 0,
    "&>span": {
      width: "100%",
    },
    "&>span>span": {
      position: "absolute",
      left: "24px",
    },
    "&.secondary": {
      marginTop: "10px",
    },
    "&.red": {
      backgroundColor: "#FF0000",
      color: "#fff",
    },
    "&.default": {
      backgroundColor: "#ABABAB",
      color: "#fff",
    },
  },
  heroText: {
    textAlign: "left",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    padding: "24px",
    width: "60vw",
    "&::before": {
      width: "100%",
      height: "100%",
      // height: '172px',
    },
  },
  summary: {
    position: "relative",
    padding: "1rem 2rem",
    margin: "1rem 0",
  },
  name: {
    color: "rgba(0, 0, 0, 0.6)",
    "font-size": "1.3rem",
    // 'font-weight': '300',
    "font-weight": "400",
    marginBottom: "1rem",
    textAlign: "left",
  },
  address: {
    color: "#868687",
    // overflow: 'visible',
    "line-height": "1.8rem",
    "margin-bottom": "1rem",
    overflow: "hidden",
    "& span": {
      float: "left",
      "&:not(:last-child)": {
        marginRight: "1rem",
      },
    },
    "& li": {
      float: "left",
      // 'padding-left': '1rem'
    },
  },
  area: {
    color: "#FF001F",
    float: "right !important",
    "font-weight": "bold",
    "font-size": "1.5rem",
    "line-height": ".4rem",
  },
  type: {
    color: "#4B4B4B",
    // 'background-color': '#FFED38',
    float: "right !important",
    "font-size": "1.4rem",
    "line-height": ".4rem",
    padding: "1.5rem .7rem",
    "text-transform": "uppercase",
    position: "absolute",
    // top: '2rem',
    // right: '2rem',
    top: 0,
    right: 0,
    "&.sale": {
      "background-color": "#FFED38",
    },
    "&.lease": {
      "background-color": "#F38D00",
    },
  },
  price: {
    color: "#DF0016",
    float: "right !important",
    "font-size": "1.5rem",
    "line-height": ".4rem",
    // padding: '1.5rem .7rem',
    padding: "1.5rem 0",
    position: "absolute",
    // bottom: '2rem',
    // right: '2rem',
    bottom: 0,
    right: 0,
  },
  utilRow: {
    display: "flex",
    "flex-direction": "row",
  },
  utilName: {
    "font-size": ".75rem",
    color: "#868687",
  },
  rightLableColumn: {
    position: "relative",
    overflow: "hidden",
  },
  fieldError: {
    color: "#f44336",
  },
});

@multilingual
class RealEstateDetail extends Component {
  // constructor(props) {
  //     super(props);

  // }
  state = {
    selectedImage: 0,
    realEstatesDetail: null,
    relatedProperties: [],
    lang: localStorage.getItem("lang"),
    captchaOpened: false,
    errors: {},
    companyProfile: null,
    submitAlert: null,
    alertOpened: false,
  };

  async UNSAFE_componentWillMount() {
    this.getCompanyProfile();
    await this.fetchData();
    await this.getOwnedProject();
  }

  componentDidMount() {
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#insertion-point-jss")
    );
    // $('html, body').animate({ scrollTop: 0 }, 'slow');
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    this.getCompanyProfile();
    await this.fetchData();
    await this.getOwnedProject();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {}

  async componentDidUpdate(prevProps, prevState) {
    if (this.props.params.id !== prevProps.params.id) {
      this.getCompanyProfile();
      await this.fetchData();
      await this.getOwnedProject();
    }
  }

  componentWillUnmount() {}

  render() {
    const { classes, dictionary, language } = this.props;
    const {
      realEstatesDetail,
      relatedProperties,
      captchaOpened,
      errors,
      companyProfile,
      submitAlert,
      alertOpened,
    } = this.state;
    // const { selectedImage } = this.state;

    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className={classes.root}>
          {/* <Parallax image={require('assets/img/villa/villa1.jpg')} /> */}
          {/* <Hero className={classes.hero} /> */}
          <div className={classes.mainContent}>
            {realEstatesDetail && (
              <div className={classes.galleryRoot}>
                {realEstatesDetail.photos &&
                  realEstatesDetail.photos.length > 0 && (
                    <PhotoGallery
                      key={this.props.params.id}
                      photos={realEstatesDetail.photos || []}
                      videos={realEstatesDetail.videos || []}
                    />
                  )}
                <Paper className={classes.summary}>
                  <Grid container>
                    <Grid item xs={12} md={8}>
                      <Typography variant="h6" className={classes.name}>
                        {realEstatesDetail.title}
                      </Typography>
                      {!!realEstatesDetail.type && (
                        <div className={classes.address}>
                          <span>{dictionary["Type of property"]}:</span>
                          <span>{realEstatesDetail.type.title}</span>
                        </div>
                      )}
                      {realEstatesDetail.location &&
                        (Object.keys(realEstatesDetail.location).length > 1 ||
                          realEstatesDetail.location.address !== "") && (
                          <div className={classes.address}>
                            <Icon>location_on</Icon>
                            {/* <span>128</span>
                                                    <li>Nguyễn Thị Minh Khai, Phường 22, Bình Thạnh</li> */}
                            <span>
                              {this.getFormattedAddress(
                                realEstatesDetail.location
                              )}
                            </span>
                            {/* <span className={classes.area}>20 - 30 tr/m<sup>2</sup></span> */}
                          </div>
                        )}
                      <div className={classNames(classes.row, classes.utilRow)}>
                        {/* {Object.keys(detail.utils).map(util => (
                                                    <div key={util} className={classes.util}>
                                                        {icons[util]}
                                                        <Typography className={classes.utilName}>
                                                            {util}
                                                        </Typography>
                                                        <Divider className={classes.utilDivider} />
                                                        <Typography className={classes.utilName}>
                                                            {detail.utils[util]}
                                                        </Typography>
                                                    </div>
                                                ))} */}
                        {!!realEstatesDetail.floorArea ? (
                          <div className={classes.util}>
                            {icons["floorArea"]}
                            <Hidden smDown implementation="css">
                              <Typography className={classes.utilName}>
                                {/* Diện tích (m<sup>2</sup>) */}
                                {dictionary["Floor area"]} (m<sup>2</sup>)
                              </Typography>
                            </Hidden>
                            <Divider className={classes.utilDivider} />
                            <Typography className={classes.utilName}>
                              {/* {realEstatesDetail.floorArea.value} {realEstatesDetail.floorArea.unit.title} */}
                              {realEstatesDetail.floorArea.value}
                            </Typography>
                          </div>
                        ) : null}
                        {!!realEstatesDetail.numOfBedrooms ? (
                          <div className={classes.util}>
                            {icons["bedroom"]}
                            <Hidden smDown implementation="css">
                              <Typography className={classes.utilName}>
                                {dictionary["Bedroom"]}
                              </Typography>
                            </Hidden>
                            <Divider className={classes.utilDivider} />
                            <Typography className={classes.utilName}>
                              {realEstatesDetail.numOfBedrooms}
                            </Typography>
                          </div>
                        ) : null}
                        {!!realEstatesDetail.numOfBathrooms ? (
                          <div className={classes.util}>
                            {icons["bathroom"]}
                            <Hidden smDown implementation="css">
                              <Typography className={classes.utilName}>
                                {dictionary["Bathroom"]}
                              </Typography>
                            </Hidden>
                            <Divider className={classes.utilDivider} />
                            <Typography className={classes.utilName}>
                              {realEstatesDetail.numOfBathrooms}
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className={classes.rightLableColumn}
                    >
                      <span
                        className={classNames(
                          classes.type,
                          realEstatesDetail.group._id === "sale"
                            ? "sale"
                            : "lease"
                        )}
                      >
                        {realEstatesDetail.group._id === "sale"
                          ? dictionary["Sales"]
                          : dictionary["Lease"]}
                      </span>
                      <span className={classes.price}>
                        {realEstatesDetail.price
                          ? `${realEstatesDetail.price.value.toLocaleString(
                              language || "en"
                            )} ${realEstatesDetail.price.currency.name}${
                              realEstatesDetail.group._id === "rent"
                                ? ` / ${realEstatesDetail.price.unit.title}`
                                : ""
                            }`
                          : dictionary["Contact"]}
                      </span>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid container className={classes.otherContainer}>
                  <Grid item xs={12} md={8} className={classes.detailColumn}>
                    <DetailTabs data={realEstatesDetail} />
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs={12} md={4} className={classes.detailColumn}>
                      <Paper square className="sticky">
                        {!!companyProfile && (
                          <div className={classes.contactSummary}>
                            {/* <Avatar className={classes.avatar}>{projectDetail.createdBy.firstName.charAt(0).toUpperCase()}</Avatar> */}
                            {!companyProfile.photo ? (
                              <Avatar className={classes.avatar}>
                                {companyProfile.name.charAt(0).toUpperCase()}
                              </Avatar>
                            ) : (
                              <img
                                src={`${restEndpoint}${companyProfile.photo.path}`}
                                alt=""
                              />
                            )}
                            <div>
                              {/* <b>{projectDetail.createdBy.firstName} {projectDetail.createdBy.lastName}</b>
                                                            <p>Chuyên viên tư vấn</p>
                                                            <p className="truncate" title={projectDetail.createdBy.email}>{projectDetail.createdBy.email}</p> */}
                              <b>{companyProfile.name}</b>
                              {/* <p>Azoro{companyProfile.location && companyProfile.location.province && `- ${companyProfile.location.province.name}`}</p> */}
                              <p
                                className="truncate"
                                title={companyProfile.email}
                              >
                                {companyProfile.email}
                              </p>
                            </div>
                            {/* <Button variant="outlined" className={classNames(classes.button, classes.saveContactButton)}>
                                                            <Icon className="fas fa-qrcode" />
                                                            Lưu danh bạ
                                                        </Button> */}
                            {!!companyProfile.hotline && (
                              <Button
                                variant="contained"
                                fullWidth
                                className={classNames(
                                  classes.button,
                                  "secondary",
                                  "red"
                                )}
                              >
                                <Icon>phone</Icon>
                                {companyProfile.hotline}
                              </Button>
                            )}
                          </div>
                        )}
                        <div className={classes.contactForm}>
                          <form
                            ref={(r) => (this.inboxForm = r)}
                            className={classes.contactFormContainer}
                          >
                            {/* onChange={this.handleCloseCaptcha} */}
                            <TextField
                              id="fullname"
                              label={dictionary["Fullname"]}
                              className={classes.textField}
                              margin="normal"
                              fullWidth
                              required
                              inputRef={(r) => (this.fullname = r)}
                              error={!!errors.fullname}
                              onBlur={this.inboxValidate}
                            />
                            <span className={classes.fieldError}>
                              {errors.fullname || null}
                            </span>
                            <TextField
                              id="phone"
                              label={dictionary["Phone number"]}
                              className={classes.textField}
                              margin="normal"
                              fullWidth
                              required
                              inputRef={(r) => (this.phone = r)}
                              error={!!errors.phone}
                              onBlur={this.inboxValidate}
                            />
                            <span className={classes.fieldError}>
                              {errors.phone || null}
                            </span>
                            <TextField
                              id="email"
                              label={dictionary["Email"]}
                              type="email"
                              className={classes.textField}
                              margin="normal"
                              fullWidth
                              required
                              inputRef={(r) => (this.email = r)}
                              error={!!errors.email}
                              onBlur={this.inboxValidate}
                            />
                            <span className={classes.fieldError}>
                              {errors.email || null}
                            </span>
                            <TextField
                              id="message"
                              label={dictionary["Contact message"]}
                              className={classes.textField}
                              margin="normal"
                              multiline
                              rowsMax="10"
                              fullWidth
                              required
                              inputRef={(r) => (this.message = r)}
                              error={!!errors.message}
                              onBlur={this.inboxValidate}
                            />
                            <span className={classes.fieldError}>
                              {errors.message || null}
                            </span>
                            {!captchaOpened && (
                              <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classNames(
                                  classes.button,
                                  "secondary"
                                )}
                                disabled={
                                  Object.keys(errors).filter(
                                    (err) => !!errors[err]
                                  ).length > 0
                                }
                                onClick={this.handleSubmitContact}
                              >
                                {dictionary["Send request"]}
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              className={classNames(
                                classes.button,
                                "secondary"
                              )}
                              disabled={
                                Object.keys(errors).filter(
                                  (err) => !!errors[err]
                                ).length > 0
                              }
                            >
                              {dictionary["View the show unit"]}
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              className={classNames(
                                classes.button,
                                "secondary"
                              )}
                              disabled={
                                Object.keys(errors).filter(
                                  (err) => !!errors[err]
                                ).length > 0
                              }
                            >
                              {dictionary["Get a quote"]}
                            </Button>
                            {alertOpened && (
                              <FormHelperText>{submitAlert}</FormHelperText>
                            )}
                            {/* {captchaOpened && (
                                                            <div className="captcha">
                                                                <Recaptcha
                                                                    sitekey="6LfkeokUAAAAAOz06NY4j_51a69aWb8wuYPo2sXM"
                                                                    hl={language}
                                                                    render="explicit"
                                                                    onloadCallback={this.inboxRecaptchaLoadCallback}
                                                                    verifyCallback={this.inboxRecaptchaVerifyCallback}
                                                                    // expiredCallback={() => {}}
                                                                    badge="inline"
                                                                    // className={classes.reCaptcha}
                                                                />
                                                            </div>
                                                        )} */}
                          </form>
                        </div>
                      </Paper>
                    </Grid>
                  </Hidden>
                </Grid>
                {/* {!!(realEstatesDetail.location && realEstatesDetail.location.location) && <MapLocation location={{lat: realEstatesDetail.location.location.latitude, lng: realEstatesDetail.location.location.longitude}} />} */}
                {relatedProperties.length > 0 && (
                  <RelatedRealEstate data={relatedProperties} />
                )}
              </div>
            )}
          </div>
        </div>
        <Footer />
      </MuiThemeProvider>
    );
  }

  fetchData = () => {
    return request(`/properties/${this.props.params.id}`, {
      headers: {
        // 'language': localStorage.getItem('lang'),
        language: this.props.language,
      },
    })
      .then((res) => {
        if (!res.stack) {
          if (res.status >= 400) {
            if (res.status === 404) return;
          } else {
            this.setState({
              realEstatesDetail: res.body.result,
            });
            this.getRelatedData({ type: res.body.result.type._id });
          }
        } else dispatch(failure("Vui lòng kiểm tra kết nối mạng."));
      })
      .catch((err) => {
        console.log(err);
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };

  getRelatedData = ({ type }) => {
    request("/properties", {
      params: {
        type,
        exclude: this.props.params.id,
      },
      headers: {
        // 'language': localStorage.getItem('lang'),
        language: this.props.language,
      },
    })
      .then((res) => {
        if (!res.stack) {
          if (res.status >= 400) console.log(res.body.message);
          else
            this.setState({
              relatedProperties: res.body.result,
            });
        } else dispatch(failure("Vui lòng kiểm tra kết nối mạng."));
      })
      .catch((err) => {
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };

  getFormattedAddress = (location) => {
    let strs = [];
    if (location.address) strs.push(location.address);
    if (location.district) strs.push(location.district.name);
    if (location.province) strs.push(location.province.name);
    return strs.join(", ");
  };

  getOwnedProject = () => {
    const { realEstatesDetail } = this.state;
    if (realEstatesDetail) {
      if (!realEstatesDetail.project) return;
      return request(`/projects/${realEstatesDetail.project._id}`, {
        headers: {
          // 'language': localStorage.getItem('lang'),
          language: this.props.language,
        },
      })
        .then((res) => {
          if (!res.stack) {
            if (res.status >= 400) console.log(res.body.message);
            else
              this.setState({
                realEstatesDetail: {
                  ...this.state.realEstatesDetail,
                  project: res.body.result,
                },
              });
          } else dispatch(failure("Vui lòng kiểm tra kết nối mạng."));
        })
        .catch((err) => {
          // notify({
          //     variant: 'error',
          //     message: err.message
          // });
        });
    }
  };

  handleNext = () => {
    this.setState((prevState) => ({
      selectedImage: prevState.selectedImage + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      selectedImage: prevState.selectedImage - 1,
    }));
  };

  handleIndexChange = (selectedImage) => {
    this.setState({ selectedImage });
  };

  inboxRecaptchaLoadCallback = () => {};

  inboxRecaptchaVerifyCallback = (res) => this.sendInbox();

  handleOpenCaptcha = () =>
    this.setState({
      captchaOpened: this.inboxValidate(),
    });

  handleCloseCaptcha = () =>
    this.setState({
      captchaOpened: false,
    });

  inboxValidate = () => {
    let errors = this.state.errors;
    this.inboxForm.checkValidity();
    if (this.fullname.validity.valid) delete errors.fullname;
    errors.fullname = this.fullname.validationMessage;
    if (this.phone.validity.valid) delete errors.phone;
    errors.phone = this.phone.validationMessage;
    if (this.email.validity.valid) delete errors.email;
    errors.email = this.email.validationMessage;
    if (this.message.validity.valid) delete errors.message;
    errors.message = this.message.validationMessage;
    this.setState({
      errors,
    });
    return Object.keys(errors).filter((err) => !!errors[err]).length === 0;
  };

  clearInboxForm = () => {
    this.fullname.value = "";
    this.phone.value = "";
    this.email.value = "";
    this.message.value = "";
  };

  // sendInbox = () => {
  //     const realEstatesDetail = this.state.realEstatesDetail;
  //     request('/property-inbox', {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json',
  //             // 'language': localStorage.getItem('lang'),
  //             'language': this.props.language
  //         },
  //         body: JSON.stringify({
  //             fullname: this.fullname.value,
  //             phone: this.phone.value,
  //             email: this.email.value,
  //             message: this.message.value,
  //             refProduct: realEstatesDetail._id,
  //             to: realEstatesDetail.seller._id,
  //             agency: realEstatesDetail.agency._id
  //         })
  //     })
  //     .then(res => {
  //         if(!res.stack) {
  //             if(res.status >= 400) this.props.action.notify({
  //                 variant: 'error',
  //                 message: res.body.message
  //             });
  //             else {
  //                 this.props.action.notify({
  //                     variant: 'success',
  //                     message: 'Yêu cầu đã được gửi đi' //res.body.message
  //                 });
  //             }
  //         }
  //         else this.props.action.notify({
  //             variant: 'error',
  //             message: 'Vui lòng kiểm tra kết nối mạng.'
  //         });
  //         this.clearInboxForm();
  //         this.handleCloseCaptcha();
  //     })
  //     .catch(err => {
  //         this.props.action.notify({
  //             variant: 'error',
  //             message: err.message
  //         });
  //     });
  // }

  sendInbox = () => {
    const { dictionary, language } = this.props;
    request("/contact-inbox", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        language,
      },
      body: JSON.stringify({
        fullname: this.fullname.value,
        phone: this.phone.value,
        email: this.email.value,
        message: this.message.value,
        // to: seller._id,
        // agency: seller.agency._id || seller.agency
      }),
    })
      .then((res) => {
        if (!res.stack) {
          if (res.status >= 400)
            this.setState({
              submitAlert: res.body.message,
            });
          else
            this.setState(
              {
                submitAlert: dictionary["Request has been sent"],
                alertOpened: true,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    alertOpened: false,
                  });
                }, 3000);
              }
            );
        } else
          this.setState({
            submitAlert: "Something went wrong",
          });
        this.clearInboxForm();
      })
      .catch((err) => {
        this.setState({
          submitAlert: err.message,
          alertOpened: true,
        });
      });
  };
  handleSubmitContact = () => {
    const isValid = this.inboxValidate();
    if (isValid) this.sendInbox();
  };
  getCompanyProfile = () => {
    request("/company-profile")
      .then(async (res) => {
        if (!res.stack) {
          if (res.status >= 400);
          else
            this.setState({
              companyProfile: res.body.result,
            });
        }
        // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
      })
      .catch(() => {
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };
}

RealEstateDetail.propTypes = {};

// export default withStyles(styles, { withTheme: true })(RealEstateDetail);

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.toast,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    action: bindActionCreators(
      {
        notify,
      },
      dispatch
    ),
    dispatch: dispatch,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RealEstateDetail));
