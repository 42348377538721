const config = require('config')
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint
// const token = localStorage.getItem('accessToken')

class ListingApi {
    static uploadPhoto({ token, data }, headers) {
        var formData  = new FormData();
        for(var name in data) {
            formData.append(name, data[name]);
        }
        return fetch(`${restEndpoint}/photos`, {
            method: 'POST',
            headers: {
                token,
                ...headers
            },
            body: formData
        })
        .then(res => res.json().then(data => ({
            status: res.status,
            body: data
        })));
    }
    static updatePhoto({ token, data, id }, headers) {
        var formData  = new FormData();
        for(var name in data) {
            formData.append(name, data[name]);
        }
        return fetch(`${restEndpoint}/photos/${id}`, {
            method: 'PATCH',
            headers: {
                token,
                ...headers
            },
            body: formData
        })
        .then(res => res.json().then(data => ({
            status: res.status,
            body: data
        })));
    }
    static uploadVideo({ token, data }, headers) {
        var formData  = new FormData();
        for(var name in data) {
            formData.append(name, data[name]);
        }
        return fetch(`${restEndpoint}/videos`, {
            method: 'POST',
            headers: {
                token,
                ...headers
            },
            body: formData
        })
        .then(res => res.json().then(data => ({
            status: res.status,
            body: data
        })));
    }
}

export default ListingApi