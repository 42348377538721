import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        // backgroundColor: theme.palette.background.paper,
        // padding: '24px'
    },
    hero: {
        height: '300px',
        position: 'relative',
        // 'min-height': 'calc(100vh - 50px - 103px)'
    },
    heroText: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        '&::before': {
            width: '50vw',
            // height: '100px',
            height: '172px',
        }
    },
    heroTextInside: {
        color: 'rgba(0, 0, 0, 0.6)',
        'font-size': '3rem',
        // 'font-weight': '300',
        'font-weight': '400',
    }
  });
  
class Hero extends Component {
    render() {
        const { classes, dictionary, title } = this.props;
        return (
            <div className={classNames(classes.root, classes.hero, 'cover')}>
                <div className={classNames(classes.heroText, 'hero-text')}>
                    <Typography variant="h6" className={classes.heroTextInside}>{title || ''}</Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Hero);