import React from 'react'
import { request } from 'lib/http-services'
const defaultLanguage = window.localStorage.getItem('lang')
const initialState = {
    languages: [],
    language: defaultLanguage || 'vi',
    dictionary: {}
}
const LanguageContext = React.createContext(initialState)

export const LanguageConsumer = LanguageContext.Consumer

export const multilingualProvider = WrappedComponent => {
    return class extends React.Component {
        state = initialState

        handleChangeLanguage = language => this.setState({ language })

        componentDidMount() {
            this.getLanguages()
        }
        
        shouldComponentUpdate(nextProps, nextState) {
            return true
        }

        getLanguages = () => {
            request('/languages')
            .then(res => {
                let moreState = {}
                if(res.status === 200) {
                    moreState.languages = res.body.result.filter(l => {
                        try {
                            const dictionary = require('dictionary/' + l._id + '.json')
                            return !!dictionary
                        } catch (err) {
                            return false
                        }
                    })
                }
                // const dictionary = require(`dictionary/${this.state.language}.json`)
                this.setState({
                    ...this.state,
                    ...moreState
                }, () => this.tryRequireDictionary(this.state.language))
            })
        }

        changeLanguage = language => {
            window.localStorage.setItem('lang', language)
            this.tryRequireDictionary(language)
            window.location.reload()
        }

        tryRequireDictionary = language => {
            try {
                const dictionary = require('dictionary/' + language + '.json')
                this.setState({
                    language,
                    dictionary
                })
            } catch (err) {
                //language not found
            }
        }

        render() {
            return (
                <LanguageContext.Provider
                    value={{
                        ...this.state,
                        getLanguages: this.getLanguages,
                        changeLanguage: this.changeLanguage
                    }}
                >
                    <WrappedComponent {...this.props} />
                </LanguageContext.Provider>
            )
        }
    }
}
export const multilingual = WrappedComponent => {
    return class extends React.Component {
        
        render() {
            return (
                <LanguageConsumer>
                    {(contextValue) => <WrappedComponent {...this.props} {...contextValue} />}
                </LanguageConsumer>
            )
        }
    }
}