import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import Parallax from 'components/parallax';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import SearchOverlay from './sections/search-overlay';
import ShowCase from './sections/projects';
import RealEstate from './sections/real-estate';
import Footer from 'components/footer';
import Typography from '@material-ui/core/Typography';
import parallaxImage from 'assets/img/home-parallax.jpg';
import { container } from 'assets/jss/theme.global';
import { getFontSize } from 'lib/helper';
import { multilingual } from '@hoc/multilingual';
var $ = window.$;

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      padding: '0 !important',
      position: 'relative'
    },
    appDownload: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '518px'
    },
    searchOverlayContainer: {
        ...container,
        alignItems: 'center',
        margin: '2rem auto',
        width: 'calc(100% - 15px * 2)',
    },
    summaryTitle: {
        color: '#fff',
        fontWeight: 'normal',
        fontSize: '3rem',
        marginBottom: 0
    },
    summarySubtitle: {
        color: '#fff',
        fontSize: '1rem'
    },
    fullScreenHeight: {
        // 'min-height': 'calc(100vh - 50px - 103px - 10px * 2 - 3px)'
        // 'min-height': 'calc(100vh - 50px - 103px + 1px)',
        'min-height': 'calc(100vh - (83px + 0.625rem))',
        display: 'flex',
        'flex-direction': 'column',
        // 'justify-content': 'flex-end',
        'justify-content': 'center',
        position: 'relative',
        'align-items': 'center'
    },
    backgroundVideo: {
        'object-fit': 'cover',
        'object-position': 'center',
        position: 'fixed',
        top: 0, //'calc(-103px - 50px)',
        left: 0,
        width: '100%',
        height: '100%',
        'z-index': -2
    },
    videoOverlay: {
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        'z-index': -1,
        backgroundColor: 'rgba(0, 0, 0, .5)'
    },
    scrollDownButton: {
        color: '#fff',
        position: 'absolute',
        left: 0,
        bottom: 0
    },
    summarySpacing: {
        lineHeight: '3rem',
        textAlign: 'left'
    },
    highlightProperties: {
        '&:not(:blank)': {
            backgroundColor: theme.palette.background.paper,
            padding: '24px 0'
        }
    },
    fadeOutLayer: {
        width: '100%',
        background: '#26293a',
        background: 'linear-gradient(to bottom, rgba(38,41,58,0) 0%, rgba(38,41,58,0.5) 15%, rgba(38,41,58,0.7) 25%, rgba(38,41,58,0.95) 50%, rgba(38,41,58,1) 100%)',
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'center',
        'justify-content': 'center',
        width: '100%',
        padding: '3rem 0 2rem'
    }
});

@multilingual
class Home extends Component {

    componentDidMount() {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
        );
        // $('html, body').animate({ scrollTop: 0 }, 'slow');
    }

    render() {
        const { classes, dictionary } = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    {/* <video autoPlay muted loop id="background-video" poster={require('assets/img/city-night.jpg')} className={classNames(classes.backgroundVideo, 'video-overlay')}>
                        <source src={require('assets/videos/the-savills-day.mp4')} type="video/mp4" />
                    </video> */}
                    <img src={parallaxImage} alt="" className={classNames(classes.backgroundVideo, 'video-overlay')} />
                    <div className={classes.videoOverlay}></div>
                    {/* <Parallax image={require('assets/img/city-night.jpg')} /> */}
                    <div className={classes.fullScreenHeight}>
                        <div className={classes.searchOverlayContainer}>
                            <h1 className={classes.summaryTitle}>
                                {dictionary['The best way to find your home']}
                            </h1>
                            <p variant="h6" className={classes.summarySubtitle}>
                                {dictionary['With over 700,000 active to listings, Realtyspace has the largest inventory of apartments in the United States.']}
                            </p>
                        </div>
                        <div className={classes.fadeOutLayer}>
                            <SearchOverlay className={classes.searchOverlayContainer} />
                        </div>
                        <IconButton className={classNames(classes.scrollDownButton, 'scoll-down-button')} onClick={this.handleScroll}>
                            <Icon className={classNames(classes.icon, 'far fa-hand-point-down')} color="inherit" />
                        </IconButton>
                    </div>
                    <ShowCase id="show-case" />
                    <div className={classes.highlightProperties}>
                        <RealEstate
                            className={classes.searchOverlayContainer}
                            group='sale'
                            header={(
                                <Typography variant="h5" component="h2" className={classes.summarySpacing}>
                                    {dictionary['Apartments for sale'] || ''}
                                </Typography>
                            )}
                        />
                        <RealEstate
                            className={classes.searchOverlayContainer}
                            group='rent'
                            header={(
                                <Typography variant="h5" component="h2" className={classes.summarySpacing}>
                                    {dictionary['Apartments for rent'] || ''}
                                </Typography>
                            )}
                        />
                    </div>
                    {/* <div className={classes.appDownload} style={{backgroundImage: `url(${appDownloadImage})`}}></div> */}
                </div>
                <Footer dictionary={dictionary} />
            </React.Fragment>
        );
    }
    
    handleScroll = () => {
        $('html, body').animate({ scrollTop: $('#show-case').offset().top - (83 + getFontSize() * 0.625) }, 'slow');
    }
}

// Home.propTypes = {

// };

export default withStyles(styles)(Home);