import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import Icon from '@material-ui/core/Icon';
import { multilingual } from '@hoc/multilingual';
import logo from 'assets/img/logo.png';
import heroImage from 'assets/img/shutterstock_1014519766_huge.jpg';
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
import { container } from 'assets/jss/theme.global';
import { request } from 'lib/http-services';

const styles = theme => ({
    root: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // alignItems: 'center',
        // justifyContent: 'space-around',
        // justifyContent: 'space-between',
        overflow: 'hidden',
        position: 'relative'
        // backgroundColor: theme.palette.background.paper,
        // padding: '24px',
        // '& > div': {
        //     width: '100%'
        // }
    },
    container,
    hero: {
        // height: '300px',
        position: 'relative',
        // 'min-height': 'calc(100vh - 50px - 103px)'
        'background-size': 'cover',
        'background-position': 'top',
        backgroundImage: `url(${heroImage})`,
        backgroundRepeat: 'no-repeat',
        // 'background-blend-mode': 'overlay',
        'background-blend-mode': 'soft-light',
        'background-color': 'rgba(38, 41, 58, 1)'
    },
    // heroImage: {
    // },
    brandRow: {
        overflow: 'hidden'
    },
    logo: {
    //   filter: 'grayscale(0.2)',
      width: '83px',
      height: '83px',
      float: 'left',
    },
    brandName: {
        height: '83px',
        'vertical-align': 'bottom',
        color: '#E01818',
        'font-size': '1.2rem',
        'font-weight': 'bold',
        float: 'left',
        'line-height': '137px',
        'margin-left': '1rem',
    },
    heroText: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        // right: '50px',
        right: '2rem',
        transform: 'translateY(-50%)',
        color: 'white',
        // width: '465px',
        width: '260px',
        padding: '24px',
        '&::before': {
            width: '100%',
            // height: '100px',
            height: '100%',
            backgroundColor: '#fff'
        }
    },
    heroTextInside: {
        color: 'rgba(0, 0, 0, 0.6)',
        'font-size': '3rem',
        // 'font-weight': '300',
        'font-weight': '400',
    },
    divider: {
        width: '1px',
        height: '150px',
        margin: '1rem',
        backgroundColor: '#ddd'
    },
    row: {
        // width: '100%',
        overflow: 'hidden',
        color: '#4E4E4E',
        'font-size': '1.1rem',
        'line-height': '1.8rem',
        '& span': {
            float: 'left',
            '&:not(:last-child)': {
                marginRight: '1rem'
            }
        }
    },
    socialRow: {
        overflow: 'hidden',
        // color: '#4E4E4E',
        // padding: '24px',
        margin: 0,
        padding: '24px',
        position: 'relative',
        '& *': {
            color: '#ddd'
        },
        // '& span': {
        //     margin: '0 3px',
        //     // fontSize: '1.5rem',
        //     // color: '#A1A2A1',
        //     '&:hover': {
        //         color: '#4E4E4E',
        //         cursor: 'pointer'
        //     }
        // },
        '& $socialButton': {
            margin: '0 3px',
            fontSize: '3rem',
            color: '#FFE850',
            '&:hover': {
                color: '#4E4E4E',
                cursor: 'pointer'
            },
        }
    },
    socialButton: {},
    form: {
        margin: 0,
        padding: '24px',
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            '&::after': {
                content: '" "',
                width: '1px',
                height: 'calc(100% - 12px - 48px)',
                'background-color': '#ddd',
                position: 'absolute',
                right: 0
            },
        },
        '& *': {
            color: '#ddd'
        },
        '& $icon': {
            color: theme.palette.primary.main,
            marginRight: '1rem'
        }
    },
    icon:  {},
    inputUnderline: {
        '&::before': {
            borderBottom: '1px solid #ddd !important'
        },
        '&::after': {
            borderBottom: '1px solid #ddd !important'
        }
    },
    inputLabel: {
        color: '#ddd !important'
    },
    flexFullWidth: {
        width: 'calc(100% - 24px - 1rem)'
    },
    formHeader: {
        fontSize: '1.2rem'
    },
    companyName: {
        fontSize: '1.5rem',
        whiteSpace: 'pre'
    },
    sendButton: {
        '& > span': {
            // color: '#7A7979'
            color: '#555'
        }
    }
});

@multilingual
class Hero extends Component {
    state = {
        errors: {},
        submitAlert: null,
        alertOpened: false
    }
    componentDidMount() {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
        );
    }
    render() {
        const { errors, submitAlert, alertOpened } = this.state
        const { classes, companyProfile, dictionary, language } = this.props;

        return (
            <form className={classNames(classes.hero, 'o2o_form_382b996a212a4c868ddaee897a9216bc')} ref={r => this.inboxForm = r}>
                <Grid container className={classNames(classes.root, classes.container)} direction="row" alignItems="flex-start" justify="space-between">
                    {/* <img className={classes.heroImage} src={heroImage} alt="" /> */}
                    <Grid spacing={24} item xs={12} md={8} container className={classes.form}>
                        <Grid item xs={12} container alignItems="flex-end" justify="flex-end">
                            <Grid item className={classes.flexFullWidth} container alignItems="center" justify="flex-start">
                                <Typography component="title" className={classes.formHeader}>
                                    {/* {dictionary['apartment-to-find']} */}
                                    {dictionary['Advisory request']}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container alignItems="flex-end">
                            <Grid item>
                                <Icon className={classes.icon}>person</Icon>
                            </Grid>
                            <Grid item className={classes.flexFullWidth}>
                                <FormControl
                                    // className={classNames(classes.margin, classes.textField)}
                                    aria-describedby="fullname-helper-text"
                                    fullWidth
                                    error={'fullname' in errors}
                                >
                                    <InputLabel htmlFor="fullname" classes={{root: classes.inputLabel}}>{dictionary['Fullname']}</InputLabel>
                                    <Input
                                        id="fullname"
                                        classes={{
                                            underline: classes.inputUnderline,
                                            input: 'o2o-fullName'
                                        }}
                                        inputRef={r => this.fullname = r}
                                        // defaultValue={this.state.fullname || ''}
                                        // label="Tên dự án"
                                        onBlur={this.handleChangeField('fullname', null, 'fullname')}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {!!errors['fullname'] && (
                            <Grid item xs={12} container alignItems="flex-end" justify="flex-end">
                                <Grid item className={classes.flexFullWidth}>
                                    <FormHelperText className={classes.fieldError}>{errors['fullname']}</FormHelperText>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6} container alignItems="flex-end">
                            <Grid item>
                                <Icon className={classes.icon}>phone</Icon>
                            </Grid>
                            <Grid item className={classes.flexFullWidth}>
                                <FormControl
                                    // className={classNames(classes.margin, classes.textField)}
                                    aria-describedby="phone-helper-text"
                                    fullWidth
                                    error={'phone' in errors}
                                >
                                    <InputLabel htmlFor="phone" classes={{root: classes.inputLabel}}>{dictionary['Phone number']}</InputLabel>
                                    <Input
                                        id="phone"
                                        classes={{
                                            underline: classes.inputUnderline,
                                            input: 'o2o-phone'
                                        }}
                                        inputRef={r => this.phone = r}
                                        // defaultValue={this.state.fullname || ''}
                                        // label="Tên dự án"
                                        onBlur={this.handleChangeField('phone', null, 'phone')}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} container alignItems="flex-end">
                            <Grid item>
                                <Icon className={classes.icon}>email</Icon>
                            </Grid>
                            <Grid item className={classes.flexFullWidth}>
                                <FormControl
                                    // className={classNames(classes.margin, classes.textField)}
                                    aria-describedby="email-helper-text"
                                    fullWidth
                                    error={'email' in errors}
                                >
                                    <InputLabel htmlFor="email" classes={{root: classes.inputLabel}}>{dictionary['Email']}</InputLabel>
                                    <Input
                                        id="email"
                                        classes={{
                                            underline: classes.inputUnderline,
                                            input: 'o2o-email'
                                        }}
                                        inputRef={r => this.email = r}
                                        // defaultValue={this.state.email || ''}
                                        // label="Tên dự án"
                                        onBlur={this.handleChangeField('email', null, 'email')}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {!!errors['email'] && (
                            <Grid item xs={12} container alignItems="flex-end" justify="flex-end">
                                <Grid item className={classes.flexFullWidth}>
                                    <FormHelperText className={classes.fieldError}>{errors['email']}</FormHelperText>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} container alignItems="flex-end">
                            <Grid item>
                                <Icon className={classes.icon}>description</Icon>
                            </Grid>
                            <Grid item className={classes.flexFullWidth}>
                                <FormControl
                                    // className={classNames(classes.margin, classes.textField)}
                                    aria-describedby="content-helper-text"
                                    fullWidth
                                    error={'content' in errors}
                                >
                                    <InputLabel htmlFor="content" classes={{root: classes.inputLabel}}>{dictionary['Contact message']}</InputLabel>
                                    <Input
                                        id="content"
                                        classes={{
                                            underline: classes.inputUnderline,
                                            inputMultiline: 'o2o-note'
                                        }}
                                        // margin="normal"
                                        multiline
                                        rowsMax="10"
                                        inputRef={r => this.message = r}
                                        // defaultValue={this.state.message || ''}
                                        // label="Tên dự án"
                                        onBlur={this.handleChangeField('content', null, 'content')}
                                        required
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {!!errors['fullname'] && (
                            <Grid item xs={12} container alignItems="flex-end" justify="flex-end">
                                <Grid item className={classes.flexFullWidth}>
                                    <FormHelperText className={classes.fieldError}>{errors['fullname']}</FormHelperText>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12} container alignItems="flex-end" justify="flex-end">
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" color="primary" fullWidth className={classes.sendButton} onClick={this.handleSubmitContact}>{dictionary['Send'] || ''}</Button>
                            </Grid>
                        </Grid>
                        {alertOpened && (
                            <Grid item xs={12} container alignItems="flex-end" justify="flex-end">
                                <Grid item className={classes.flexFullWidth}>
                                    <FormHelperText className={classes.fieldError}>{submitAlert}</FormHelperText>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {/* <Divider className={classes.divider} /> */}
                    <Grid container spacing={24} item xs={12} md={4} className={classes.socialRow}>
                        <Grid item xs={12} container alignItems="center" justify="flex-start">
                            <Typography component="title" className={classes.companyName}>
                                {companyProfile.name || ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} container direction="row" alignItems="center" justify="space-between">
                            <Grid item>
                                <Icon className={classes.icon}>phone</Icon>
                            </Grid>
                            <Grid item className={classes.flexFullWidth} container direction="row" alignItems="center" justify="flex-start">
                                <span>{`Hotline: ${companyProfile.hotline || ''}`}</span>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container direction="row" alignItems="center" justify="space-between">
                            {/* <a href={companyProfile.socials ? companyProfile.socials.twitter || '#' : '#'} target={companyProfile.socials && companyProfile.socials.twitter ? '_blank' : null}><Icon className="fab fa-twitter-square" /></a> */}
                            <a href={companyProfile.website || 'https://www.savills.com.vn'} target="_blank"><Icon className={classNames('fab fa-dribbble-square', classes.socialButton)} /></a>
                            <a href={companyProfile.socials ? companyProfile.socials.facebook || '#' : '#'} target={companyProfile.socials && companyProfile.socials.facebook ? '_blank' : null}><Icon className={classNames('fab fa-facebook-square', classes.socialButton)} /></a>
                            <a href={companyProfile.socials ? companyProfile.socials.linkedin || '#' : '#'} target={companyProfile.socials && companyProfile.socials.linkedin ? '_blank' : null}><Icon className={classNames('fab fa-linkedin', classes.socialButton)} /></a>
                            {/* <a href={companyProfile.socials ? companyProfile.socials.googlePlus || '#' : '#'} target={companyProfile.socials && companyProfile.socials.googlePlus ? '_blank' : null}><Icon className={classNames('fab fa-google-plus-square', classes.socialButton)} /></a>
                            <a href={companyProfile.socials ? companyProfile.socials.youtube || '#' : '#'} target={companyProfile.socials && companyProfile.socials.youtube ? '_blank' : null}><Icon className={classNames('fab fa-youtube-square', classes.socialButton)} /></a> */}
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        );
    }
    handleChangeField = (field, type = 'text', validatingField = null, callback = null) => e => this.setState({ [field]: type === 'number' ? new Number(e.target.value.replace(/,/g, '')).valueOf() : e.target.value }, () => {
        this.validate(validatingField || field)
        if (callback) callback(this.state[field])
    })
    validate = field => {
        return new Promise((res, rej) => {
            try {
                var targetField;
                switch (field) {
                    case 'fullname':
                        targetField = document.querySelector(`#${field}`);
                        if(!targetField.validity.valid) {
                            let errors = {};
                            if(targetField.validity.valueMissing) errors[field] = 'Không bỏ trống';
                            this.setState({
                                errors: {
                                    ...this.state.errors,
                                    ...errors
                                }
                            }, () => res());
                        } else {
                            const { [field]: removed, ...errors } = this.state.errors
                            this.setState({
                                errors
                            }, () => res());
                        }
                        break;
                    case 'phone':
                        targetField = document.querySelector(`#${field}`);
                        if(!targetField.validity.valid) {
                            let errors = {};
                            if(targetField.validity.valueMissing) errors[field] = 'Không bỏ trống';
                            this.setState({
                                errors: {
                                    ...this.state.errors,
                                    ...errors
                                }
                            }, () => res());
                        } else {
                            const { [field]: removed, ...errors } = this.state.errors
                            this.setState({
                                errors
                            }, () => res());
                        }
                        break;
                    case 'email':
                        targetField = document.querySelector(`#${field}`);
                        if(!targetField.validity.valid) {
                            let errors = {};
                            if(targetField.validity.valueMissing) errors[field] = 'Không bỏ trống';
                            this.setState({
                                errors: {
                                    ...this.state.errors,
                                    ...errors
                                }
                            }, () => res());
                        } else {
                            const { [field]: removed, ...errors } = this.state.errors
                            this.setState({
                                errors
                            }, () => res());
                        }
                        break;
                    case 'message':
                        targetField = document.querySelector(`#${field}`);
                        if(!targetField.validity.valid) {
                            let errors = {};
                            if(targetField.validity.valueMissing) errors[field] = 'Không bỏ trống';
                            this.setState({
                                errors: {
                                    ...this.state.errors,
                                    ...errors
                                }
                            }, () => res());
                        } else {
                            const { [field]: removed, ...errors } = this.state.errors
                            this.setState({
                                errors
                            }, () => res());
                        }
                        break;
                
                    default:
                        res();
                        break;
                }
            } catch (err) {
                rej(err);
            }
        });
    }
    inboxValidate = () => {
        let errors = this.state.errors;
        this.inboxForm.checkValidity();
        if(this.fullname.validity.valid) delete errors.fullname;
        errors.fullname = this.fullname.validationMessage;
        if(this.phone.validity.valid) delete errors.phone;
        errors.phone = this.phone.validationMessage;
        if(this.email.validity.valid) delete errors.email;
        errors.email = this.email.validationMessage;
        if(this.message.validity.valid) delete errors.message;
        errors.message = this.message.validationMessage;
        this.setState({
            errors
        });
        return Object.keys(errors).filter(err => !!errors[err]).length === 0;
    }
    clearInboxForm = () => {
        this.fullname.value = '';
        this.phone.value = '';
        this.email.value = '';
        this.message.value = '';
    }
    sendInbox = () => {
        const { dictionary, language } = this.props;
        request('/contact-inbox', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                language
            },
            body: JSON.stringify({
                fullname: this.fullname.value,
                phone: this.phone.value,
                email: this.email.value,
                message: this.message.value,
                // to: seller._id,
                // agency: seller.agency._id || seller.agency
            })
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400) this.setState({
                    submitAlert: res.body.message
                });
                else this.setState({
                    submitAlert: dictionary['Request has been sent'],
                    alertOpened: true
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            alertOpened: false
                        })
                    }, 3000)
                })
            }
            else this.setState({
                submitAlert: 'Something went wrong'
            });
            this.clearInboxForm();
        })
        .catch(err => {
            this.setState({
                submitAlert: err.message,
                alertOpened: true
            });
        });
    }
    handleSubmitContact = () => {
        const isValid = this.inboxValidate();
        if(isValid) this.sendInbox();
    }
}

export default withStyles(styles)(Hero);