import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
// import Calendar from 'react-calendar-material';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Parallax from 'components/parallax';
import Hero from './sections/hero';
import Footer from 'components/footer';
import { container } from 'assets/jss/theme.global';
import s from './style.scss';
import { Divider } from '@material-ui/core';
import { request } from 'lib/http-services';
import { multilingual } from '@hoc/multilingual';
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
const noPhotoImage = require('assets/img/no-photo.jpg');

const relatedFeeds = [
    {
        _id: 1,
        title: 'Sed viverra eleifend nulla at cursus. Ut porta leo odio, nec aliquam velit',
        image: require('assets/img/projects/cac-du-bat-dong-san-2018-se-phat-trien-theo-huong-nao-3.jpg'),
        createdDate: new Date(2018, 7, 1),
        views: 2000,
        comment: 50
    },
    {
        _id: 2,
        title: 'Sed viverra eleifend nulla at cursus. Ut porta leo odio, nec aliquam velit',
        image: require('assets/img/projects/cac-du-bat-dong-san-2018-se-phat-trien-theo-huong-nao-3.jpg'),
        createdDate: new Date(2018, 7, 2),
        views: 200,
        comment: 100
    },
    {
        _id: 3,
        title: 'Sed viverra eleifend nulla at cursus. Ut porta leo odio, nec aliquam velit',
        image: require('assets/img/projects/cac-du-bat-dong-san-2018-se-phat-trien-theo-huong-nao-3.jpg'),
        createdDate: new Date(2018, 7, 3),
        views: 2000,
        comment: 50
    },
];

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      padding: '0 !important',
      position: 'relative'
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        borderRadius: 0
    },
    overlayContainer: {
        // ...container,
        alignItems: 'center',
        margin: '0 auto',
    },
    mainContent: {
        margin: 0,
        'background-color': '#fff',
        margin: 0,
        // 'padding': '24px',
        '&>.container': {
            ...container,
            alignItems: 'center',
            padding: '0 !important'
        }
    },
    icon: {
        '&.left': {
            'margin-right': '1rem'
        },
        '&.right': {
            'margin-left': '1rem'
        },
        '&.small': {
            'font-size': '1rem',
            // color: '#565656',
        },
        // '&+span': {
        //     color: '#565656'
        // },
    },
    rightIcons: {
        marginLeft: 'auto',
    },
});

@multilingual
class NewsDetail extends Component {
    state = {
        postDetail: {}
    }
    componentDidMount() {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
        );
        this.fetchData()
    }
    render() {
        const { classes, dictionary } = this.props;
        const { postDetail } = this.state;

        return (
            <React.Fragment>
                <div className={classes.root}>
                    {/* <Parallax image={require('assets/img/villa/villa1.jpg')} /> */}
                    {/* <Hero className={classes.searchOverlayContainer} /> */}
                    <div className={classNames(classes.mainContent, s.post)}>
                        <div className="container">
                            <Grid container direction="row" spacing={24}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" align="left" style={{padding: '12px 12px 0'}}>
                                        {postDetail.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} className={classes.infoRow} container direction="row" alignItems="center" style={{padding: '0 12px'}}>
                                    <Icon className={classNames(classes.icon, 'far', 'fa-calendar-alt', 'left')} />
                                    <span>{(new Date(postDetail.startDate || postDetail.createdAt)).toLocaleDateString('vi')}</span>
                                    <div className={classes.rightIcons}>
                                        <Icon className={classNames(classes.icon, 'fab', 'fa-facebook', 'right')} />
                                        <Icon className={classNames(classes.icon, 'fab', 'fa-google-plus-square', 'right')} />
                                        <Icon className={classNames(classes.icon, 'fab', 'fa-twitter', 'right')} />
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card className={s.relatedFeed}>
                                        <CardMedia
                                            className={s.mainImage}
                                            image={!!postDetail.photo ? `${restEndpoint}${postDetail.photo.path}` : noPhotoImage}
                                        />
                                        {/* <CardContent className={classes.content}>
                                            <Typography component="p" align="left" dangerouslySetInnerHTML={{__html: postDetail.content}} />
                                        </CardContent> */}
                                    </Card>
                                </Grid>
                                <Grid item xs={12} sm={8} className={s.description}>
                                    <Typography component="p" align="left" dangerouslySetInnerHTML={{__html: postDetail.content}} />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <Footer dictionary={dictionary} />
            </React.Fragment>
        );
    }
    
    fetchData = () => {
        request(`/news/${this.props.params.id}`, {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400) {
                    if (res.status === 404) return;
                } else this.setState({
                    postDetail: res.body.result
                });
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

}

// NewsDetail.propTypes = {

// };

export default withStyles(styles)(NewsDetail);