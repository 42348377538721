import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { notify } from 'common/toast/actions';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Hero from './sections/hero';
import Footer from 'components/footer';
import { container } from 'assets/jss/theme.global';
const config = require('config');
import { request } from 'lib/http-services';
import { multilingual } from '@hoc/multilingual';
import ContactOverlay from './sections/contact-list';
;

const muiTheme = createMuiTheme({
    palette: {
        primary: {
            // light: yellow[400],
            main: '#FFE850',
            // dark: yellow[600],
        },
    },
    typography: {
        useNextVariants: true,
    },
});

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      padding: '0 !important',
      position: 'relative'
    },
    overlayContainer: {
        // ...container,
        alignItems: 'center',
        margin: '0 auto',
        padding: '2rem',
        // 'background-color': '#fff',
        position: 'relative'
    },
    projectCOntainer: {
        ...container,
        alignItems: 'center',
        margin: '24px auto',
    },
    summaryTitle: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '3rem',
        marginBottom: 0
    },
    summarySubtitle: {
        color: '#fff',
        fontSize: '1.3rem'
    },
    formWrapper: {
        'border-radius': 0,
        padding: '0 24px 24px'
    },
    saveContactButton: {
        'border-radius': 0,
        // 'border-color': '#FF0000',
        // color: '#fff',
        'margin-top': '24px',
        backgroundColor: '#FFED38',
        '&>span>span': {
            'margin-right': '1rem'
        },
        // '&:hover': {
        //     'background-color': '#FF0000',
        //     color: '#fff'
        // }
    },
    map: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: 0
    },
    contactColumn: {
        zIndex: 1
    },
    button: {
        borderRadius: 0,
        '&>span': {
            width: '100%'
        },
        '&>span>span': {
            position: 'absolute',
            left: '24px'
        },
        '&.secondary': {
            marginTop: '10px'
        },
        '&.red': {
            backgroundColor: '#FF0000',
            color: '#fff'
        },
        '&.default': {
            backgroundColor: '#ABABAB',
            color: '#fff'
        }
    },
    contactFormContainer: {
        padding: '0 24px 24px 24px',
        textAlign: 'left',
        '& [role="document"] .rc-anchor-light': {
            background: 'transparent',
            border: 'none',
            'box-shadow': 'none'
        },
        '& .captcha': {
            'margin-top': '24px',
            'margin-left': '-25px',
            'margin-bottom': '-27px',
        }
    },
    fieldError: {
        color: '#f44336'
    }
});

@multilingual
class Contact extends Component {
    state = {
        lang: localStorage.getItem('lang'),
        companyProfile: {},
        captchaOpened: false,
        errors: {}
    };

    componentDidMount() {
        this.getCompanyProfile();
    }

    render() {
        const { classes, theme } = this.props;
        const { companyProfile } = this.state;
        
        return (
            <MuiThemeProvider theme={muiTheme}>
                <div className={classes.root}>
                    {/* <Parallax image={require('assets/img/villa/villa1.jpg')} /> */}
                    <Hero
                        className={classes.searchOverlayContainer}
                        companyProfile={companyProfile}
                    />
                    {/* <ShowCase className={classes.projectCOntainer} /> */}
                    <ContactOverlay
                        theme={theme}
                        onClose={this.hideContactOverlay}
                        // location={this.props.location}
                        // pathname={this.props.location.pathname}
                    />
                </div>
                <Footer />
            </MuiThemeProvider>
        );
    }
    
    getCompanyProfile = () => {
        request('/company-profile')
        .then(async (res) => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    companyProfile: res.body.result
                });
                const { location } = res.body.result;
                if(!!this.map && !!location && location.location) {
                    this.map.clearMarkers();
                    await this.map.addMarker({
                        location: {
                            lat: location.location.latitude,
                            lng: location.location.longitude
                        }
                    });
                    this.map.getBounds();
                    this.map.setZoom(15);
                }
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(() => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    inboxRecaptchaLoadCallback = () => {}

    inboxRecaptchaVerifyCallback = () => this.sendInbox()

    handleOpenCaptcha = () => this.setState({
        captchaOpened: this.inboxValidate()
    })

    handleCloseCaptcha = () => this.setState({
        captchaOpened: false
    })

    inboxValidate = () => {
        let errors = this.state.errors;
        this.inboxForm.checkValidity();
        if(this.fullname.validity.valid) delete errors.fullname;
        errors.fullname = this.fullname.validationMessage;
        if(this.phone.validity.valid) delete errors.phone;
        errors.phone = this.phone.validationMessage;
        if(this.email.validity.valid) delete errors.email;
        errors.email = this.email.validationMessage;
        if(this.message.validity.valid) delete errors.message;
        errors.message = this.message.validationMessage;
        this.setState({
            errors
        });
        return Object.keys(errors).filter(err => !!errors[err]).length === 0;
    }

    clearInboxForm = () => {
        this.fullname.value = '';
        this.phone.value = '';
        this.email.value = '';
        this.message.value = '';
    }

    sendInbox = () => {
        request('/contact-inbox', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // 'language': localStorage.getItem('lang'),
                'language': this.props.language,
            },
            body: JSON.stringify({
                fullname: this.fullname.value,
                phone: this.phone.value,
                email: this.email.value,
                message: this.message.value,
                // refProduct: projectDetail._id,
                // to: projectDetail.seller._id,
                // agency: 'savills' //projectDetail.agency._id
            })
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400) this.props.action.notify({
                    variant: 'error',
                    message: res.body.message
                });
                else {
                    this.props.action.notify({
                        variant: 'success',
                        message: 'Yêu cầu đã được gửi đi' //res.body.message
                    });
                }
            }
            else this.props.action.notify({
                variant: 'error',
                message: 'Vui lòng kiểm tra kết nối mạng.'
            });
            this.clearInboxForm();
            this.handleCloseCaptcha();
        })
        .catch(err => {
            this.props.action.notify({
                variant: 'error',
                message: err.message
            });
        });
    }
}

// Project.propTypes = {

// };

// export default withStyles(styles)(Contact);

const mapStateToProps = (state) => {
    return {
        ...state.toast
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators({
            notify
        }, dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Contact))