import * as types from './constants';
import api from './api';

function request() {
    return {
      type: types.REQUEST_START,
      payload: {
        fetching: true,
        fetched: false
      }
    }
  }

  function uploadPhotoSuccess(payload) {
    return {
      type: types.UPLOAD_PHOTO_SUCCESS,
      payload: {
        uploadedPhoto: payload,
        fetching: false,
        fetched: true,
        error: null
      }
    }
  }
  
  function uploadVideoSuccess(payload) {
    return {
      type: types.UPLOAD_PHOTO_SUCCESS,
      payload: {
        uploadedVideo: payload,
        fetching: false,
        fetched: true,
        error: null
      }
    }
  }
  
function failure(err) {
    return {
      type: types.ERROR,
      payload: {
        error: {
          message: err
        },
        fetching: false,
        fetched: false
      }
    }
  }
  
  export function uploadPhoto(data, headers) {
    return (dispatch) => {
        dispatch(request());
        return api.uploadPhoto({ data, token: localStorage.getItem('token') }, headers).then(res => {
          if(!res.stack) {
            // if(res.status >= 400) dispatch(failure('Xảy ra lỗi khi tải ảnh lên, vui lòng thử lại.'));
            if(res.status === 200) dispatch(uploadPhotoSuccess(res.body.result));
            else dispatch(failure(res.body.message));
          }
          else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
          dispatch(failure(err.message));
        });
    }
  }
  
  export function uploadVideo(data, headers) {
    return (dispatch) => {
        dispatch(request());
        return api.uploadVideo({ data, token: localStorage.getItem('token') }, headers).then(res => {
          if(!res.stack) {
            // if(res.status >= 400) dispatch(failure('Xảy ra lỗi khi tải ảnh lên, vui lòng thử lại.'));
            if(res.status === 200) dispatch(uploadVideoSuccess(res.body.result));
            else dispatch(failure(res.body.message));
          }
          else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
          dispatch(failure(err.message));
        });
    }
  }
  
  export function updatePhoto(id, data, headers) {
    return (dispatch) => {
        dispatch(request());
        return api.updatePhoto({ id, data, token: localStorage.getItem('token') }, headers).then(res => {
          if(!res.stack) {
            // if(res.status >= 400) dispatch(failure('Xảy ra lỗi khi tải ảnh lên, vui lòng thử lại.'));
            if(res.status === 200) dispatch(uploadPhotoSuccess(res.body.result));
            else dispatch(failure(res.body.message));
          }
          else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
          dispatch(failure(err.message));
        });
    }
  }
  