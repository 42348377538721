import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
// import { Link } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
// import List from '@material-ui/core/List';
// import ListItem from "@material-ui/core/ListItem";
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
// import Button from '@material-ui/core/Button';
import headerStyle from './style.js';
// import s from './style.scss';

const styles = theme => ({
  ...headerStyle,
  root: {
    flexGrow: 1,
    width: '100%'
  },
  menuButton: {
    padding: '0 .4rem'
  }
});

class Header extends Component {
  state = {
    id: `header-${Date.now()}`,
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  componentDidMount() {
    const { classes, changeColorOnScroll } = this.props;
    const pinned = !document.body.getElementsByTagName("header")[0].classList.contains(classes.transparent);
    if (changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
      if(pinned) this.changeBackColor(changeColorOnScroll.invertHeight);
      else this.changeBackColor(changeColorOnScroll.height);
    }
  }

  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }

  render() {
    const {
      classes,
      className,
      color,
      rightLinks,
      leftLinks,
      sideNavLinks,
      brand,
      logo,
      fixed,
      absolute,
      sticky,
      menuButton
    } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
      [classes.sticky]: sticky
    });
    // const brandComponent = brand && (
    //   <Button className={classes.title}>
    //     {brand}
    //   </Button>
    // );
    return (
      <AppBar className={classNames(appBarClasses, ...(className || '').split(' '), this.state.id, 'top-menu')}>
        <Toolbar className={classes.container}>
          {/* <Hidden mdUp>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer('left', true)}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <img src={logo} alt="" className={classes.logo} />
          <Typography variant="h6" color="inherit" className={classes.topNav}>
            <Link className={classes.topNavItem} activeClassName="active" to="/buy">Mua</Link>
            <Link className={classes.topNavItem} activeClassName="active" to="?group=rent">Thuê</Link>
          </Typography>
          <div>
            <Button color="inherit">Login</Button>
            <Button color="inherit">Signup</Button>
          </div> */}

          {brand || logo}
          <div className={classes.flex}>
            {leftLinks}
          </div>
          <div>
            {rightLinks}
          </div>
          {menuButton && (
            <Hidden mdUp>
              <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={this.toggleDrawer('right', true)}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
        </Toolbar>
        <Hidden mdUp implementation="css">
          <SwipeableDrawer
            classes={{
              paper: classes.drawerPaper
            }}
            anchor="right"
            open={this.state.right}
            onClose={this.toggleDrawer('right', false)}
            onOpen={this.toggleDrawer('right', true)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={this.toggleDrawer('right', false)}
              onKeyDown={this.toggleDrawer('right', false)}
            >
              {sideNavLinks}
            </div>
          </SwipeableDrawer>
        </Hidden>
      </AppBar>
    );
  }

  headerColorChange = () => {
    const { classes, changeColorOnScroll } = this.props;
    const pinned = !document.body.getElementsByTagName("header")[0].classList.contains(classes.transparent);
    if(pinned) this.changeBackColor(changeColorOnScroll.invertHeight);
    else this.changeBackColor(changeColorOnScroll.height);
  }

  changeBackColor = (scrollHeight) => {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    let header = document.body.querySelector(`.${this.state.id}`);
    if (windowsScrollTop > scrollHeight) {
      // header.classList.remove(classes[color]);
      // header.classList.add(classes[changeColorOnScroll.color], classes.pinned, 'pinned');
      header.classList.add(classes[changeColorOnScroll.color], classes.pinned, 'pinned');
    } else {
      // header.classList.add(classes[color]);
      // header.classList.remove(classes[changeColorOnScroll.color], classes.pinned, 'pinned');
      header.classList.remove(classes[changeColorOnScroll.color], classes.pinned, 'pinned');
      header.classList.add(classes[color]);
    }
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  }

}

Header.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "glass",
    "white",
    "rose",
    "dark",
    'savills-grey',
    'fade-out-grey'
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  sticky: PropTypes.bool,
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "glass",
      "white",
      "rose",
      "dark",
      'savills-grey',
      'fade-out-grey'
    ]).isRequired
  })
};

Header.defaultProp = {
  id: `header-${Date.now()}`,
  color: "white"
};

export default withStyles(styles)(Header);
