import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import Typography from '@material-ui/core/Typography';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
import Map from 'common/map';
import s from './style.scss';

const styles = theme => ({
    root: {
        // width: 'calc(100vw - 14px)',
        width: '100vw',
        'margin-left': '50%',
        transform: 'translateX(-50%)',
        padding: '2rem',
        'margin-top': '1rem'
    },
    map: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        'background-color': theme.palette.background.paper,
        'z-index': 0
    },
    htmlFormWrapper: {
        'border-radius': 0,
        padding: '0 24px 24px'
    },
    htmlFormColumn: {
        'z-index': 1
    },
    tableCell: {
        'font-size': '1rem',
        'font-weight': 'inherit'
    },
    directionIcon: {
        'color': '#989898',
        '&.active': '#444D5A'
    },
    coverNearby: {
        width: '405px',
        position: 'absolute',
        top: '30px',
        left: '0',
        transition: 'transform .25s linear',
        bottom: '30px'
    }
  });
  
class MapLocation extends Component {
    state = {
        active: true
    }
    
    async componentDidMount() {
        if(!!this.map) {
            this.map.clearMarkers();
            await this.map.addMarker({
                location: {...(this.props.location || {lat: 0, lng: 0})},
                options: {
                    type: 'property',
                }
            });
            this.map.getBounds();
            this.map.setZoom(15);
        }
    }

    render() {
        const { classes, className } = this.props;
        const { active } = this.state;
        return (
            <Grid container justify="flex-start" alignItems="flex-start" className={classNames(classes.root, s.mapDetail, className || '')}>
                <div className={classes.map}>
                    <Map
                        ref={m => {
                            this.map = m;
                        }}
                    />
                </div>
                {/* <Grid item xs={12} md={4} className={classes.formColumn}>
                    <div className={`${s.coverNearby}${active ? ` ${s.active}` : ''}`}>
                        <div className={s.nearby}>
                            <div className={s.parentContent}>
                                <form name="search-nearby">
                                    <input type="text" id="nearby-search" className={s.input} autoComplete="off" spellCheck="false" dir="auto" placeholder="Tìm kiếm địa điểm gần đây" />
                                </form>
                                <div className={s.title}>
                                    <span>Bản đồ</span>
                                    <div className={`${s.listIcon} ${s.trafficType}`}>
                                        <a className={s.bus} title="Xe bus" data-type="TRANSIT">
                                            <Icon>directions_bus</Icon>
                                        </a>
                                        <a className={`${s.car} ${s.active}`} title="Ô tô" data-type="DRIVING">
                                            <Icon>directions_car</Icon>
                                        </a>
                                        <a className={s.byFoot} title="Đi bộ" data-type="WALKING">
                                            <Icon>directions_walk</Icon>
                                        </a>
                                    </div>
                                </div>
                                <ul className={`${s.content} ${s.directions} pdb-0`}>
                                    <li data-value="10.7725616@106.6958022">
                                        <span className={s.left}>Chợ Bến Thành</span>
                                        <span className={s.right}>1.97 km</span>
                                    </li>
                                    <li data-value="10.8184684@106.6566358">
                                        <span className={s.left}>Sân bay Tân Sơn Nhất</span>
                                        <span className={s.right}>7.87 km</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={s.iconLeftNearby}>
                            <a className={s.location} title="Vị trí của BĐS">
                                <Icon>place</Icon>
                            </a>
                            <a className={s.iconSlide} title="Mở rộng / thu gọn" onClick={this.toggleForm}>
                                <Icon>{active ? 'chevron_left' : 'chevron_right'}</Icon>
                            </a>
                        </div>
                    </div>
                </Grid> */}
            </Grid>
        );
    }

    toggleForm = () => this.setState({
        active: !this.state.active
    })
}

export default withStyles(styles)(MapLocation);