import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
// import { modalContent } from 'assets/jss/theme.global';
// import s from './style.scss'

const styles = theme => ({
    modalCardPaper: {
        '& > div': {
            padding: '0 !important'
        }
    },
    root: {
        position: 'absolute',
        // width: theme.spacing.unit * 50,
        // backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing.unit * 4,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '& .modal-card': {
            // 'border-radius': 0,
            '& .modal-actions': {
                'background-color': '#FFE850',
                padding: '1em'
            },
            '& .modal-title': {
                'font-size': '1em',
                color: '#FC3C09'
            }
        },
        '& .form-actions': {
            textAlign: 'right',
            width: '100%',
            padding: '0 calc(12px - 4px)',
            '& $button': {
                '&:not(:last-of-type)': {
                    marginRight: '1em'
                }
            }
        }
    },
    button: {
        color: '#606060',
        padding: '0 2.5em',
        'font-weight': 'bold',
        'text-transform': 'inherit',
        'font-size': '1em',
        'border-radius': 0
    },
    modal: {
        position: 'absolute',
        // width: theme.spacing.unit * 50,
        // backgroundColor: theme.palette.background.paper,
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing.unit * 4,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // '& $content': {
        //     ...modalContent
        // },
        '& .modal-card': {
            // 'border-radius': 0,
            '& .modal-actions': {
                'background-color': '#FFE850',
                padding: '1em'
            },
            '& .modal-title': {
                'font-size': '1em',
                color: '#606060'
            }
        },
        '& .form-actions': {
            textAlign: 'right',
            width: '100%',
            // padding: '0 calc(12px - 4px) calc(24px - 8px)',
            // padding: 'calc(12px - 4px) calc(24px - 8px)',
            padding: 'calc(12px - 4px) 0',
            '& $button': {
                '&:not(:last-of-type)': {
                    marginRight: '1em'
                }
            }
        }
    },
    content: {},
    btnClose: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: '#ddd'
    }
})

class SModal extends Component {
    constructor(props) {
        super(props)

    }

    UNSAFE_componentWillMount() {

    }

    componentDidMount() {

    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        const { theme, classes, classnames, open, onClose, actionButtons, children, title, noAction, style, ...otherProps } = this.props
        return (
            <MuiThemeProvider theme={theme}>
                <Modal
                    open={open}
                    onClose={onClose}
                    {...otherProps}
                >
                    <div>
                        <Grid container justify="center" style={{outline: 'none'}} className={classes.modal}>
                            <Grid item xs={12} className={classNames(classes.root, classnames.root)} style={style || {}}>
                                <Card className={classNames('modal-card', classnames.paper || '', classes.modalCardPaper)}>
                                {/* <Card className={classNames('modal-card')}> */}
                                    {!!title && (
                                        <CardActions color="primary" className="modal-actions header">
                                            <Typography className="modal-title">
                                                {title}
                                            </Typography>
                                        </CardActions>
                                    )}
                                    <CardContent className={classNames(classes.content, classnames.content, 'think-scroll')}>
                                        {children}
                                    </CardContent>
                                    {!noAction && (
                                        <CardActions>
                                            <div className="form-actions">
                                                <Button variant="text" size="medium" color="default" className={classes.button} onClick={onClose}>
                                                    Đóng
                                                </Button>
                                                {actionButtons}
                                            </div>
                                        </CardActions>
                                    )}
                                </Card>
                            </Grid>
                        </Grid>
                        {!!noAction && (
                            <IconButton className={classes.btnClose} onClick={onClose}>
                                <Icon>close</Icon>
                            </IconButton>
                        )}
                   </div>
                </Modal>
            </MuiThemeProvider>
        )
    }
}

SModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    actionButtons: PropTypes.array,
    children: PropTypes.any,
    title: PropTypes.string,
    classnames: PropTypes.any,
}

SModal.defaultProps = {
    classnames: {}
}

export default withStyles(styles)(SModal)