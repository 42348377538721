import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import classNames from "classnames";
import { browserHistory } from 'react-router';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
// import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import yellow from '@material-ui/core/colors/yellow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Calendar from 'react-calendar-material';
// import Parallax from 'components/parallax';
import Footer from 'components/footer';
import Hero from './sections/hero';
import { container } from 'assets/jss/theme.global';
import s from './style.scss';
import { request } from 'lib/http-services';
const { toHyphens } = require('lib/helper');
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
const noPhotoImage = require('assets/img/no-photo.jpg');

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: yellow[400],
            main: yellow[500],
            // dark: yellow[600],
        },
    },
    typography: {
        useNextVariants: true,
    },
});

const data = [
    {
        _id: 1,
        image: require('assets/img/projects/bat-dong-san.jpg'),
        title: 'About Savils',
        desc: 'Aenean lacus lacus, sollicitudin eu tortor sit amet, condimentum maximus arcu. Curabitur elementum dui turpis, eget convallis felis fringilla eu. Phasellus et purus vestibulum, faucibus mi in, tincidunt mi. Fusce vel nisl nunc. Quisque rutrum sollicitudin molestie. Quisque bibendum dolor leo, vel scelerisque lectus condimentum sit amet. Donec malesuada dolor scelerisque, accumsan quam a, lobortis libero. Curabitur est ipsum, faucibus id tellus sit.'
    },
    {
        _id: 2,
        image: require('assets/img/projects/bat-dong-san.jpg'),
        title: 'Key Facts',
        desc: 'Nullam tincidunt lectus posuere nisi convallis rhoncus. Pellentesque consectetur leo vel lacus consequat, et semper nisi auctor. Curabitur dui magna, commodo tempor velit eu, fringilla pulvinar odio. Sed at rhoncus odio. Nam quis lobortis velit. Duis convallis ex mauris, sed pretium turpis auctor vitae. Nunc libero quam, condimentum nec felis ut, tempus placerat enim. Fusce a mi ultrices, mattis diam ac, semper diam.'
    },
    {
        _id: 3,
        image: require('assets/img/projects/bat-dong-san.jpg'),
        title: 'Vision & Values',
        desc: 'Praesent sit amet sollicitudin lacus. Morbi a tincidunt nisl. In vel metus vitae diam hendrerit tempor sed eu nulla. Sed facilisis dapibus tellus a bibendum. Sed et augue tincidunt, posuere purus eu, volutpat nisi. Cras ex tellus, faucibus nec venenatis eget, commodo sit amet nunc. Fusce ante ex, sagittis sed risus vitae, ultrices mollis lorem. Suspendisse fermentum bibendum varius. Morbi at rutrum dolor.'
    },
    {
        _id: 4,
        image: require('assets/img/projects/bat-dong-san.jpg'),
        title: 'History',
        desc: 'Donec hendrerit sed erat eget imperdiet. Suspendisse porttitor metus quam, quis ornare ante convallis nec. Quisque egestas metus sed dui feugiat tincidunt. Praesent lorem arcu, egestas non libero tincidunt, pulvinar feugiat felis. Proin mollis ultricies nisi, non semper purus sodales in. Ut ut feugiat turpis. Quisque convallis non erat non feugiat. Suspendisse dapibus efficitur vehicula. Etiam id gravida velit. Aenean finibus pellentesque urna.'
    }
];

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      padding: '0 !important',
      position: 'relative'
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        borderRadius: 0
    },
    card: {
        'border-radius': 0,
        '&.half': {
            display: 'flex',
            'flex-direction': 'row',
            position: 'relative',
            '&.reverse': {
                'flex-direction': 'row-reverse'
            },
            '& $content': {
                width: 'calc(50% - 24px * 2)',
                display: 'flex',
                'flex-direction': 'column',
                'justify-content': 'space-between',
                padding: '16px 24px',
                '& .desc.truncate': {
                    display: '-webkit-box',
                    'max-width': '100%',
                    'max-height': 'calc(1.46429em * 5)',
                    margin: '0 auto',
                    'line-height': 1.46429,
                    '-webkit-line-clamp': 5,
                    '-webkit-box-orient': 'vertical',
                    overflow: 'hidden',
                    'text-overflow': 'ellipsis',
                    'white-space': 'normal'
                }
            }
        }
    },
    content: {},
    overlayContainer: {
        // ...container,
        alignItems: 'center',
        margin: '0 auto',
    },
    mainContent: {
        margin: 0,
        'background-color': '#fff',
        margin: 0,
        // 'padding': '24px',
        '&>.container': {
            ...container,
            alignItems: 'center',
        }
    },
    icon: {
        '&.left': {
            'margin-right': '1rem'
        },
        '&.right': {
            'margin-left': '1rem'
        },
        '&.small': {
            'font-size': '1rem',
            color: '#565656',
        },
        '&+span': {
            color: '#565656'
        }
    },
});
  
class Introductions extends Component {
    state = {
        posts: []
    }

    componentDidMount() {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
        );
    }

    UNSAFE_componentWillMount() {
        this.fetchData();
    }

    render() {
        const { classes, dictionary } = this.props;
        const { posts } = this.state;

        return (
            <React.Fragment>
                <MuiThemeProvider theme={theme}>
                    <div className={classes.root}>
                        {/* <Parallax image={require('assets/img/introductions-parallax.jpg')} /> */}
                        <Hero dictionary={dictionary} />
                        <div className={classNames(classes.mainContent, s.post)}>
                            <div className="container">
                                <Grid container direction="row" spacing={24}>
                                    <Grid item xs={12} sm={8}>
                                        {posts.map((tile, i) => (
                                            <Card key={tile._id} className={classNames(classes.card, 'half', i % 2 !== 0 && 'reverse')}>
                                                <CardMedia
                                                    className={classNames(s.mainImage, s.half)}
                                                    image={!!tile.photo ? `${restEndpoint}${tile.photo.path}` : noPhotoImage}
                                                />
                                                <CardContent className={classes.content}>
                                                    <h4 className={s.postTitle}>
                                                        {tile.title}
                                                    </h4>
                                                    <Typography component="p" align="left" className="desc truncate">
                                                        {tile.description}
                                                    </Typography>
                                                    <Typography component="p" align="right">
                                                        <Button variant="contained" color="primary" className={s.detailButton} onClick={() => this.showDetail(tile)}>
                                                            {dictionary['Detail'] || ''}
                                                        </Button>
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        ))}
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Card className={classes.card}>
                                            <CardMedia
                                                className={s.logo}
                                                image={require('assets/img/logo.png')}
                                            />
                                            <CardContent className={s.noPadding}>
                                                <List>
                                                    {data.map((tile, i) => (
                                                        <ListItem key={tile._id} button onClick={() => this.showDetail(tile)}>
                                                            <ListItemText primary={tile.title} className="truncate" />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </MuiThemeProvider>
                <Footer dictionary={dictionary} />
            </React.Fragment>
        );
    }

    showDetail = ({ _id, title, alias }) => {
        browserHistory.push(`/introductions/${alias || toHyphens(title)}-${_id}`);
    }
    
    fetchData = () => {
        request('/posts', {
            params: {
                page: 1,
                limit: 10
            },
            headers: {
                'language': localStorage.getItem('lang'),
                module: 'introduction-cms'
            }
        })
        // .then(res => res.json().then(data => ({
        //     status: res.status,
        //     body: data
        // })))
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    posts: res.body.result
                });
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

}

// News.propTypes = {

// };

export default withStyles(styles)(Introductions);