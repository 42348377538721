import React, { Component } from 'react';
// import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import NativeSelect from '@material-ui/core/NativeSelect';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
// import Slider from '@material-ui/lab/Slider';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import red from '@material-ui/core/colors/red';
import Recaptcha from 'react-recaptcha';
// import { Range } from 'rc-slider';
import Pagination from 'common/pagination';
import Modal from 'common/modal';
import { container } from 'assets/jss/theme.global';
// import 'rc-slider/assets/index.css';
import s from './style.scss';
import { request } from 'lib/http-services';
const { forEachAsync } = require('lib/helper');
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
;
const noPhoto = require('assets/img/no-photo.jpg');

// const theme = createMuiTheme({
//     palette: {
//         primary: {
//             // light: yellow[400],
//             main: red[500],
//             // dark: yellow[600],
//         },
//     },
// });

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    mainContent: {
        ...container,
        // display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        // overflow: 'hidden',
        // backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        // margin: '24px auto',
        // padding: '24px',
        // 'max-width': '100% !important',
        // margin: '0 auto',
        // padding: '10px 0 !important',
        // padding: '0 !important',
        width: 'calc(100% - 15px * 2)',
        'margin-bottom': '2rem'
    },
    flex: {
        display: 'flex'
    },
    // tabsRoot: {
    //     // borderBottom: '1px solid #e8e8e8',
    //     padding: '0 24px'
    // },
    media: {
        height: 0,
        paddingTop: '75%'//'56.25%'
    },
    searchButton: {
        float: 'right',
        // marginRight: '-8px',
        backgroundColor: '#FFED38',
        'text-transform': 'inherit',
        'border-radius': 0
    },
    slider: {
        // '& .rc-slider-track': {
        //     'background-color': '#f00'
        // },
        // '& .rc-slider-handle': {
        //     border: 'solid 2px #f00'
        // },
        // '& .rc-slider-handle:active': {
        //     'border-color': '#f00',
        //     'box-shadow': '0 0 5px #f00'
        // },
        // '& .rc-slider-handle:hover': {
        //     'border-color': '#f00'
        // },
        // '& .rc-slider-handle:focus': {
        //     'border-color': '#f00'
        // }
    },
    sliderLabel: {
        // 'font-weight': 'bold',
        // color: '#fff',
        'margin-bottom': '5px',
        '&>span': {
            color: '#8E8C8C'
        }
    },
    sliderWrapper: {
        // padding: '12px 7px !important',
        display: 'flex',
        'flex-direction': 'column',
        'align-items': 'flex-start',
        'justify-content': 'flex-end'
    },
    formHeader: {
        'font-size': '2rem',
        padding: '2rem',
        'text-align': 'center',
        'font-weight': 'lighter'
    },
    flexFullWidth: {
        width: 'calc(100% - 48px)'
    },
    formGroup: {
        'margin-bottom': '24px',
        float: 'left',
        width: '100%',
        '&:not(:first-child)': {
            'margin-top': '24px',
        }
    },
    formGroupTitle: {
        'font-size': '1rem',
        'font-weight': 'bold',
        display: 'block',
        color: '#767676'
    },
    uploadInput: {
        display: 'none'
    },
    uploadField: {
        cursor: 'pointer',
        '&:hover span': {
            color: 'rgba(0, 0, 0, 0.87)'
        },
        '& *': {
            cursor: 'pointer'
        }
    },
    icon: {
        // margin: theme.spacing.unit * 2,
        '&.left': {
            marginRight: '0.4rem'
        },
        '&.right': {
            marginLeft: '0.4rem'
        }
    },
    infoRow: {
        '&:not(:first-child)': {
            marginTop: '-12px'
        }
    },
    contactFormModel: {
        width: 'calc(100% - 12px * 2)',
        [theme.breakpoints.up('sm')]: {
            // maxWidth: '350px',
            maxWidth: '450px'
        },
    },
    contactFormModelContent: {
        padding: '24px'
    },
    contactSummary: {
        // backgroundColor: '#EAEAEA',
        // padding: '24px',
        overflow: 'hidden',
        height: '120px',
        '&>$avatar': {
            'background-color': '#73CC2E',
            // width: '60px',
            // height: '60px',
            width: '100px',
            height: '100px'
        },
        '& $saveContactButton': {
            width: '100%',
            color: '#7A7979',
            padding: '16px'
        }
    },
    avatar: {
        float: 'left',
        marginRight: '24px',
        '&+div': {
            float: 'left',
            textAlign: 'left',
            maxWidth: '167px'
        }
    },
    saveContactButton: {},
    sellerSummaryRow: {
        maxWidth: 'calc(100% - 24px - 0.4rem)'
    },
    flexRightColumn: {
        // maxWidth: 'calc(100% - 60px - 24px) !important',
        maxWidth: 'calc(100% - 100px - 24px) !important'
    },
    callButton: {
        'text-transform': 'none',
        '& $phoneNumber': {
            'text-decoration': 'none',
            'margin-left': '1rem',
            color: '#f00',
            'font-size': '1rem'
        }
    },
    phoneNumber: {}
});

function TabContainer({ children, className }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }} className={className}>
            {children}
        </Typography>
    );
}

class RealEstateSearch extends Component {
    state = {
        pagination: {
            page: 1,
            limit: 12
        },
        sellers: [],
        amenities: {},
        provinces: [],
        listingGroups: [],
        listingGroup: 1,
        photos: {},
        videos: {},
        title: '',
        lang: localStorage.getItem('lang'),
        captchaOpened: false,
        errors: {},
        contactFormOpened: false,
        seller: null,
    }

    componentDidMount() {
        this.getSellers({ ...this.state.pagination, ...this.props.location.query });
    }

    shouldComponentUpdate() {
        return true;
    }

    // async componentWillReceiveProps(nextProps) {
    //     if(nextProps !== this.props) {
    //         await this.getPropertyTypes();
    //         await this.getAmenities();
    //     }
    // }

    componentDidUpdate(prevProps) {
        if(this.props.location.query !== prevProps.location.query) {
            this.getSellers({ ...this.state.pagination, ...this.props.location.query });
        }
    }

    render() {
        const { classes, dictionary, theme } = this.props;
        const {
            pagination,
            sellers,
            lang,
            captchaOpened,
            errors,
            contactFormOpened,
            seller
        } = this.state;
        
        return (
            // <MuiThemeProvider theme={theme}>
                <div className={classes.root}>
                    <div className={classes.mainContent}>
                        <Typography component="title" className={classes.formHeader}>
                            {/* {dictionary['apartment-to-find']} */}
                            {dictionary['Contact']}
                        </Typography>
                        <Grid container spacing={24} style={{marginBottom: '12px'}}>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    id="keyword"
                                    type="text"
                                    inputRef={r => this.fSearchbox = r}
                                    placeholder={dictionary['Search by name']}
                                    className={classes.searchbox}
                                    // disableUnderline
                                    // classes={{
                                    //     root: classes.searchbox,
                                    //     underline: classes.underline
                                    // }}
                                    required
                                    autoComplete="off"
                                    fullWidth
                                    // defaultValue={queries.title || ''}
                                    onKeyPress={e => e.key === 'Enter' && this.getSellers({ name: this.fSearchbox.value })}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            {/* <IconButton>
                                                <Icon className={classes.icon}>search</Icon>
                                            </IconButton> */}
                                            <Icon
                                                color="primary"
                                                className={classNames(classes.icon, classes.searchingButton)}
                                                onClick={() => this.getSellers({ name: this.fSearchbox.value })}
                                            >search</Icon>
                                        </InputAdornment>
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={24}>
                            {sellers.map(seller => (
                                <Grid key={seller._id} item xs={12} sm={6} md={4} lg={3}>
                                    <Card className={classes.card}>
                                        <CardMedia
                                            className={classes.media}
                                            image={!!seller.photo ? `${restEndpoint}${seller.photo.path}` : noPhoto}
                                            title={`${seller.firstName} ${seller.lastName}`}
                                        />
                                        <CardContent>
                                            <Grid container spacing={24} direction="column" alignItems="flex-start">
                                                <Grid item xs={12} className={classNames('truncate', classes.infoRow)} container direction="row" alignItems="center">
                                                    <Icon color="primary" className={classNames(classes.icon, 'left')}>person_outline</Icon> {`${seller.firstName} ${seller.lastName}`}
                                                </Grid>
                                                <Grid item xs={12} className={classNames('truncate', classes.infoRow)} container direction="row" alignItems="center">
                                                    <Icon color="primary" className={classNames(classes.icon, 'left')}>assignment_ind</Icon> {seller.roles.filter(r => r.level > 0 && !r.master).map(r => r.name).join(', ')}
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={24} alignItems="center" justify="center">
                                                <Grid item xs={12} md={10} container alignItems="center" justify="center">
                                                    <Button variant="contained" color="primary" className={classes.button} fullWidth onClick={this.handledOpenContactForm({ seller })}>
                                                        {dictionary['Contact']}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        {pagination.total > 0 && pagination.pages > 1 && (
                            <Pagination
                                className="border-top-0"
                                onRef={p => this.pagination = p}
                                {...pagination}
                                onChange={this.getSellers}
                                location={this.props.location}
                                pathname={this.props.location.pathname}
                            />
                        )}
                    </div>
                    <Modal
                        open={contactFormOpened}
                        onClose={this.handledCloseContactForm}
                        theme={theme}
                        classes={{
                            root: classes.contactFormModel,
                            content: classes.contactFormModelContent
                        }}
                        noAction
                        // title="Thêm quyền truy cập"
                        // actionButtons={[[
                        //     !captchaOpened && (
                        //         <Button key="btn-send-contact" variant="contained" color="primary" className={classNames(classes.button, 'secondary')} disabled={Object.keys(errors).filter(err => !!errors[err]).length > 0} onClick={this.handleOpenCaptcha}>
                        //             {dictionary['Send'] || ''}
                        //             <Icon className={classNames(classes.icon, 'right')}>send</Icon>
                        //         </Button>
                        //     )
                        // ]]}
                    >
                        <Grid container spacing={24} justify="center">
                            <Grid item xs={12}>
                                {!!seller && (
                                    <div className={classes.contactSummary}>
                                        {!!seller.photo ? (
                                            <Avatar alt="Remy Sharp" src={`${restEndpoint}${seller.photo.path}`} className={classes.avatar} />
                                        ) : (
                                            <Avatar className={classes.avatar}>{seller.firstName.charAt(0).toUpperCase()}</Avatar>
                                        )}
                                        {/* <div>
                                            <b>{projectDetail.createdBy.firstName} {projectDetail.createdBy.lastName}</b>
                                            <p>Chuyên viên tư vấn</p>
                                            <p className="truncate" title={projectDetail.createdBy.email}>{projectDetail.createdBy.email}</p>
                                        </div> */}
                                        <Grid container spacing={24} className={classes.flexRightColumn} direction="column" alignItems="flex-start" justify="flex-start">
                                            <Grid item xs={12} className={classNames('truncate', classes.infoRow)} container direction="row" alignItems="center">
                                                <Icon color="primary" className={classNames(classes.icon, 'left')}>person_outline</Icon>
                                                <span className={classNames(classes.sellerSummaryRow, 'truncate')}>{`${seller.firstName} ${seller.lastName}`}</span>
                                            </Grid>
                                            <Grid item xs={12} className={classNames('truncate', classes.infoRow)} container direction="row" alignItems="center">
                                                <Icon color="primary" className={classNames(classes.icon, 'left')}>assignment_ind</Icon>
                                                <span className={classNames(classes.sellerSummaryRow, 'truncate')}>{seller.roles.map(r => r.name).join(', ')}</span>
                                            </Grid>
                                            {seller.phone && (
                                                <Button variant="outlined" fullWidth className={classNames(classes.button, 'secondary', 'red', classes.callButton)}>
                                                    {/* <Icon>phone</Icon> */}
                                                    Call me: <a href={`tel:${seller.phone}`} className={classes.phoneNumber}>{seller.phone}</a>
                                                </Button>
                                            )}
                                        </Grid>
                                        {/* <Button variant="outlined" className={classNames(classes.button, classes.saveContactButton)}>
                                            <Icon className="fas fa-qrcode" />
                                            Lưu danh bạ
                                        </Button> */}
                                    </div>
                                )}
                                <div className={classes.contactForm}>
                                    <form ref={r => this.inboxForm = r} className={classes.contactFormContainer}>
                                    {/* onChange={this.handleCloseCaptcha} */}
                                        <TextField
                                            id="fullname"
                                            label={dictionary['Fullname']}
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            required
                                            inputRef={r => this.fullname = r}
                                            error={!!errors.fullname}
                                            onBlur={this.inboxValidate}
                                        />
                                        <span className={classes.fieldError}>{errors.fullname || null}</span>
                                        <TextField
                                            id="phone"
                                            label={dictionary['Phone number']}
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            required
                                            inputRef={r => this.phone = r}
                                            error={!!errors.phone}
                                            onBlur={this.inboxValidate}
                                        />
                                        <span className={classes.fieldError}>{errors.phone || null}</span>
                                        <TextField
                                            id="email"
                                            label={dictionary['Email']}
                                            type="email"
                                            className={classes.textField}
                                            margin="normal"
                                            fullWidth
                                            required
                                            inputRef={r => this.email = r}
                                            error={!!errors.email}
                                            onBlur={this.inboxValidate}
                                        />
                                        <span className={classes.fieldError}>{errors.email || null}</span>
                                        <TextField
                                            id="message"
                                            label={dictionary['Content']}
                                            className={classes.textField}
                                            margin="normal"
                                            multiline
                                            rowsMax="10"
                                            fullWidth
                                            required
                                            inputRef={r => this.message = r}
                                            error={!!errors.message}
                                            onBlur={this.inboxValidate}
                                        />
                                        <span className={classes.fieldError}>{errors.message || null}</span>
                                        {!captchaOpened && (
                                            <Button variant="contained" color="primary" fullWidth className={classNames(classes.button, 'secondary')} style={{marginTop: '8px'}} disabled={Object.keys(errors).filter(err => !!errors[err]).length > 0} onClick={this.handleOpenCaptcha}>
                                                {dictionary['Send'] || ''}
                                                <Icon className={classNames(classes.icon, 'right')}>send</Icon>
                                            </Button>
                                        )}
                                        {captchaOpened && (
                                            <div className="captcha">
                                                <Recaptcha
                                                    sitekey="6LfkeokUAAAAAOz06NY4j_51a69aWb8wuYPo2sXM"
                                                    hl={lang}
                                                    render="explicit"
                                                    onloadCallback={this.inboxRecaptchaLoadCallback}
                                                    verifyCallback={this.inboxRecaptchaVerifyCallback}
                                                    // expiredCallback={() => {}}
                                                    badge="inline"
                                                    // className={classes.reCaptcha}
                                                />
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </Grid>
                        </Grid>
                    </Modal>
                </div>
            // </MuiThemeProvider>
        );
    }

    handleChangeField = field => e => this.setState({ [field]: e.target.value })

    getListingGroups = () => {
        return request('/listing-groups', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    listingGroups: res.body.result
                });
            } else this.props.action.notify({
                variant: 'error',
                message: 'Vui lòng kiểm tra kết nối mạng.'
            });
        }).catch(err => {
            this.props.action.notify({
                variant: 'error',
                message: err.message
            });
        });
    }

    getPropertyTypes = () => {
        return request('/property-types', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    propertyTypes: res.body.result
                });
            } else this.props.action.notify({
                variant: 'error',
                message: 'Vui lòng kiểm tra kết nối mạng.'
            });
        }).catch(err => {
            this.props.action.notify({
                variant: 'error',
                message: err.message
            });
        });
    }

    getAmenities = () => {
        return request('/amenities', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    let amenities = {};
                    res.body.result.forEach(a => {
                        amenities[a._id] = false;
                    });
                    this.setState({
                        defaultAmenities: res.body.result,
                        amenities
                    });
                }
            } else this.props.action.notify({
                variant: 'error',
                message: 'Vui lòng kiểm tra kết nối mạng.'
            });
        }).catch(err => {
            this.props.action.notify({
                variant: 'error',
                message: err.message
            });
        });
    }

    getProvinces = () => {
        return request('/provinces', {
            headers: {
                'language': localStorage.getItem('lang')
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        provinces: res.body.result
                    });
                }
            } else this.props.action.notify({
                variant: 'error',
                message: 'Vui lòng kiểm tra kết nối mạng.'
            });
        }).catch(err => {
            this.props.action.notify({
                variant: 'error',
                message: err.message
            });
        });
    }

    getSellers = queries => {
        const { listing, ...otherQueries } = queries;
        const { page, limit } = this.state.pagination;
        const q = {
            page,
            limit,
            ...otherQueries
        };
        return request('/sellers', {
            headers: {
                'language': localStorage.getItem('lang'),
            },
            params: q
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        sellers: res.body.result,
                        pagination: res.body.meta || {}
                    });
                }
            } else this.props.action.notify({
                variant: 'error',
                message: 'Vui lòng kiểm tra kết nối mạng.'
            });
        }).catch(err => {
            this.props.action.notify({
                variant: 'error',
                message: err.message
            });
        });
    }

    inboxRecaptchaLoadCallback = () => {}

    inboxRecaptchaVerifyCallback = res => this.sendInbox()

    handleOpenCaptcha = () => this.setState({
        captchaOpened: this.inboxValidate()
    })

    handleCloseCaptcha = () => this.setState({
        captchaOpened: false
    })

    inboxValidate = () => {
        let errors = this.state.errors;
        this.inboxForm.checkValidity();
        if(this.fullname.validity.valid) delete errors.fullname;
        errors.fullname = this.fullname.validationMessage;
        if(this.phone.validity.valid) delete errors.phone;
        errors.phone = this.phone.validationMessage;
        if(this.email.validity.valid) delete errors.email;
        errors.email = this.email.validationMessage;
        if(this.message.validity.valid) delete errors.message;
        errors.message = this.message.validationMessage;
        this.setState({
            errors
        });
        return Object.keys(errors).filter(err => !!errors[err]).length === 0;
    }

    clearInboxForm = () => {
        this.fullname.value = '';
        this.phone.value = '';
        this.email.value = '';
        this.message.value = '';
    }

    sendInbox = () => {
        const { seller } = this.state;
        request('/contact-inbox', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'language': localStorage.getItem('lang'),
            },
            body: JSON.stringify({
                fullname: this.fullname.value,
                phone: this.phone.value,
                email: this.email.value,
                message: this.message.value,
                // refProduct: projectDetail._id,
                to: seller._id,
                agency: seller.agency._id || seller.agency
            })
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400) this.props.action.notify({
                    variant: 'error',
                    message: res.body.message
                });
                else {
                    this.props.action.notify({
                        variant: 'success',
                        message: 'Yêu cầu đã được gửi đi' //res.body.message
                    });
                }
            }
            else this.props.action.notify({
                variant: 'error',
                message: 'Vui lòng kiểm tra kết nối mạng.'
            });
            this.clearInboxForm();
            this.handleCloseCaptcha();
            this.handledCloseContactForm();
        })
        .catch(err => {
            this.props.action.notify({
                variant: 'error',
                message: err.message
            });
        });
    }
    handledOpenContactForm = ({ seller }) => () => this.setState({
        seller,
        contactFormOpened: true
    })

    handledCloseContactForm = () => this.setState({
        seller: null,
        contactFormOpened: false
    })

}

RealEstateSearch.propTypes = {

};

export default withStyles(styles)(RealEstateSearch);