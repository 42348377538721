import React, { Component } from 'react';
import PropTypes from 'prop-types';

const HCMC_POSITION = {
    lat: 10.779152, 
    lng: 106.694620
}

const clusterStyles = [
    {
        url: require('assets/img/clusters/m1.png'),
        height: 50,
        width: 50,
        anchor: [0, 0],
        textColor: 'rgba(0, 0, 0, 0.87)',
        textSize: 10
    }, {
        url: require('assets/img/clusters/m2.png'),
        height: 65,
        width: 65,
        anchor: [0, 0],
        textColor: 'rgba(0, 0, 0, 0.87)',
        textSize: 11
    }, {
        url: require('assets/img/clusters/m3.png'),
        height: 75,
        width: 75,
        anchor: [0, 0],
        textColor: 'rgba(0, 0, 0, 0.87)',
        textSize: 12
    }
]

class Map extends Component {
    constructor(props) {
        super(props);
        // this.state = {
        //     markers: {},
        //     infoWindows: {},
        //     cluster: null
        // };
        this.markers = {};
        this.infoWindows = {};
        this.cluster = null;
    }

    UNSAFE_componentWillMount() {
        
    }

    componentDidMount() {
        let _self = this;
        const { onRef, onMount, options } = _self.props;
        if(onRef) onRef(this);
        _self.sync(() => {
            try {
                let opts = {
                    center: HCMC_POSITION,
                    zoom: 3, //15,
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    // disableDefaultUI: true,
                    panControl: true,
                    zoomControl: true,
                    scaleControl: true,
                    mapTypeControl: false,
                    streetViewControl: false,
                    overviewMapControl: true,
                    rotateControl: true,
                    ...options
                };
                _self.map = new window.google.maps.Map(_self.refs.map, opts);
                // if(_self.props.onLoad) _self.props.onLoad();
                if(onMount) onMount();
            } catch (err) {
                
            }
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        const mapStyle = {
            width: '100%',
            height: '100%'
        };
        return (
            <div ref="map" style={this.props.style ? Object.assign({}, mapStyle, this.props.style) : mapStyle}></div>
        );
    }

    static sync = cb => {
        return new Promise(resolve => {
            var mapInit = setInterval(() => {
                if(window.google) {
                    clearInterval(mapInit);
                    if(cb) resolve(cb());
                    resolve();
                }
            }, 1000);
        });
    }

    sync = cb => {
        return new Promise(resolve => {
            var mapInit = setInterval(() => {
                if(window.google) {
                    clearInterval(mapInit);
                    if(cb) resolve(cb());
                    resolve();
                }
            }, 1000);
        });
    }

    getLatLng = (lat, lng) => new window.google.maps.LatLng(lat, lng)

    panTo = pos => {
        if(this.map) this.map.panTo(pos);
    }

    setCenter = pos => {
        if(this.map) this.map.setCenter(pos);
    }

    setZoom = level => {
        var _self = this;
        if(_self.map) {
            var listener = window.google.maps.event.addListener(_self.map, "idle", function() {
                _self.map.setZoom(level);
                window.google.maps.event.removeListener(listener); 
            });
        }
    }

    getBounds = () => {
        let bounds = new window.google.maps.LatLngBounds();
        Object.keys(this.markers).forEach(m => {
            bounds.extend(this.markers[m].getPosition());
        });
        this.map.fitBounds(bounds);
    }

    clustering = (locations) => {
        const propertyMarkerImage = require('assets/img/markers/property.png');
        var markers = locations.map(l => {
            return new google.maps.Marker({
                position: this.getLatLng(l.lat, l.lng),
                icon: {
                    url: propertyMarkerImage,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(39 / 2, 47),
                    scaledSize: new window.google.maps.Size(39, 47)
                }
            });
        });
        var cluster = new window.MarkerClusterer(this.map, markers, {
            // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            styles: clusterStyles,
            // gridSize: 100,
            // maxZoom: 4,
            // maxZoom: 10
            // zoomOnClick: true
        });
        // cluster.getExtendedBounds();
        // this.setState({ cluster });
        this.cluster = cluster;
    }

    clusteringWithMarkers = (locations) => {
        const propertyMarkerImage = require('assets/img/markers/property.png');
        var markers = locations.map(l => {
            var markerId = (l._id ? l._id : Date.now()) + '';
            var marker = new google.maps.Marker({
                _id: markerId,
                position: this.getLatLng(l.lat, l.lng),
                icon: {
                    url: propertyMarkerImage,
                    origin: new window.google.maps.Point(0, 0),
                    anchor: new window.google.maps.Point(39 / 2, 47),
                    scaledSize: new window.google.maps.Size(39, 47),
                    ...l.icon
                }
            });
            this.markers = {
                ...this.markers,
                [markerId]: marker
            };
            if(l.onClick) window.google.maps.event.addListener(this.markers[markerId], 'click', l.onClick);
            return this.markers[markerId];
        });
        var cluster = new window.MarkerClusterer(this.map, markers, {
            // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
            styles: clusterStyles,
            // gridSize: 50,
            // maxZoom: 10,
            // zoomOnClick: true
        });
        // this.setState({ cluster });
        this.cluster = cluster;
    }

    addMarker = ({ location, options, onClick }, cb = null) => {
        return new Promise(resolve => {
            var _self = this;
            this.sync(() => {
                var markerId = options && options.id ? options.id : `marker-${Date.now()}`;
                var markerOptions = {
                    position: this.getLatLng(location.lat, location.lng),
                    map: _self.map,
                    id: markerId,
                    draggable: options && (options.draggable || false),
                };
                if(options && options.icon) {
                    const scaledSize = options.icon.scaledSize;
                    markerOptions['icon'] = {
                        url: l.icon || options.icon.url,
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(scaledSize.width / 2, scaledSize.height),
                        scaledSize: new window.google.maps.Size(scaledSize.width, scaledSize.height)
                    };
                }
                if(options && options.type) {
                    switch (options.type) {
                        case 'property':
                            markerOptions['icon'] = {
                                //path: window.google.maps.SymbolPath.CIRCLE,
                                url: options.icon || require('assets/img/markers/property.png'),
                                //scale: 3
                                // size: new window.google.maps.Size(100, 100),
                                origin: new window.google.maps.Point(0, 0),
                                //size: new window.google.maps.Size(45, 45),
                                //origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(39 / 2, 47),
                                scaledSize: options.iconSize || new window.google.maps.Size(39, 47)
                                // labelOrigin: new window.google.maps.Point(80, 45)
                            }
                            break;
                    }
                }
                var marker = new window.google.maps.Marker(markerOptions);
                _self.markers = {
                    ..._self.markers,
                    [markerId]: marker
                };
                if(onClick) window.google.maps.event.addListener(_self.state.markers[markerId], 'click', onClick);
                // if(cb) resolve(cb(_self.state.markers[markerId]));
                // else resolve(_self.state.markers[markerId]);
                if(cb) resolve(cb(markerId));
                else resolve(markerId);
            });
        });
    }

    moveMarker = (markerId, pos) => {
        let m = this.markers[markerId];
        if(m) {
            this.sync(() => {
                m.setPosition(pos);
                this.panTo(pos);
            });
        }
    }

    removeMarker = markerId => {
        let m = this.markers[markerId];
        m.setMap(null); // set markers setMap to null to remove it from map
        // delete this.markers[markerId]; // delete marker instance from markers object
        const { [markerId]: removed, ...markers} = this.markers;
        this.markers = markers;
    }

    clearMarkers = (skippedMarkerId) => {
        var _self = this;
        Object.keys(_self.markers).forEach(function(m) {
            if(m !== skippedMarkerId) _self.removeMarker(m);
        });
    }

    addInfoWindow = ({ content, options }, cb = null) => {
        let _self = this;
        return new Promise(resolve => {
            const { id, ...otherOptions } = options;
            _self.sync(() => {
                var windowId = options && options.id ? options.id : `info-window-${Date.now()}`;
                let w = new window.google.maps.InfoWindow({
                    content,
                    ...otherOptions
                });
                _self.infoWindows = {
                    ..._self.infoWindows,
                    [windowId]: w
                };
                if(cb) resolve(cb(_self.infoWindows[windowId])) ;
                else resolve(_self.infoWindows[windowId]);
                // return _self.infoWindows[windowId];
            });
        });
    }
    
    removeInfoWindow = windowId => {
        let w = this.infoWindows[windowId];
        w.close();
        const { [windowId]: removed, ...infoWindows} = this.infoWindows;
        this.infoWindows = infoWindows;
    }

    clearInfoWindows = (skippedwindowId) => {
        var _self = this;
        Object.keys(_self.infoWindows).forEach(function(m) {
            if(m !== skippedwindowId) _self.removeInfoWindow(m);
        });
    }

    clearClusters = () => {
        if(this.cluster) this.cluster.clearMarkers();
    }

}

Map.propTypes = {
    
};

export default Map;