import React, { Component } from 'react';
import { browserHistory, withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import { container } from 'assets/jss/theme.global';
import { Divider } from '@material-ui/core';
import Pagination from 'common/pagination';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import Hero from './hero';
import SearchOverlay from './search-overlay';
// import s from '../style.scss';
import { request } from 'lib/http-services';
const { toHyphens } = require('lib/helper');
import { multilingual } from '@hoc/multilingual';
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
;
const noPhotoImage = require('assets/img/no-photo.jpg');

const styles = theme => ({
    root: {
        // ...container,
        // display: 'flex',
        // flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        // margin: '24px auto',
        // padding: '24px',
        // 'max-width': '98% !important',
        margin: '0 auto',
        // padding: '24px',
        padding: '4px',
        // minHeight: 'calc(100vh - 51px - (83px + 0.625rem) - 300px - 64px - 24px * 2)',
        'flex-direction': 'column'
    },
    container: {
        ...container,
        // maxWidth: '100% !important',
        alignItems: 'center',
        // margin: '24px auto',
        // padding: '24px',
        'padding-left': 'inherit',
        'padding-right': 'inherit'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        // width: '100%',
        // margin: '-2px',
        // 'margin-left': 'calc(-100vw - 2px)',
        overflow: 'hidden',
        width: '100%',
        // height: 'calc(60vw / 2)',
        transition: 'margin-left 500ms',
        // marginBottom: '4px !important'
        margin: '0 !important'
    },
    gridListTile: {
        // width: '50% !important',
        // width: '50vw !important',
        // width: 'calc((100vw - 4px - 15px) / 2) !important',
        width: '100%',
        // height: '300px !important',
        height: 'inherit !important',
        padding: '0 !important',
        'margin-bottom': '4px',
        // margin: '4px 0',
        '&:first-child': {
            'margin-right': '4px'
        },
        '&.fullwidth': {
            width: 'calc(100% - 4px) !important'
        },
        [theme.breakpoints.up(600)]: {
            width: 'calc(100% / 2) !important',
        },
        '&>div': {
            height: 'auto'
        }
    },
    title: {
        color: '#fff', //theme.palette.primary.light,
        textAlign: 'left',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    titleBar: {
        padding: '2%',
        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    titleLeft: {
        color: '#BAB9B9',
        textAlign: 'left',
        fontSize: '1rem',
        marginBottom: '10px'
    },
    titleRight: {
        color: '#FFED38',
        padding: '.438rem',
        transform: 'translateY(25%)',
        fontSize: '1rem',
        fontWeight: 'bold',
        textAlign: 'right',
        width: 'max-content'
    },
    // titleBarImage: {
    //     filter: 'grayscale(0.2) brightness(0.7)',
    //     width: '100%',
    //     '&:hover': {
    //         filter: 'inherit',
    //         cursor: 'pointer'
    //     }
    // },
    titleBarImage: {
        width: '100%',
        height: 0,
        paddingTop: '60%',
        'background-blend-mode': 'inherit',
        'background-color': 'inherit',
        '&:hover': {
            'background-blend-mode': 'overlay',
            'background-color': 'rgba(0, 0, 0, .2)',
            cursor: 'pointer'
        }
    },
    tileHero: {
        // textAlign: 'center',
        position: 'absolute',
        right: '1rem',
        bottom: '1rem',
        color: 'white',
        textAlign: 'left',
        padding: '1rem',
        marginLeft: '1rem',
        marginWop: '1rem',
        maxWidth: '40%',
    },
    row: {
        width: '100%'
    },
    util: {
        float: 'left',
        border: '1px solid rgba(0, 0, 0, 0.54)',
        padding: '0 1rem .5rem 1rem',
        'text-align': 'center',
        'font-weight': 'bold',
        '&:not(:last-child)': {
            marginRight: '-1px'
        }
    },
    utilDivider: {
        backgroundColor: 'rgba(0, 0, 0, 0.54)'
    },
    responsiveTile: {
        'margin-bottom': '24px'
    },
    card: {
        'border-radius': 0,
        // '&:hover': {
        //     'box-shadow': '0 8px 16px 0 rgba(0,0,0,0.2)'
        // }
    },
    feature: {
        // 'margin-bottom': '54px',
        position: 'relative',
        '&>$card': {
            cursor: 'pointer'
        },
        '&:not(:last-child)': {
            'margin-bottom': '54px',
        }
    },
    featureLabel: {
        position: 'absolute',
        'text-transform': 'uppercase',
        'background-color': '#FFED38',
        color: '#4B4B4B',
        padding: '0.5rem 0',
        top: 0,
        right: '20px',
        transform: 'translateY(-1.5rem)',
        width: '55px',
        height: '56px',
        'line-height': '1.5rem',
        'font-size': '.9rem',
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        '&.sale': {
            'background-color': '#FFED38',
        },
        '&.lease': {
            'background-color': '#F38D00',
        }
    },
    content: {
        textAlign: 'left',
        // '&:not(first-child)': {
        //     borderTop: '1px solid #ddd'
        // }
        borderTop: '1px solid #ddd'
    },
    actionIconButton: {
        padding: '8px'
    },
    tileDescription: {
        display: '-webkit-box',
        'max-width': '100%',
        'max-height': 'calc(1.4em * 3)',
        margin: '0 auto',
        'line-height': '1.4em',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'normal',
        '& p': {
            margin: 0
        }
    },
    media: {
        height: 0,
        paddingTop: '75%'//'56.25%'
    },
    actions: {
        // display: 'block',
        // overflow: 'hidden',
        // padding: '1rem 20px',
        borderTop: '1px solid #ddd',
        display: 'flex',
        '& .truncate': {
            display: '-webkit-box',
            overflow: 'hidden',
            'line-height': '1.4',
            'white-space': 'normal',
            'text-overflow': 'ellipsis',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            'text-align': 'left'
        }
    },
    address: {
        width: '100%'
    },
    price: {
        // float: 'right',
        textAlign: 'right',
        marginLeft: 'auto',
        color: '#FF8F35',
        fontWeight: 'bold',
        // width: '80px',
        width: '240px'
    },
    leftPriceColumn: {
        width: 'calc(100% - 200px)',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'flex-end',
        'justify-content': 'flex-start'
    },
    rightPriceColumn: {
        position: 'relative',
        overflow: 'hidden',
        width: '200px',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'flex-end',
        'justify-content': 'flex-end'
    },
});

@multilingual
class OutstandingProject extends Component {
    state = {
        pagination: {
            page: 1,
            limit: 10
        },
        projects: [],
        lang: localStorage.getItem('lang')
    }

    componentDidMount() {
        // this.fetchData({ ...this.state.pagination, ...this.props.location.query });
    }

    shouldComponentUpdate() {
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // this.fetchData();
    }

    componentDidUpdate(prevProps) {
        // if(this.props.location.query !== prevProps.location.query) {
        //     this.fetchData({ ...this.state.pagination, ...this.props.location.query });
        // }
    }

    render() {
        const { classes, dictionary, language } = this.props;
        const { projects, pagination } = this.state;
        return (
            <React.Fragment>
                <div className={classes.searchOverlayContainer}>
                    <SearchOverlay
                        // dictionary={dictionary}
                        // location={this.props.location}
                        // onSearch={this.onSearch}
                        // onFilter={this.handleChangeQueries}
                        onChange={this.onSearchChange}
                    />
                </div>
                <div className={classes.root}>
                    {/* <GridList className={classes.gridList} cols={1}> */}
                    <div className={classNames(classes.gridList, classes.container)}>
                        {/* {projects.map(tile => (
                            <React.Fragment key={tile._id}>
                                <Hidden smDown implementation="css">
                                    <div className={classNames(classes.gridListTile, 'cover', 'feature', 'hoverable')} style={{backgroundImage: `url(${tile.photos && tile.photos.length > 0 ? `${restEndpoint}${tile.photos[0].path}` : noPhotoImage})`}} onClick={() => this.showDetail(tile)}>
                                        <div className={classNames(classes.tileHero, 'hero-text')}>
                                            <Typography variant="h5" component="h2" className={classes.summarySpacing}>
                                                {tile.title}
                                            </Typography>
                                            <Typography component="div" className={classes.summarySpacing}>
                                                {tile.location && (Object.keys(tile.location).length > 1 || tile.location.address !== '') && (
                                                    <div className={classNames(classes.row, 'truncate')}>
                                                        <IconButton>
                                                            <Icon>location_on</Icon>
                                                        </IconButton>
                                                        {this.getFormattedAddress(tile.location)}
                                                    </div>
                                                )}
                                                <Grid container>
                                                    <div className={classes.leftPriceColumn}>
                                                        {tile.blocks && (
                                                            <div className={classNames(classes.util, 'xxx')}>
                                                                <IconButton>
                                                                    <Icon>{icons['blocks']}</Icon>
                                                                </IconButton>
                                                                <Typography>
                                                                    Block
                                                                </Typography>
                                                                <Divider className={classes.utilDivider} />
                                                                <Typography>
                                                                    {tile.blocks.length}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        {tile.numOfFloors && (
                                                            <div className={classNames(classes.util, 'xxx')}>
                                                                <IconButton>
                                                                    <Icon>{icons['properties']}</Icon>
                                                                </IconButton>
                                                                <Typography>
                                                                    Tầng
                                                                </Typography>
                                                                <Divider className={classes.utilDivider} />
                                                                <Typography>
                                                                    {tile.numOfFloors}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        {tile.numOfProperties && (
                                                            <div className={classNames(classes.util, 'xxx')}>
                                                                <IconButton>
                                                                    <Icon>{icons['properties']}</Icon>
                                                                </IconButton>
                                                                <Typography>
                                                                    Căn
                                                                </Typography>
                                                                <Divider className={classes.utilDivider} />
                                                                <Typography>
                                                                    {tile.numOfProperties}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={classes.rightPriceColumn}>
                                                        <span className={classes.price}>{tile.price ? `Từ ${tile.price.min.value.toLocaleString(lang || 'en')} ${tile.price.min.currency.name}` : dictionary['Contact']}</span>
                                                    </div>
                                                </Grid>
                                            </Typography>
                                        </div>
                                    </div>
                                </Hidden>
                                <Hidden mdUp implementation="css">
                                    <Card className={classNames(classes.card, 'card', 'hoverable', classes.responsiveTile)} onClick={() => this.showDetail(tile)}>
                                        <CardMedia
                                            className={classes.media}
                                            image={tile.photos && tile.photos.length > 0 ? `${restEndpoint}${tile.photos[0].path}` : noPhotoImage}
                                            title={tile.title}
                                        />
                                        {tile.blocks && tile.numOfFloors && tile.numOfProperties ? (
                                            <CardActions className={classes.actions}>
                                                {tile.blocks && (
                                                    <React.Fragment>
                                                        <IconButton className={classes.actionIconButton}>
                                                            <Icon>{icons['blocks']}</Icon>
                                                        </IconButton>
                                                        <Typography>
                                                            {tile.blocks.length}
                                                        </Typography>
                                                    </React.Fragment>
                                                )}
                                                {tile.numOfFloors && (
                                                    <React.Fragment>
                                                        <IconButton className={classes.actionIconButton}>
                                                            <Icon>{icons['properties']}</Icon>
                                                        </IconButton>
                                                        <Typography>
                                                            {tile.numOfFloors}
                                                        </Typography>
                                                    </React.Fragment>
                                                )}
                                                {tile.numOfProperties && (
                                                    <React.Fragment>
                                                        <IconButton className={classes.actionIconButton}>
                                                            <Icon>{icons['properties']}</Icon>
                                                        </IconButton>
                                                        <Typography>
                                                            {tile.numOfProperties}
                                                        </Typography>
                                                    </React.Fragment>
                                                )}
                                            </CardActions>
                                        ) : null}
                                        <CardContent className={classes.content}>
                                            <Typography gutterBottom variant="h5" component="h2" className="truncate" align="left">
                                                {tile.title}
                                            </Typography>
                                            <Typography component="div" className={classes.tileDescription} align="left">{tile.shortDescription || ''}</Typography>
                                        </CardContent>
                                        <CardActions className={classes.actions}>
                                            <IconButton className={classes.actionIconButton}>
                                                <Icon>location_on</Icon>
                                            </IconButton>
                                            <Typography className={classes.address}>
                                                <span className="truncate">{tile.location ? this.getFormattedAddress(tile.location) : ''}</span>
                                            </Typography>
                                            <Typography className={classes.price}>
                                                {tile.price && tile.price.min ? `${tile.price.min.value.toLocaleString('en')} ${tile.price.min.currency.name}${tile.group && tile.group._id === 'rent' ? ` / ${tile.price.min.unit.title}` : ''}` : dictionary['Contact']}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                </Hidden>
                            </React.Fragment>
                        ))} */}
                    </div>
                    {Array.from(Array(Math.round(projects.length / 2)).keys()).map(i => (
                        <React.Fragment key={`row-${i}`}>
                            <Hidden smDown implementation="css">
                                <GridList className={classes.gridList} cols={2}>
                                    {Array.from(Array(2).keys()).map(j => {
                                        const tile = projects[i * 2 + j]

                                        if (!tile) return null;

                                        return (
                                            <GridListTile key={tile._id} className={classNames(classes.gridListTile, 'feature', 'hoverable', projects.length <= 1 && 'fullwidth')} onClick={() => this.showDetail(tile)}>
                                                <div style={{backgroundImage: `url(${tile.photos && tile.photos.length > 0 ? `${restEndpoint}${tile.photos[0].path}` : noPhotoImage})`}} className={classNames(classes.titleBarImage, 'cover')}></div>
                                                <GridListTileBar
                                                    title={tile.title}
                                                    subtitle={tile.location ? this.getFormattedAddress(tile.location) : ''}
                                                    classes={{
                                                        root: classes.titleBar,
                                                        title: classes.title,
                                                        subtitle: classes.titleLeft,
                                                    }}
                                                    actionIcon={
                                                        <div className={classes.titleRight}>
                                                            {!!tile.price && tile.price.min.value && <span>{tile.price.min.value.toLocaleString(language)} {tile.price.min.currency.name}{tile.price.min.unit ? ` / ${tile.price.min.unit.title}` : ''}</span>}
                                                            {/* <IconButton>
                                                                <StarBorderIcon />
                                                            </IconButton> */}
                                                        </div>
                                                    }
                                                />
                                            </GridListTile>
                                        );
                                    })}
                                </GridList>
                            </Hidden>
                        </React.Fragment>
                    ))}
                    {Array.from(Array(projects.length).keys()).map(i => (
                        <Hidden mdUp implementation="css">
                            <GridList className={classes.gridList} cols={1}>
                                {(() => {
                                    const tile = projects[i]

                                    if (!tile) return null;

                                    return (
                                        <GridListTile key={tile._id} className={classNames(classes.gridListTile, 'feature', 'hoverable', projects.length <= 1 && 'fullwidth')} onClick={() => this.showDetail(tile)}>
                                            <div style={{backgroundImage: `url(${tile.photos && tile.photos.length > 0 ? `${restEndpoint}${tile.photos[0].path}` : noPhotoImage})`}} className={classNames(classes.titleBarImage, 'cover')}></div>
                                            <GridListTileBar
                                                title={tile.title}
                                                subtitle={tile.location ? this.getFormattedAddress(tile.location) : ''}
                                                classes={{
                                                    root: classes.titleBar,
                                                    title: classes.title,
                                                    subtitle: classes.titleLeft,
                                                }}
                                                actionIcon={
                                                    <div className={classes.titleRight}>
                                                        {!!tile.price && tile.price.min.value && <span>{tile.price.min.value.toLocaleString(language)} {tile.price.min.currency.name}{tile.price.min.unit ? ` / ${tile.price.min.unit.title}` : ''}</span>}
                                                        {/* <IconButton>
                                                            <StarBorderIcon />
                                                        </IconButton> */}
                                                    </div>
                                                }
                                            />
                                        </GridListTile>
                                    );
                                })()}
                            </GridList>
                        </Hidden>
                    ))}
                    {pagination.total > 0 && pagination.pages > 1 && (
                        <Pagination
                            className="border-top-0"
                            onRef={p => this.pagination = p}
                            // onChange={this.fetchData}
                            {...pagination}
                            // location={this.props.location}
                            // pathname={this.props.location.pathname}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }

    onSearchChange = data => this.setState({
        projects: data.result,
        pagination: data.meta
    })

    init = (query) => {
        // const { group, type, province, numOfBathrooms, numOfBedrooms, area, price } = query;
        const { type, keyword, province, ...otherQueries } = query;
        var _self = this;
        let filter = {
            ...otherQueries
        };
        let filteredProjectTypes = {};
        Object.keys(_self.state.filteredProjectTypes).forEach(t => filteredProjectTypes[t] = false);
        if(type) {
            type.split(',').forEach(t => filteredProjectTypes[t] = true);
            filter['type'] = type;
        }
        if(province && province !== '-1') filter['location.province'] = province;
        _self.setState({
            filteredProjectTypes,
            filteredTypes: filteredProjectTypes,
            province,
            filter
        }, () => _self.fetchData({
            ...filter
        }));
    }

    fetchData = (query) => {
        console.log(query)
        const { pages, total, ...otherQueries } = query;
        // const { page, limit } = this.state.pagination;
        const queries = Object.keys(otherQueries).filter(k => !!otherQueries[k] && otherQueries[k] !== -1);
        let q = {};
        queries.forEach(k => {
            q[k] = otherQueries[k]
        });
        // const q = {
        //     page,
        //     limit,
        //     ...otherQueries
        // };
        request('/projects', {
            params: {
                ...q
            },
            headers: {
                // 'language': localStorage.getItem('lang'),
                'language': this.props.language
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    console.log(res.body)
                    this.setState({
                        projects: res.body.result,
                        pagination: res.body.meta || {}
                    });
                }
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    onSearch = (projects) => this.setState({
        projects
    })

    handleChangeQueries = queries => {
        let finalQueries = {...this.props.location.query}
        Object.keys(queries).forEach(q => {
            if(queries[q] === -1 || queries[q] === '') {
                const {[q]: removed, ...otherQueries} = finalQueries
                finalQueries = {...otherQueries}
            }
        });
        let filteredQueries = Object.keys(queries).filter(q => queries[q] !== -1 && queries[q] !== '').reduce((obj, item) => {
            obj[item] = queries[item]
            return obj
          }, {})
        finalQueries = {
            // ...this.props.location.query,
            ...finalQueries,
            ...filteredQueries
        }
        browserHistory.push(`/projects${Object.keys(finalQueries).length > 0 ? `?${Object.keys(finalQueries).map(q => `${q}=${encodeURIComponent(finalQueries[q])}`).join('&')}` : ''}`)
    }
    
    getFormattedAddress = location => {
        let strs = []
        if(location.address) strs.push(location.address)
        if(location.district) strs.push(location.district.name)
        if(location.province) strs.push(location.province.name)
        return strs.join(', ')
    }

    showDetail = ({ _id, title }) => {
        browserHistory.push(`/project/${toHyphens(title)}-${_id}`);
    }
}

export default withRouter(withStyles(styles)(OutstandingProject));