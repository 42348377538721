import React, { Component } from "react";
import { browserHistory } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
// import Divider from '@material-ui/core/Divider';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
// import Hidden from '@material-ui/core/Hidden';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
import { container } from "assets/jss/theme.global";
import Icon from "@material-ui/core/Icon";
import { multilingual } from "@hoc/multilingual";
import { request } from "lib/http-services";
const { toHyphens } = require("lib/helper");
const config = require("config");
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
const noPhotoImage = require("assets/img/no-photo.jpg");

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    // padding: '24px'
  },
  container: {
    ...container,
    // maxWidth: '100% !important',
    alignItems: "center",
    // margin: '24px auto',
    margin: 0,
    // padding: '0 15px',
    // maxWidth: '100% !important',
    flex: "1",
    justifyContent: "space-between",
    display: "flex",
  },
  summary: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  },
  summarySpacing: {
    lineHeight: "3rem",
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    // width: '100%',
    // margin: '-2px',
    // 'margin-left': 'calc(-100vw - 2px)',
    overflow: "hidden",
    width: "999%",
    // height: 'calc(60vw / 2)',
    transition: "margin-left 500ms",
    // marginBottom: '4px !important'
    margin: "0 !important",
  },
  gridListTile: {
    // width: '50% !important',
    // width: '50vw !important',
    // width: 'calc((100vw - 4px - 15px) / 2) !important',
    width: "100vw !important",
    // height: '300px !important',
    height: "inherit !important",
    padding: "0 !important",
    "margin-bottom": "4px",
    // margin: '4px 0',
    "&:first-child": {
      "margin-right": "4px",
    },
    "&.fullwidth": {
      width: "calc(100vw - 4px) !important",
    },
    [theme.breakpoints.up(600)]: {
      width: "calc((100vw - 4px) / 2) !important",
    },
    "&>div": {
      height: "auto",
    },
  },
  title: {
    color: "#fff", //theme.palette.primary.light,
    textAlign: "left",
    fontSize: "1.3rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  titleBar: {
    padding: "2%",
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    width: "-webkit-fill-available",
  },
  titleLeft: {
    color: "#BAB9B9",
    textAlign: "left",
    fontSize: "1rem",
    marginBottom: "10px",
  },
  titleRight: {
    color: "#FFED38",
    padding: ".438rem",
    transform: "translateY(25%)",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "right",
    width: "max-content",
  },
  // titleBarImage: {
  //     filter: 'grayscale(0.2) brightness(0.7)',
  //     width: '100%',
  //     '&:hover': {
  //         filter: 'inherit',
  //         cursor: 'pointer'
  //     }
  // },
  titleBarImage: {
    width: "100%",
    height: 0,
    paddingTop: "60%",
    "background-blend-mode": "inherit",
    "background-color": "inherit",
    "&:hover": {
      "background-blend-mode": "overlay",
      "background-color": "rgba(0, 0, 0, .2)",
      cursor: "pointer",
    },
  },
  slider: {
    position: "relative",
    overflow: "hidden",
    width: "100vw",
  },
  nextButton: {
    width: "48px !important",
    height: "48px !important",
    padding: "0 !important",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    "z-index": 1,
    color: "#fff",
    zoom: 2,
  },
  prevButton: {
    width: "48px !important",
    height: "48px !important",
    padding: "0 !important",
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    "z-index": 1,
    color: "#fff",
    zoom: 2,
  },
  loadMoreButton: {
    backgroundColor: "#2a2a2a",
    color: "#fff",
    margin: "2rem auto",
    "border-radius": 0,
  },
});

@multilingual
class OutstandingProject extends Component {
  state = {
    sliderOffset: 0,
    projects: [],
    pagination: {
      total: 0,
    },
    lang: localStorage.getItem("lang"),
  };

  componentDidMount() {
    this.fetchData();
  }

  shouldComponentUpdate() {
    return true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData();
  }

  render() {
    const { classes, id, dictionary, language } = this.props;
    const { sliderOffset, projects, pagination } = this.state;
    if (projects && projects.length > 0)
      return (
        <div id={id || Date.now()} className={classes.root}>
          <div className={classes.summary}>
            <Typography
              variant="h5"
              component="h2"
              className={classes.summarySpacing}
            >
              {dictionary["Featured projects"]}
            </Typography>
            <Typography component="p" className={classes.summarySpacing}>
              {
                dictionary[
                  "Azoro gives you full information on current real estate projects."
                ]
              }
            </Typography>
          </div>
          <div className={classes.slider}>
            {/* {sliderOffset > 0 && (
                        <IconButton className={classes.prevButton} onClick={this.handlePrev}>
                            <Icon>chevron_left</Icon>
                        </IconButton>
                    )} */}
            {/* <Hidden smDown implementation="css"> */}
            {Array.from(Array(Math.round(projects.length / 2)).keys()).map(
              (i) =>
                i < 3 ? (
                  <GridList
                    key={`row-${i}`}
                    className={classes.gridList}
                    cols={2}
                  >
                    {Array.from(Array(2).keys()).map((j) => {
                      const tile = projects[i * 2 + j];

                      if (!tile) return null;

                      return (
                        <GridListTile
                          key={tile._id}
                          className={classNames(
                            classes.gridListTile,
                            "feature",
                            "hoverable",
                            projects.length <= 1 && "fullwidth"
                          )}
                          onClick={() => this.showDetail(tile)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                tile.photos && tile.photos.length > 0
                                  ? `${restEndpoint}${tile.photos[0].path}`
                                  : noPhotoImage
                              })`,
                            }}
                            className={classNames(
                              classes.titleBarImage,
                              "cover"
                            )}
                          ></div>
                          <GridListTileBar
                            title={tile.title}
                            subtitle={
                              tile.location
                                ? this.getFormattedAddress(tile.location)
                                : ""
                            }
                            classes={{
                              root: classes.titleBar,
                              title: classes.title,
                              subtitle: classes.titleLeft,
                            }}
                            actionIcon={
                              <div className={classes.titleRight}>
                                {/* {!!tile.price && tile.price.min.value && tile.price.max.value && (<span>{tile.price.min.value.toLocaleString(language)} - {tile.price.max.value.toLocaleString(language)} {tile.price.max.currency.name}</span>)} */}
                                {!!tile.price && tile.price.min.value && (
                                  <span>
                                    {dictionary["Asking price"]}{" "}
                                    {tile.price.min.value.toLocaleString(
                                      language
                                    )}{" "}
                                    {tile.price.min.currency.name}
                                    {tile.price.min.unit
                                      ? ` / ${tile.price.min.unit.title}`
                                      : ""}
                                  </span>
                                )}
                                {/* <IconButton>
                                                            <StarBorderIcon />
                                                        </IconButton> */}
                              </div>
                            }
                            sf
                          />
                        </GridListTile>
                      );
                    })}
                  </GridList>
                ) : null
            )}
            {/* </Hidden> */}
            {/* {sliderOffset < projects.length - 2 && (
                        <IconButton className={classes.nextButton} onClick={this.handleNext}>
                            <Icon>chevron_right</Icon>
                        </IconButton>
                    )} */}
          </div>
          {pagination.total > 6 && (
            <div className={classes.row}>
              <Button
                variant="contained"
                size="large"
                className={classes.loadMoreButton}
                onClick={this.seeMore}
              >
                {dictionary["See more"]}
              </Button>
            </div>
          )}
        </div>
      );
    else return null;
    // else return <div id={id || Date.now()}></div>;
  }

  fetchData = () => {
    const { language } = this.props;
    request("/projects", {
      params: {
        page: 1,
        limit: 10,
      },
      headers: {
        // 'language': localStorage.getItem('lang'),
        language,
      },
    })
      .then((res) => {
        if (!res.stack) {
          if (res.status >= 400);
          else
            this.setState({
              projects: res.body.result,
              pagination: res.body.meta,
            });
        }
        // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
      })
      .catch((err) => {
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };

  getFormattedAddress = (location) => {
    let strs = [];
    if (location.address) strs.push(location.address);
    if (location.district) strs.push(location.district.name);
    if (location.province) strs.push(location.province.name);
    return strs.join(", ");
  };

  handleNext = () => {
    const { sliderOffset, projects } = this.state;
    const newOffset = sliderOffset + 1;
    if (newOffset < projects.length - 1)
      this.setState({
        sliderOffset: newOffset,
      });
  };

  handlePrev = () => {
    const { sliderOffset } = this.state;
    const newOffset = sliderOffset - 1;
    if (newOffset >= 0)
      this.setState({
        sliderOffset: newOffset,
      });
  };

  showDetail = ({ _id, title }) => {
    browserHistory.push(`/project/${toHyphens(title)}-${_id}`);
  };

  seeMore = () => {
    const { group } = this.props;
    browserHistory.push("/projects");
  };
}

export default withStyles(styles)(OutstandingProject);
