import {
    container,
    defaultFont,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    roseColor,
    transition,
    boxShadow,
    drawerWidth
  } from 'assets/jss/theme.global';
  
  const headerStyle = {
    appBar: {
      display: "flex",
      border: "0",
      // borderRadius: "3px",
      borderRadius: 0,
      // padding: "0.625rem 0 0.625rem 0",
      // marginBottom: "20px",
      color: "#555",
      width: "100%",
      backgroundColor: "#fff",
      // boxShadow: "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
      transition: "all 150ms ease 0s",
      alignItems: "center",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      position: "relative",
      zIndex: 2,
      '&.white:not(.bordered)': {
        // boxShadow: '0 8px 16px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)',
        boxShadow: '0 1px 8px 0px rgba(0, 0, 0, 0.12), 0 7px 2px -5px rgba(0, 0, 0, 0.15)',
        // boxShadow: '0 8px 3px 0px rgba(0, 0, 0, 0.12), 0 3px 8px 2px rgba(0, 0, 0, 0.15)'
      },
      // '&.bordered': {
      //   boxShadow: 'inherit'
      // },
      '&.padded': {
        // padding: "0.625rem 0 0.625rem 0",
        padding: "0 0 0.625rem 0",
        // '&.pinned': {
        //   padding: 0
        // }
      },
    },
    absolute: {
      position: "absolute"
    },
    fixed: {
      position: "fixed",
      zIndex: "1100"
    },
    sticky: {
      position: "sticky",
      zIndex: "1100"
    },
    container: {
      ...container,
      minHeight: "50px",
      flex: "1",
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      flexWrap: "nowrap"
    },
    flex: {
      flex: 1
    },
    title: {
      ...defaultFont,
      lineHeight: "30px",
      fontSize: "18px",
      borderRadius: "3px",
      textTransform: "none",
      color: "inherit",
      "&:hover,&:focus": {
        color: "inherit",
        background: "transparent"
      }
    },
    appResponsive: {
      margin: "20px 10px"
    },
    primary: {
      backgroundColor: primaryColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)"
    },
    info: {
      backgroundColor: infoColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)"
    },
    success: {
      backgroundColor: successColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)"
    },
    warning: {
      backgroundColor: warningColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)"
    },
    danger: {
      backgroundColor: dangerColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
    },
    rose: {
      backgroundColor: roseColor,
      color: "#FFFFFF",
      boxShadow:
        "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)"
    },
    transparent: {
      backgroundColor: "transparent !important",
      boxShadow: "none",
      // marginTop: "25px",
      // padding: '0 !important',
      color: "#FFFFFF"
    },
    'fade-out-grey': {
      boxShadow: "none",
      // marginTop: "25px",
      // padding: '0 !important',
      color: "#FFFFFF",
      backgroundColor: 'transparent',
      '&::before': {
        content: '""',
        background: "linear-gradient(to top, rgba(38,41,58,0) 0%, rgba(38,41,58,0.5) 15%, rgba(38,41,58,0.7) 25%, rgba(38,41,58,0.95) 50%, rgba(38,41,58,1) 100%)",
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '150px'
      }
    },
    glass: {
      // backgroundColor: "rgba(0, 0, 0, 0.2) !important",
      // background: 'linear-gradient(to bottom, #000 0%, transparent 100%)',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      boxShadow: "none",
      padding: '0 !important',
      color: "#FFFFFF"
    },
    dark: {
      color: "#FFFFFF",
      backgroundColor: "#212121 !important",
      boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
    },
    white: {
      border: "0",
      // padding: "0.625rem 0",
      // marginBottom: "20px",
      color: "#555",
      backgroundColor: "#fff",
      padding: 0,
      borderRadius: 0,
      // '&:last-child': {
      //     marginBottom: '20px'
      // },
      // '&:not(last-child)': {
      //   marginBottom: "20px"
      // }
      '& *': {
        color: '#555'
      }
    },
    'savills-grey': {
      border: "0",
      // padding: "0.625rem 0",
      // marginBottom: "20px",
      color: "#fff",
      backgroundColor: "#26293a",
      padding: 0,
      borderRadius: 0,
    },
    drawerPaper: {
      border: "none",
      // bottom: "0",
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      // top: "0",
      height: "100vh",
      maxHeight: "1200px",
      // right: "0",
      // left: "auto",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      ...transition
    },
    pinned: {
      // position: 'fixed',
      boxShadow: "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
      padding: 0,
      borderRadius: 0,
      '&::before': {
        display: 'none'
      }
    },
    topNav: {
      flex: 1,
      textAlign: 'left',
      'max-height': '55px',
      width: '104%'
    },
    topNavItem: {
      borderRadius: 0,
      color: 'inherit',
      textDecoration: 'none',
      marginLeft: '1rem',
      marginRight: '1rem',
      padding: '.4rem',
      display: 'inline-flex',
      flexDirection: 'column',
      flexBasis: '200px',
      // margin: '8px',
      float: 'left',
      fontSize: '1rem',
      // fontWeight: 'bold',
      fontWeight: 'normal',
      textTransform: 'uppercase',
      cursor: 'pointer',
      transform: 'translateY(0.625rem)',
      '&.active': {
        // borderBottom: '2px solid #FF0000',
        boxShadow: 'inset 0px -2px 0px 0px #f00'
      }
    },
  };
  
  export default headerStyle;
  