import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
import { container } from 'assets/jss/theme.global';
import { multilingual } from '@hoc/multilingual';
import { request } from 'lib/http-services';
const { toHyphens } = require('lib/helper');
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
;
const propertyTypes = {
    2: 'Bán',
    3: 'Cho thuê'
};
const icons = {
    floorArea: <Icon>home</Icon>,
    numOfBedrooms: <img src={require('assets/img/icons/bed.png')} alt="" className="img-icon" />,
    numOfBathrooms: <img src={require('assets/img/icons/accesorii-baie.png')} alt="" className="img-icon" />
}

const styles = theme => ({
    container,
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        // padding: '24px'
    },
    summary: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 3,
        // paddingBottom: theme.spacing.unit * 3,
    },
    summarySpacing: {
        lineHeight: '3rem'
    },
    // card: {
    //     maxWidth: 345,
    // },
    content: {
        textAlign: 'left',
        // '&:not(first-child)': {
        //     borderTop: '1px solid #ddd'
        // }
        borderTop: '1px solid #ddd'
    },
    flexContent: {
        display: 'flex'
    },
    media: {
        height: 0,
        paddingTop: '75%'//'56.25%'
    },
    actions: {
        // display: 'block',
        // overflow: 'hidden',
        // padding: '1rem 20px',
        borderTop: '1px solid #ddd',
        display: 'flex',
        '& .truncate': {
            display: '-webkit-box',
            overflow: 'hidden',
            'line-height': '1.4',
            'white-space': 'normal',
            'text-overflow': 'ellipsis',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            'text-align': 'left'
        }
    },
    actionButton: {
        // margin: 0,
        // paddingLeft: 0,
        // paddingRight: 0
    },
    // address: {
    //     float: 'left',
    //     textAlign: 'left'
    // },
    address: {
        width: '100%'
    },
    price: {
        // float: 'right',
        textAlign: 'right',
        marginLeft: 'auto',
        color: '#FF8F35',
        fontWeight: 'bold',
        // width: '80px',
        width: '240px'
    },
    buttonIcon: {
        '&.left': {
            marginRight: '0.4rem'
        },
        '&.right': {
            marginLeft: '0.4rem'
        }
    },
    loadMoreButton: {
        backgroundColor: '#2a2a2a',
        color: '#fff',
        margin: '2rem auto',
        'border-radius': 0
    },
    tileDescription: {
        display: '-webkit-box',
        'max-width': '100%',
        'max-height': 'calc(1.4em * 3)',
        margin: '0 auto',
        'line-height': '1.4em',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'normal',
        '& p': {
            margin: 0
        }
    },
    featureGridContainer: {
        ...container,
        // maxWidth: '100% !important',
        alignItems: 'center',
        margin: '24px auto 0',
        // margin: '0 auto',
        // padding: '24px',
        maxWidth: 'calc(100% - 24px * 2)',
        '&>div': {
            margin: '0 -12px !important'
        }
    },
    row: {
        width: '100%'
    },
    card: {
        'border-radius': 0,
        // '&:hover': {
        //     'box-shadow': '0 8px 16px 0 rgba(0,0,0,0.2)'
        // }
    },
    feature: {
        // 'margin-bottom': '54px',
        position: 'relative',
        '&>$card': {
            cursor: 'pointer'
        },
        '&:not(:last-child)': {
            // 'margin-bottom': '54px',
            'margin-bottom': '24px',
        }
    },
    featureLabel: {
        position: 'absolute',
        'text-transform': 'uppercase',
        'background-color': '#FFED38',
        color: '#4B4B4B',
        padding: '0.5rem 0',
        top: 0,
        right: '20px',
        transform: 'translateY(-1.5rem)',
        width: '55px',
        height: '56px',
        'line-height': '1.5rem',
        'font-size': '.9rem',
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        '&.sale': {
            'background-color': '#FFED38',
        },
        '&.lease': {
            'background-color': '#F38D00',
        }
    },
    actionIconButton: {
        padding: '8px'
    }
});

@multilingual
class OutstandingRealEstate extends Component {
    state = {
        realEstates: [],
        pagination: {
            total: 0
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    shouldComponentUpdate() {
        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.fetchData();
    }

    render() {
        const { classes, header, dictionary } = this.props;
        const { realEstates, pagination } = this.state;
        let tiles = [[], [], []];
        let i = 0;
        for (let j = 0; j < realEstates.length && j < 6; j++) {
            const element = realEstates[j];
            tiles[i].push(element);
            i = (i + 1) % tiles.length;
        }
        if(realEstates && realEstates.length > 0) return (
            <div className={classes.root}>
                <div className={classNames(classes.featureGridContainer, classes.summary, classes.row)}>
                    {header}
                </div>
                {/* <Grid container spacing={24} className={classNames(classes.container, 'feature-grid')}> */}
                <div className={classes.featureGridContainer}>
                    <Grid container spacing={24}>
                        {tiles.map((tileList, index) => (
                            <Grid key={index} item xs={12} md={4}>
                                {tileList.map(tile => (
                                    // <Grid key={tile.key} item xs={12} md={4} className="feature">
                                    <div key={tile._id} className={classes.feature} onClick={() => this.showDetail(tile)}>
                                        <Card className={classNames(classes.card, 'card', 'hoverable')}>
                                            <CardMedia
                                                className={classes.media}
                                                image={tile.photos && tile.photos.length > 0 ? `${restEndpoint}${tile.photos[0].path}` : require('assets/img/no-photo.jpg')}
                                                title={tile.title}
                                            />
                                            {tile.numOfBedrooms && tile.numOfBathrooms && tile.floorArea ? (
                                                <CardActions className={classes.actions}>
                                                    {tile.floorArea && (
                                                        <React.Fragment>
                                                            <IconButton className={classes.actionIconButton}>
                                                                {icons['floorArea']}
                                                            </IconButton>
                                                            <Typography>
                                                                {/* {tile.floorArea.value} {tile.floorArea.unit.title} */}
                                                                {tile.floorArea.value} m<sup>2</sup>
                                                            </Typography>
                                                        </React.Fragment>
                                                    )}
                                                    {tile.numOfBedrooms && (
                                                        <React.Fragment>
                                                            <IconButton className={classes.actionIconButton}>
                                                                {icons['numOfBedrooms']}
                                                            </IconButton>
                                                            <Typography>
                                                                {tile.numOfBedrooms}
                                                            </Typography>
                                                        </React.Fragment>
                                                    )}
                                                    {tile.numOfBathrooms && (
                                                        <React.Fragment>
                                                            <IconButton className={classes.actionIconButton}>
                                                                {icons['numOfBathrooms']}
                                                            </IconButton>
                                                            <Typography>
                                                                {tile.numOfBathrooms}
                                                            </Typography>
                                                        </React.Fragment>
                                                    )}
                                                </CardActions>
                                            ) : null}
                                            <CardContent className={classes.content}>
                                                {/* <Typography gutterBottom variant="h5" component="h2" className="truncate"> */}
                                                <Typography gutterBottom variant="h5" component="h2" className="truncate">
                                                    {tile.title}
                                                </Typography>
                                                {/* <Typography component="p" className={classNames(classes.tileDescription, 'truncate')}> */}
                                                <Typography component="div" className={classes.tileDescription}>{tile.shortDescription || ''}</Typography>
                                            </CardContent>
                                            <CardActions className={classes.actions}>
                                                <IconButton className={classes.actionIconButton}>
                                                    <Icon>location_on</Icon>
                                                </IconButton>
                                                <Typography className={classes.address}>
                                                    <span className="truncate">{tile.location ? this.getFormattedAddress(tile.location) : ''}</span>
                                                </Typography>
                                                <Typography className={classes.price}>
                                                    {tile.price ? `${tile.price.value.toLocaleString('en')} ${tile.price.currency.name}${tile.group && tile.group._id === 'rent' ? ` / ${tile.price.unit.title}` : ''}` : dictionary['Contact']}
                                                </Typography>
                                            </CardActions>
                                        </Card>
                                        {/* {tile.type && (
                                            <div className={classNames(classes.featureLabel, tile.group._id === 'sale' ? 'sale' : 'lease')}>{tile.type.title}</div>
                                        )} */}
                                    {/* </Grid> */}
                                    </div>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                </div>
                {/* <div className={classNames(classes.featureGridContainer, "feature-grid")}>
                    {tileData.map(tile => (
                        <div key={tile.key} className="feature">
                            <Card className={classes.card}>
                                <CardMedia
                                    className={classes.media}
                                    image={tile.img}
                                    title={tile.title}
                                />
                                <CardActions className={classes.actions}>
                                    {Object.keys(tile.utils).map(util => (
                                        <React.Fragment key={util}>
                                            <IconButton>
                                                <Icon>{icons[util]}</Icon>
                                            </IconButton>
                                            <Typography>
                                                {tile.utils[util]}
                                            </Typography>
                                        </React.Fragment>
                                    ))}
                                </CardActions>
                                <CardContent className={classes.content}>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {tile.title}
                                    </Typography>
                                    <Typography component="p" className={classes.tileDescription}>
                                        {tile.description}
                                    </Typography>
                                </CardContent>
                                <CardActions className={classes.actions}>
                                    <IconButton>
                                        <Icon>location_on</Icon>
                                    </IconButton>
                                    <Typography>
                                        {tile.address}
                                    </Typography>
                                    <Typography className={classes.price}>
                                        {tile.price}
                                    </Typography>
                                </CardActions>
                            </Card>
                            <div className={classNames(classes.featureLabel, 'sale')}>{tile.type}</div>
                        </div>
                    ))}
                </div> */}
                {pagination.total > 6 && (
                    <div className={classes.row}>
                        <Button variant="contained" size="large" className={classes.loadMoreButton} onClick={this.seeMore}>
                            {dictionary['See more']}
                        </Button>
                    </div>
                )}
            </div>
        );
        else return null;
    }

    fetchData = () => {
        const { group, language } = this.props;
        request('/properties', {
            params: {
                featured: true,
                group,
                page: 1,
                limit: 10
            },
            headers: {
                // 'language': localStorage.getItem('lang'),
                language
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    realEstates: res.body.result,
                    pagination: res.body.meta
                });
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    getFormattedAddress = location => {
        let strs = []
        if(location.address) strs.push(location.address)
        if(location.district) strs.push(location.district.name)
        if(location.province) strs.push(location.province.name)
        return strs.join(', ')
    }

    showDetail = ({ _id, title }) => {
        browserHistory.push(`/real-estate/${toHyphens(title)}-${_id}`);
    }
    
    seeMore = () => {
        const { group } = this.props;
        browserHistory.push(`/real-estates?group=${group}`);
    }
}

export default withStyles(styles)(OutstandingRealEstate);