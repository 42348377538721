import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';

class SearchBox extends Component {
    constructor(props) {
        super(props);

    }

    UNSAFE_componentWillMount() {
        this.id = this.props.id || `google-place-searchbox-${Date.now()}`;
    }

    componentDidMount() {
        var _self = this;
        let mapInit = setInterval(() => {
            if(window.google) {
                var addressInput = document.getElementById(_self.id);
                var searchBox = new window.google.maps.places.SearchBox(addressInput);
                window.google.maps.event.addListener(searchBox, 'places_changed', function(){
                    var places = searchBox.getPlaces();
                    if (places.length === 0) {
                        return;
                    }
                    _self.place = places[0]; //.geometry.location;
                    if(_self.props.onChange) _self.props.onChange(_self.place);
                });
                clearInterval(mapInit);
                // window.google.maps.event.trigger(addressInput, 'places_changed');
            }
        }, 1000);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        $('div.pac-container').remove();
    }

    render() {
        const { type, id, name, className, placeholder, value, defaultValue, fullWidth, required, otherProps } = this.props;
        return (
            <Input type="text" id={this.id} name={this.id} className={className || ''} placeholder={placeholder || ''} defaultValue={defaultValue || value || ''} fullWidth={fullWidth || false} required={required || false} {...otherProps} />
        );
    }

}

SearchBox.propTypes = {

};

export default SearchBox;