import React, { Component } from "react";
// import PropTypes from 'prop-types';
import classNames from "classnames";
import { Link } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { loadCSS } from "fg-loadcss/src/loadCSS";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import Divider from '@material-ui/core/Divider';
import Grid from "@material-ui/core/Grid";
// import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import Button from "@material-ui/core/Button";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import footerStyle from './style.js';
import { container } from "assets/jss/theme.global";
// import logo from 'assets/img/logo.png';
import s from "./style.scss";
import { request } from "lib/http-services";
import { multilingual } from "@hoc/multilingual";

const styles = (theme) => ({
  // ...footerStyle,
  // root: {
  //   // flexGrow: 1,
  //   backgroundColor: '#141414',
  //   color: '#fff',
  //   overflow: 'hidden',
  //   margin: 0,
  //   // paddingLeft: '24px',
  //   // paddingRight: '24px'
  //   '& *': {
  //     color: '#fff'
  //   }
  // },
  root: {
    backgroundColor: "#fff",
  },
  container: {
    ...container,
    minHeight: "50px",
    flex: "1",
    "flex-direction": "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "nowrap",
  },
  footerContainer: {
    ...container,
    // maxWidth: '100% !important',
    alignItems: "center",
    // margin: '24px auto',
    margin: 0,
    // padding: '0 46px',
    padding: "0 !important",
    // maxWidth: '100% !important',
    flex: "1",
    justifyContent: "space-between",
    display: "flex",
    // flexWrap: "nowrap",
    // margin: 0,
    // padding: '0 46px',
    // maxWidth: '100% !important',
    // flex: "1",
    // justifyContent: "space-between",
  },
  footerBackground: {
    backgroundColor: "#26293A",
  },
  noShadow: {
    boxShadow: "none",
  },
  footerLine: {
    backgroundColor: "transparent",
    color: "#ddd",
    "&:not(:last-child)": {
      width: "100%",
      margin: "0 auto",
      padding: 0,
    },
  },
  borderTop: {
    borderTop: "1px solid rgba(171, 171, 171, 0.12)",
  },
  insideFooterLine: {
    justifyContent: "space-between",
  },
  button: {
    // margin: theme.spacing.unit,
    textTransform: "inherit",
    fontWeight: "inherit",
    // padding: '8px 0'
  },
  icon: {
    margin: theme.spacing.unit * 2,
    width: "inherit",
  },
  leftIcon: {
    width: "24px",
    height: "24px",
    marginRight: theme.spacing.unit,
  },
  divider: {
    backgroundColor: "rgba(171, 171, 171, 0.12)",
  },
  listItemText: {
    color: "#D1D1D1",
    padding: "8px 0",
    lineHeight: "1.5rem",
    "&>*": {
      fontSize: ".9rem",
      paddingLeft: "16px !important",
    },
  },
  listGroupTitle: {
    color: "#FFED38",
    fontWeight: "bold",
    // padding: 0,
    padding: "12px 0",
    lineHeight: "2rem",
    "&>*": {
      fontSize: "1rem",
      paddingLeft: "16px !important",
    },
  },
  logo: {
    //   filter: 'grayscale(0.2)',
    width: "83px",
    height: "83px",
  },
  logoLink: {
    height: "83px",
  },
  footerlogo: {
    padding: "24px 12px",
  },
  flexStart: {
    alignItems: "flex-start",
  },
  copyrightText: {
    "text-align": "left",
    // 'padding-left': '16px',
    "line-height": "1.6em",
  },
  footerMenuHeader: {
    display: "block",
    color: "#30333a",
    "text-decoration": "none",
    "padding-bottom": "20px",
    "font-size": "16px",
    "line-height": 1.5,
    "text-align": "left",
    padding: "18px 0",
    "font-weight": "bold",
    "background-color": "#fff",
  },
  footerMenu: {
    // padding: 'calc(10px + 8px)',
    "background-color": "#fff",
    "& > div": {
      "text-align": "left",
    },
    "& $menuItem": {
      // color: '#3b93ad',
      color: "#272a3a",
      display: "block",
      float: "left",
      "margin-bottom": "1rem",
      "text-overflow": "ellipsis",
      "white-space": "nowrap",
      width: "100%",
      overflow: "hidden",
      "text-decoration": "none",
      "font-size": "12px",
    },
  },
  menuItem: {},
});

@multilingual
class Footer extends Component {
  state = {
    id: `footer-${Date.now()}`,
    menu: [],
  };

  componentDidMount() {
    this.getMenu();
    loadCSS(
      "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
      document.querySelector("#insertion-point-jss")
    );
  }

  componentWillUnmount() {}

  isExternalLink = (url) => new RegExp("^https?://.+").test(url);

  render() {
    const { menu } = this.state;
    const {
      classes,
      dictionary,
      // menu
    } = this.props;
    const language = localStorage.getItem("lang");
    const footerMenu = menu.find(
      (m) => m.type._id === "footer" && m.language._id === language
    );
    // const brandComponent = brand && (
    //   <Button className={classes.title}>
    //     {brand}
    //   </Button>
    // );
    return (
      <div className={classes.root}>
        {!!footerMenu ? (
          <div className={classes.container}>
            <div className={classes.footerMenuHeader}>
              {dictionary["Featured location"]}
            </div>
            <Grid
              container
              alignItems="flex-start"
              justify="space-between"
              className={classes.footerMenu}
            >
              {footerMenu.items
                .filter((mi) => !mi.parent)
                .map((mi) => {
                  const footerSubmenuItems = footerMenu.items.filter(
                    (smi) => smi.parent === mi._id
                  );
                  if (footerSubmenuItems.length > 0)
                    return (
                      <Grid
                        key={mi._id}
                        item
                        xs={12}
                        sm={Math.floor(
                          12 /
                            footerMenu.items.filter((mi) => !mi.parent).length
                        )}
                      >
                        {footerSubmenuItems.map((smi) => {
                          if (this.isExternalLink(smi.url))
                            return (
                              <a
                                key={smi._id}
                                href={smi.url}
                                className={classes.menuItem}
                              >
                                {smi.text}
                              </a>
                            );
                          else
                            return (
                              <Link
                                key={smi._id}
                                to={smi.url}
                                className={classes.menuItem}
                              >
                                {smi.text}
                              </Link>
                            );
                        })}
                      </Grid>
                    );
                  return null;
                })}
            </Grid>
          </div>
        ) : null}
        <div
          className={classNames(classes.borderTop, classes.footerBackground)}
        >
          <AppBar
            position="static"
            color="inherit"
            className={classNames(
              classes.container,
              classes.footerLine,
              classes.noShadow
            )}
          >
            <Toolbar
              className={classNames(
                classes.insideFooterLine,
                classes.footerContainer
              )}
            >
              <Typography
                variant="body1"
                color="inherit"
                className={classes.copyrightText}
              >
                Copyright @ 2018 Azoro. All Rights Reserved.
              </Typography>
              <div>
                <Button color="inherit" className={classes.button}>
                  <img
                    src={require("assets/svg/legal-paper-invert.svg")}
                    alt=""
                    className={classes.leftIcon}
                  />
                  {dictionary["Privacy Policy"]}
                </Button>
                <Button color="inherit" className={classes.button}>
                  <img
                    src={require("assets/svg/auction-invert.svg")}
                    alt=""
                    className={classes.leftIcon}
                  />
                  {dictionary["Terms of use"]}
                </Button>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
    // return (
    //   <div className={classNames(classes.root, s.footer, 'footer')}>
    //     <Grid container className={classNames(classes.footerLine, classes.footerContainer, classes.flexStart)}>
    //       <Grid item xs={12} md={3}>
    //         <Grid container>
    //           <Grid item xs={12} className={classes.footerlogo}>
    //             <Link className={classes.logoLink} to="/"><img src={logo} alt="Azoro" className={classes.logo} /></Link>
    //           </Grid>
    //           <Grid item xs={12}>
    //             <Icon className={classNames(classes.icon, 'fab fa-facebook')} color="inherit" />
    //             <Icon className={classNames(classes.icon, 'fab fa-twitter')} color="inherit" />
    //             <Icon className={classNames(classes.icon, 'fab fa-instagram')} color="inherit" />
    //             <Icon className={classNames(classes.icon, 'fab fa-youtube')} color="inherit" />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       <Grid item xs={12} md={9}>
    //         <Grid container>
    //           <Grid item xs={12} md={3}>
    //             <List dense>
    //               <ListItem className={classes.listGroupTitle}>
    //                 <ListItemText primary={dictionary['Company']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['About us']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Team']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Recruitment']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Contact']} disableTypography />
    //               </ListItem>
    //             </List>
    //           </Grid>
    //           <Grid item xs={12} md={3}>
    //             <List dense>
    //               <ListItem className={classes.listGroupTitle}>
    //                 <ListItemText primary={dictionary['Service']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Why choose Azoro?']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Sell or lease real estate']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Expert Azoro']} disableTypography />
    //               </ListItem>
    //             </List>
    //           </Grid>
    //           <Grid item xs={12} md={3}>
    //             <List dense>
    //               <ListItem className={classes.listGroupTitle}>
    //                 <ListItemText primary={dictionary['Information']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Expert Azoro']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Reporting and analysis']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Real estate handbook']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Traning center']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['User manual']} disableTypography />
    //               </ListItem>
    //             </List>
    //           </Grid>
    //           <Grid item xs={12} md={3}>
    //             <List dense>
    //               <ListItem className={classes.listGroupTitle}>
    //                 <ListItemText primary={dictionary['Azoro apps']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Azoro on iOS']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Azoro on Android']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Azoro Agents on iOS']} disableTypography />
    //               </ListItem>
    //               <ListItem button component="a" className={classes.listItemText}>
    //                 <ListItemText primary={dictionary['Azoro Agents on Android']} disableTypography />
    //               </ListItem>
    //             </List>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //     <Divider className={classes.divider} />
    //     <AppBar position="static" className={classes.footerLine}>
    //       <Toolbar className={classNames(classes.insideFooterLine, classes.footerContainer)}>
    //         <Typography variant="body1" color="inherit" className={classes.copyrightText}>
    //           Copyright @ 2018 Azoro. All Rights Reserved.
    //         </Typography>
    //         <div>
    //           <Button color="inherit" className={classes.button}>
    //             <img src={require('assets/svg/legal-paper-invert.svg')} alt="" className={classes.leftIcon} />
    //             {dictionary['Privacy Policy']}
    //           </Button>
    //           <Button color="inherit" className={classes.button}>
    //             {/* <Icon className={classes.leftIcon}>gavel</Icon> */}
    //             <img src={require('assets/svg/auction-invert.svg')} alt="" className={classes.leftIcon} />
    //             {dictionary['Terms of use']}
    //           </Button>
    //         </div>
    //       </Toolbar>
    //     </AppBar>
    //   </div>
    // );
  }

  getMenu = () => {
    request("/menu", {
      headers: {
        language: localStorage.getItem("lang"),
      },
    })
      .then((res) => {
        if (!res.stack) {
          if (res.status >= 400);
          else {
            if (res.body.result.length > 0)
              this.setState({
                menu: res.body.result,
              });
          }
        }
        // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
      })
      .catch((err) => {
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };

  headerColorChange = () => {
    const { classes, changeColorOnScroll } = this.props;
    const pinned = !document.body
      .getElementsByTagName("header")[0]
      .classList.contains(classes.transparent);
    if (pinned) this.changeBackColor(changeColorOnScroll.invertHeight);
    else this.changeBackColor(changeColorOnScroll.height);
  };

  changeBackColor = (scrollHeight) => {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    let header = document.body.querySelector(`.${this.state.id}`);
    if (windowsScrollTop > scrollHeight) {
      header.classList.remove(classes[color]);
      header.classList.add(
        classes[changeColorOnScroll.color],
        classes.pinned,
        "pinned"
      );
    } else {
      header.classList.add(classes[color]);
      header.classList.remove(
        classes[changeColorOnScroll.color],
        classes.pinned,
        "pinned"
      );
    }
  };
}

Footer.propTypes = {};

Footer.defaultProp = {
  id: `header-${Date.now()}`,
};

export default withStyles(styles)(Footer);
