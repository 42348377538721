import React from 'react'
import component from './component'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as actions from '../actions'

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.toast
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        action: bindActionCreators(actions, dispatch),
        dispatch: dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(component)