import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Parallax from 'components/parallax';
// import Hero from './sections/hero';
import ShowCase from './sections/projects';
import Footer from 'components/footer';
import { container } from 'assets/jss/theme.global';
import { multilingual } from '@hoc/multilingual';

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      padding: '0 !important',
      position: 'relative'
    },
    overlayContainer: {
        // ...container,
        alignItems: 'center',
        margin: '0 auto',
    },
    projectCOntainer: {
        ...container,
        alignItems: 'center',
        margin: '24px auto',
    },
    summaryTitle: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: '3rem',
        marginBottom: 0
    },
    summarySubtitle: {
        color: '#fff',
        fontSize: '1.3rem'
    }
});

@multilingual
class Project extends Component {
    render() {
        const { classes, dictionary } = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    {/* <Parallax image={require('assets/img/villa/villa1.jpg')} /> */}
                    {/* <Hero className={classes.searchOverlayContainer} dictionary={dictionary} /> */}
                    <ShowCase className={classes.projectCOntainer} />
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

// Project.propTypes = {

// };

export default withStyles(styles)(Project);