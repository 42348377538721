import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { browserHistory, withRouter } from 'react-router';
import s from './style.scss'
import { withStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

const styles = theme => ({
    margin: {
        margin: theme.spacing.unit,
    },
    fab: {
        margin: theme.spacing.unit,
        '&.active': {
            // backgroundColor: '#FFE850',
            color: 'rgba(0, 0, 0, 0.54)'
        }
    },
    pageItem: {
        boxShadow: 'none',
        backgroundColor: 'transparent'
    }
})
  
class Pagination extends Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 10,
            page: 0,
            pages: 0,
            total: 0
        }

    }

    UNSAFE_componentWillMount() {
    }

    componentDidMount() {
        const { onRef, onMount } = this.props
        if(onRef) onRef(this)
        if(onMount) onMount()
        this.init();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.location !== prevProps.location) this.init();
    }

    componentWillUnmount() {

    }

    render() {
        const { classes } = this.props
        const {
            page,
            pages
        } = this.state
        return (
            <div>
                <IconButton className={classes.margin} onClick={this.prevPage} disabled={page <= 0}>
                    <Icon>chevron_left</Icon>
                </IconButton>
                {Array.from(Array(pages).keys()).map(i => page === i ? (
                        <Fab key={i} size="small" className={classNames(classes.fab, 'active')} disabled>
                            {i + 1}
                        </Fab>
                    ) : (
                        <Fab key={i} size="small" className={classNames(classes.fab, classes.pageItem)} onClick={this.setPage(i)}>
                            {i + 1}
                        </Fab>
                    ))}
                <IconButton className={classes.margin} onClick={this.nextPage} disabled={page >= pages - 1}>
                    <Icon>chevron_right</Icon>
                </IconButton>
            </div>
        )
    }
    
    // init = () => {
    //     let newState = {};
    //     if(this.props.limit) newState['limit'] = this.props.limit
    //     if(this.props.page) newState['page'] = this.props.page - 1
    //     if(this.props.pages) newState['pages'] = this.props.pages
    //     if(this.props.total) newState['total'] = this.props.total
    //     this.setState({ ...newState })
    // }

    init = () => {
        const { onChange } = this.props
        const { page, limit, ...otherQueries } = this.props.location ? this.props.location.query : {};
        let newState = {
            page: page ? (parseInt(page) - 1) : this.props.page - 1,
            limit: limit ? parseInt(limit) : this.props.limit
        };
        if(this.props.pages) newState['pages'] = this.props.pages
        if(this.props.total) newState['total'] = this.props.total
        this.setState({ ...newState })
        onChange && onChange({ ...otherQueries, page: newState.page + 1, limit: newState.limit })
    }

    prevPage = e => {
        if(!$(e.target).closest('a').hasClass(s.disabled)) {
            let firstPage = 0;
            let page = this.state.page - 1 < firstPage ? firstPage : this.state.page - 1;
            const { page: rp, limit: rl, ...queries } = this.props.location ? this.props.location.query : {};
            const q = {
                page: page + 1,
                limit: this.state.limit,
                ...queries
            };
            if(this.props.location) browserHistory.push({
                pathname: this.props.location.pathname,
                search: !!q && Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${q[k]}`).join('&') : ''//`?page=${page + 1}&limit=${this.state.limit}`
            })
            else this.setState({
                page
            }, () => this.props.onChange && this.props.onChange({ page: page + 1, limit: this.state.limit }));
        }
    }

    nextPage = e => {
        if(!$(e.target).closest('a').hasClass(s.disabled)) {
            let lastPage = this.state.pages - 1;
            let page = this.state.page + 1 > lastPage ? lastPage : this.state.page + 1;
            const { page: rp, limit: rl, ...queries } = this.props.location ? this.props.location.query : {};
            const q = {
                page: page + 1,
                limit: this.state.limit,
                ...queries
            };
            if(this.props.location) browserHistory.push({
                pathname: this.props.location.pathname,
                search: !!q && Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${q[k]}`).join('&') : ''//`?page=${page + 1}&limit=${this.state.limit}`
            })
            else this.setState({
                page
            }, () => this.props.onChange && this.props.onChange({ page: page + 1, limit: this.state.limit }));
        }
    }

    firstPage = e => {
        if(!$(e.target).closest('a').hasClass(s.disabled)) {
            let page = 0;
            const { page: rp, limit: rl, ...queries } = this.props.location ? this.props.location.query : {};
            const q = {
                page: page + 1,
                limit: this.state.limit,
                ...queries
            };
            if(this.props.location) browserHistory.push({
                pathname: this.props.location.pathname,
                search: !!q && Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${q[k]}`).join('&') : ''//`?page=${page + 1}&limit=${this.state.limit}`
            })
            else this.setState({
                page
            }, () => this.props.onChange && this.props.onChange({ page: page + 1, limit: this.state.limit }));
        }
    }
    
    lastPage = e => {
        if(!$(e.target).closest('a').hasClass(s.disabled)) {
            let page = this.state.pages - 1;
            const { page: rp, limit: rl, ...queries } = this.props.location ? this.props.location.query : {};
            const q = {
                page: page + 1,
                limit: this.state.limit,
                ...queries
            };
            if(this.props.location) browserHistory.push({
                pathname: this.props.location.pathname,
                search: !!q && Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${q[k]}`).join('&') : ''//`?page=${page + 1}&limit=${this.state.limit}`
            })
            else this.setState({
                page
            }, () => this.props.onChange && this.props.onChange({ page: page + 1, limit: this.state.limit }));
        }
    }

    // setPage = page => () => this.setState({
    //     page
    // }, () => this.props.onChange && this.props.onChange({
    //     page: page + 1,
    //     limit: this.state.limit
    // }))

    setPage = page => () => {
        const { page: rp, limit: rl, ...queries } = this.props.location ? this.props.location.query : {};
        const q = {
            page: page + 1,
            limit: this.state.limit,
            ...queries
        };
        if(this.props.location) browserHistory.push({
            pathname: this.props.location.pathname,
            search: !!q && Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${q[k]}`).join('&') : ''//`?page=${page + 1}&limit=${this.state.limit}`
        })
        else this.setState({
            page
        }, () => this.props.onChange && this.props.onChange({
            page: page + 1,
            limit: this.state.limit
        }))
    }
    
    getPage = () => this.state.page + 1
    
    getLimit = () => this.state.limit

}

Pagination.propTypes = {
    total: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    onChange: PropTypes.func
}

Pagination.defaultProps = {
    total: 0,
    page: 1,
    pages: 1,
    limit: 10
}

export default withRouter(withStyles(styles)(Pagination))