import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { container } from 'assets/jss/theme.global';
// import { Divider } from '@material-ui/core';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import MobileStepper from '@material-ui/core/MobileStepper';
// import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import SwipeableViews from 'react-swipeable-views';
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;

const detail = {
    utils: {
        'Block': 6,
        'Căn hộ': 2000,
        'Giá căn hộ': 'Từ 1.5 tỷ',
        'Sở hữu': 'Vĩnh viễn',
        'Công viên': `500${<sup>m2</sup>}`
    },
};

const styles = theme => ({
    root: {
        ...container,
        maxWidth: '100%',
        flexGrow: 1,
        // maxWidth: '100% !important',
        alignItems: 'center',
        // margin: '24px auto',
        margin: '0 auto',
        // padding: '24px'
    },
    galleryRoot: {
        'background-color': '#fff'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        // width: '100%',
        // margin: '-2px',
        // 'margin-left': 'calc(-100vw - 2px)',
        overflow: 'hidden',
        width: '100%',
        height: '60%',
        transition: 'margin-left 500ms',
        margin: '0 !important'
    },
    gridListTile: {
        // width: '50% !important',
        // width: '50vw !important',
        width: '100% !important',
        height: '100% !important',
        height: 'inherit !important',
        padding: '0 !important',
        // 'margin-right': '4px'
    },
    titleBarImage: {
        width: '100%',
        height: 0,
        paddingTop: '60%',
        // 'background-color': 'rgba(0, 0, 0, .2)',
        // 'background-blend-mode': 'overlay',
        '&:hover': {
            'background-color': 'inherit',
            'background-blend-mode': 'inherit',
            // cursor: 'pointer'
        }
    },
    slider: {
        position: 'relative',
        overflow: 'hidden',
        // width: '100vw',
        width: '100%',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    smallSlider: {
        position: 'relative',
        // overflow: 'hidden',
        width: 'calc(100% - 48px * 2)',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    insideSmallSlider: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        left: '50%',
        transform: 'translateX(-50%)'
    },
    nextButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        color: '#fff',
        // zoom: 2,
    },
    prevButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        color: '#fff',
        // zoom: 2,
    },
    smallNextButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        right: '-48px',
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        // color: '#fff',
        // zoom: 2,
    },
    smallPrevButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        left: '-48px',
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        // color: '#fff',
        // zoom: 2,
    },
    img: {
        // position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '60%',
        // margin: '12px auto',
        // overflow: 'hidden'
    },
    imgItem: {
        // position: 'relative',
        // width: 'calc((100% - 40px) / 5)',
        width: 'calc(((100% / 10) - 10px * 4) / 5)',
        height: 0,
        // paddingTop: 'calc(((100% - 40px) / 5) * 0.7)',
        paddingTop: 'calc((((100% / 10) - 10px * 4) / 5) * 0.7)',
        float: 'left',
        // margin: '12px auto',
        // overflow: 'hidden',
        cursor: 'pointer',
        // marginRight: '10px',
        // 'background-color': 'rgba(0, 0, 0, .5)',
        // 'background-blend-mode': 'overlay',
        '&.active': {
            // 'background-blend-mode': 'inherit'
            'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        },
        '&:hover': {
            // 'background-blend-mode': 'inherit'
            'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        },
        // '&:not(:nth-child(5n))': {
        //     marginRight: '10px'
        // }
        '&:not(:last-of-type)': {
            marginRight: '10px'
        }
    },
    photoList: {
        width: '1000% !important',
        marginTop: '10px',
        // height: `calc((((100% - (10px * 4)) * 10) / (${detail.photos.length} * 10)) * 0.7)`,
        transition: 'margin-left 500ms'
    },
    row: {
        width: '100%',
        overflow: 'hidden',
        display: 'block',
        position: 'relative',
        marginBottom: '10px'
    },
});
  
class PhotoGallery extends Component {
    state = {
        sliderOffset: 0,
        selectedImage: 0,
        offset: 0,
    };
    render() {
        const { classes, data } = this.props;
        const { selectedImage, offset, sliderOffset } = this.state;

        return (
            <div className={classes.galleryRoot}>
                <div className={classes.slider}>
                    {/* {sliderOffset > 0 && (
                        <IconButton className={classes.prevButton} onClick={this.handleSliderPrev}>
                            <Icon>chevron_left</Icon>
                        </IconButton>
                    )} */}
                    <GridList className={classes.gridList} cols={1} style={{marginLeft: `calc(-1 * (100% / 2) * ${sliderOffset})`}}>
                        {data.map((img, index) => (
                            <GridListTile key={index} className={classes.gridListTile}>
                                <div style={{backgroundImage: `url(${img})`}} className={classNames(classes.titleBarImage, 'cover')}></div>
                            </GridListTile>
                        ))}
                    </GridList>
                    {/* {sliderOffset < detail.photos.length - 2 && (
                        <IconButton className={classes.nextButton} onClick={this.handleSliderNext}>
                            <Icon>chevron_right</Icon>
                        </IconButton>
                    )} */}
                </div>
                <div className={classes.smallSlider}>
                    {offset > 0 && (
                        <IconButton className={classes.smallPrevButton} onClick={this.handleBack}>
                            <Icon>chevron_left</Icon>
                        </IconButton>
                    )}
                    <div className={classes.insideSmallSlider}>
                        <div className={classNames(classes.photoList, classes.row)} style={{marginLeft: `calc(-1 * ((100% - 10px * 4) / 5) * ${offset})`}}>
                            {data.map((photo, index) => (
                                <div
                                    key={`item-${photo._id || photo}-${index}`}
                                    className={classNames(classes.imgItem, 'cover', index === selectedImage && 'active')}
                                    style={{backgroundImage: `url(${restEndpoint}${photo.path})`}}
                                    onClick={() => this.handleIndexChange(index)}
                                ></div>
                            ))}
                        </div>
                    </div>
                    {offset < data.length - 5 && (
                        <IconButton className={classes.smallNextButton} onClick={this.handleNext}>
                            <Icon>chevron_right</Icon>
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }
    
    handleSliderNext = () => {
        const { data } = this.props;
        const { sliderOffset } = this.state;
        const newOffset = sliderOffset + 1;
        if(newOffset < data.length - 1) this.setState({
            sliderOffset: newOffset
        });
    }

    handleSliderPrev = () => {
        const { sliderOffset } = this.state;
        const newOffset = sliderOffset - 1;
        if(newOffset >= 0) this.setState({
            sliderOffset: newOffset
        });
    }

    handleNext = () => {
        const { data } = this.props;
        const { offset } = this.state;
        const newOffset = offset + 1;
        if(newOffset < data.length - 4) this.setState({
            offset: newOffset
        });
    }
    
    handleBack = () => {
        const { offset } = this.state;
        const newOffset = offset - 1;
        if(newOffset >= 0) this.setState({
            offset: newOffset
        });
    }
    
    handleIndexChange = offset => {
        const { data } = this.props;
        this.setState({
            sliderOffset: offset > data.length - 1 ? data.length - 1 : offset,
            selectedImage: offset
        });
    }

}
PhotoGallery.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(PhotoGallery);