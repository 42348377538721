import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import s from './style.scss';

class Parallax extends Component {
    render() {
        const { image } = this.props;
        return (
            <div className="parallax" style={{ backgroundImage: `url(${image})` }}></div>
        );
    }
}

Parallax.propTypes = {
    image: PropTypes.string.isRequired
};

export default Parallax;