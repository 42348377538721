import React from 'react'
import ReactDOM, { hydrate } from 'react-dom'
// import { Router, browserHistory } from 'react-router'
import { AppContainer } from 'react-hot-loader'
// import { createBrowserHistory } from 'history'
// import routes from 'routes'
// import registerServiceWorker from './registerServiceWorker'
import configureStore from './store'
// import fs from 'fs'

if (process.env.NODE_ENV === 'production') console.log = console.warn = console.error = () => {}

if(!window.localStorage.getItem('lang')) window.localStorage.setItem('lang', 'vi')
// const locales = ['vi', 'en', 'zh']
// locales.forEach(lang => {
//   const dictionary = require(`dictionary/${lang}.json`)
//   window.localStorage.setItem('dictionary', dictionary)
// })
// const defaultLocale = 'vi'
// const dictionary = require(`dictionary/${defaultLocale}.json`)
// window.localStorage.setItem('dictionary', JSON.stringify(dictionary))

import RootContainer from './root'

if (process.env.NODE_ENV === 'production') console.log = console.warn = console.error = () => {}
// var hist = createBrowserHistory();

const preloadedState = window.__PRELOADED_STATE__ || {}
delete window.__PRELOADED_STATE__
const store = configureStore(preloadedState)
// const store = configureStore({})

var render = (RootElement) => {
    let rendering = !!module.hot ? ReactDOM.render : hydrate;
    rendering(
      <AppContainer>
        <RootElement store={store} />
      </AppContainer>,
      document.getElementById('root')
    )
  }
  
render(RootContainer)

if(module.hot) {
    module.hot.accept('./root', () => {
        const nextRoot = require('./root').default({store})
        render(nextRoot)
    })
}
// registerServiceWorker();
