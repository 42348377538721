const config = require('config')
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint
const apiKey = process.env.API_KEY || config.apiKey

/**
 * 
 * @param {String} apiEndpoint Api endpoint
 * @param {Object} options Options
 */
export function request (apiEndpoint, options) {
    const { method, params, headers, body } = options || {}
    let query = []
    Object.keys(params || {}).forEach(f => {
        query.push(`${f}=${encodeURIComponent(params[f])}`)
    })
    return fetch(`${restEndpoint}${apiEndpoint}${query.length > 0 ? '?' + query.join('&') : ''}`, {
        method: method || 'GET',
        headers: {
            ...(headers || {}),
            'x-api-key': apiKey,
        },
        body
    })
    // .then(res => {
    //     if (res.status === 404) return window.location.href = '/'
    //     else return res
    // })
    .then(res => !res.ok ? ({
        status: res.status,
        body: {
            message: 'Resource not found'
        }
    }) : res.json().then(data => ({
        status: res.status,
        body: data
    })))
}