import React, { lazy } from 'react';
import { lazyLoad } from 'components/lazy';
import { Route, IndexRoute, Redirect } from 'react-router';
import 'assets/css/theme.global.css';
// import Layout from 'views/layout';
// import Home from 'views/pages/home';
// import About from 'views/pages/introductions';
// import Contact from 'views/pages/contact';
// import Buy from 'views/pages/buy';
// import Project from 'views/pages/project';
// import ProjectDetail from 'views/pages/project-detail';
// import RealEstateDetail from 'views/pages/real-estate-detail';
// import NewsFeed from 'views/pages/news-feed';
// import NewsDetail from 'views/pages/news-detail';
// import { SignIn, UserProfile, Authorization } from 'session';
const Layout = lazy(() => import('views/layout'));
// const Layout = lazy(() => import('appContainer'));
const Home = lazy(() => import('views/pages/home'));
const Introductions = lazy(() => import('views/pages/introductions'));
const Contact = lazy(() => import('views/pages/contact'));
const Buy = lazy(() => import('views/pages/buy'));
const Project = lazy(() => import('views/pages/project'));
const ProjectDetail = lazy(() => import('views/pages/project-detail'));
const RealEstateDetail = lazy(() => import('views/pages/real-estate-detail'));
const NewsFeed = lazy(() => import('views/pages/news-feed'));
const NewsDetail = lazy(() => import('views/pages/news-detail'));

function requireLogout(nextState, replace) {
  if(window.localStorage.getItem('token')) {
      replace({
          pathname: '/',
          state: {
              nextPathname: nextState.location.pathname
          }
      })
  }
}

export default (
  <React.Fragment>
    <Route path="/" component={lazyLoad(Layout)}>
      <IndexRoute component={lazyLoad(Home)} />
      <Route path="/real-estates" component={lazyLoad(Buy)} headerColor="savills-grey" />
      <Route path="/projects" component={lazyLoad(Project)} headerColor="savills-grey" />
      <Route path="/project/(:title-):id" component={lazyLoad(ProjectDetail)} headerColor="savills-grey" />
      <Route path="/real-estate/(:title-):id" component={lazyLoad(RealEstateDetail)} headerColor="savills-grey" />
      <Route path="/news" component={lazyLoad(NewsFeed)} headerColor="savills-grey" />
      <Route path="/news/(:title-):id" component={lazyLoad(NewsDetail)} headerColor="savills-grey" />
      <Route path="/events" component={lazyLoad(NewsFeed)} headerColor="savills-grey" />
      <Route path="/contact" component={lazyLoad(Contact)} headerColor="savills-grey" />
      <Route path="/introductions" component={lazyLoad(Introductions)} headerColor="savills-grey" />
      <Route path="/introductions/(:title-):id" component={lazyLoad(Introductions)} headerColor="savills-grey" />
      <Redirect from="*" to="/" />
    </Route>
    {/* <Route path="/login" component={SignIn} onEnter={requireLogout} /> */}
  </React.Fragment>
);
