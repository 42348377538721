import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Divider from '@material-ui/core/Divider';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { container } from 'assets/jss/theme.global';
// import { Divider } from '@material-ui/core';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import MobileStepper from '@material-ui/core/MobileStepper';
// import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import SwipeableViews from 'react-swipeable-views';
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;

const detail = {
    utils: {
        'Block': 6,
        'Căn hộ': 2000,
        'Giá căn hộ': 'Từ 1.5 tỷ',
        'Sở hữu': 'Vĩnh viễn',
        'Công viên': `500${<sup>m2</sup>}`
    },
    photos: [
        {
            key: 'the-new-city-thu-thiem',
            img: 'https://giaodichbatdongsan.org/wp-content/uploads/2017/10/Saigon-Riverside-City.jpg'
        },
        {
            key: 'the-new-city-thu-thiem-1',
            img: require('assets/img/villa/villa1.jpg')
        },
        {
            key: 'the-new-city-thu-thiem-2',
            img: require('assets/img/villa/villa2.jpeg')
        },
        {
            key: 'the-new-city-thu-thiem-3',
            img: 'https://giaodichbatdongsan.org/wp-content/uploads/2017/10/Saigon-Riverside-City.jpg'
        },
        {
            key: 'the-new-city-thu-thiem-4',
            img: 'https://giaodichbatdongsan.org/wp-content/uploads/2017/10/Saigon-Riverside-City.jpg'
        },
        {
            key: 'the-new-city-thu-thiem-5',
            img: 'https://giaodichbatdongsan.org/wp-content/uploads/2017/10/Saigon-Riverside-City.jpg'
        },
        {
            key: 'the-new-city-thu-thiem-6',
            img: 'https://giaodichbatdongsan.org/wp-content/uploads/2017/10/Saigon-Riverside-City.jpg'
        },
    ]
};

const styles = theme => ({
    root: {
        ...container,
        maxWidth: '100%',
        flexGrow: 1,
        // maxWidth: '100% !important',
        alignItems: 'center',
        // margin: '24px auto',
        margin: '0 auto',
        // padding: '24px'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
        // width: '100%',
        // margin: '-2px',
        // 'margin-left': 'calc(-100vw - 2px)',
        overflow: 'hidden',
        width: '1000%',
        height: '60vw',
        transition: 'margin-left 500ms',
        margin: 0,
        [theme.breakpoints.up(600)]: {
            height: 'calc(60vw / 2)',
        }
    },
    gridListTile: {
        // width: '50% !important',
        // width: '50vw !important',
        width: '100vw !important',
        // height: '300px !important',
        height: 'inherit !important',
        padding: '0 !important',
        [theme.breakpoints.up(600)]: {
            width: 'calc((100vw - 4px) / 2) !important',
            'margin-right': '4px',
        },
    },
    titleBarImage: {
        width: '100%',
        height: 0,
        paddingTop: '60%',
        'background-color': 'rgba(0, 0, 0, .2)',
        'background-blend-mode': 'overlay',
        '&:hover': {
            'background-color': 'inherit',
            'background-blend-mode': 'inherit',
            // cursor: 'pointer'
        }
    },
    slider: {
        position: 'relative',
        overflow: 'hidden',
        // width: '100vw',
        width: 'calc(100vw - 14px)',
        left: '50%',
        transform: 'translateX(-50%)',
        'background-color': '#1E1E1E'
    },
    smallSlider: {
        position: 'relative',
        // overflow: 'hidden',
        width: '100%',
        // height: '150px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
        'justify-content': 'space-between'
    },
    insideSmallSlider: {
        position: 'relative',
        overflow: 'hidden',
        // width: '100%',
        // width: 'calc((100% - 10px * 4) / 5 * 4 + 10px * 3)',
        width: 'calc((100% - 10px * 4) / 5 * 5 + 10px * 4)',
        // left: '50%',
        // transform: 'translateX(-50%)',
        // '&~$viewMode': {
        //     width: 'calc((100% - 10px * 4) / 5)',
        // }
    },
    viewMode: {
        // position: 'relative',
        // height: 0,
        // 'padding-top': 'calc((100% - 10px * 4) / 5 * 0.7)',
        // // height: 'calc(100% - 10px * 2)',
        position: 'absolute',
        top: 'calc(-50px - 10px)',
        right: 0,
        width: 'calc(50px * 2)',
        height: '50px',
        '& $viewModeButton': {
            position: 'absolute',
            width: '50px',
            height: '50px',
            // 'line-height': 2,
            'line-height': '50px',
            '&:not(.disabled):hover': {
                // 'background-color': theme.palette.primary.main,
                'background-color': 'rgba(0, 0, 0, 0.5)',
                // color: '#808080',
                cursor: 'pointer'
            },
            '&.active': {
                // 'background-color': '#FFED38'
                'background-color': 'rgba(0, 0, 0, 0.5)',
                color: theme.palette.primary.main
            },
            '&.disabled': {
                // color: '#ccc'
                'background-color': 'rgba(0, 0, 0, 0.3)',
                color: 'rgba(255, 255, 255, 0.5)'
            },
            '&:nth-child(1)': {
                top: 0,
                left: 0,
            },
            '&:nth-child(2)': {
                top: 0,
                left: '50%',
            },
            '&:nth-child(3)': {
                top: '50%',
                left: 0,
            },
            '&:nth-child(4)': {
                top: '50%',
                left: '50%',
            }
        }
    },
    viewModeButton: {},
    nextButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        color: '#fff',
        // zoom: 2,
    },
    prevButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        color: '#fff',
        // zoom: 2,
    },
    smallNextButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        right: '-48px',
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        // color: '#fff',
        // zoom: 2,
    },
    smallPrevButton: {
        width: '48px !important',
        height: '48px !important',
        padding: '0 !important',
        position: 'absolute',
        left: '-48px',
        top: '50%',
        transform: 'translateY(-50%)',
        'z-index': 1,
        // color: '#fff',
        // zoom: 2,
    },
    img: {
        // position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '60%',
        // margin: '12px auto',
        // overflow: 'hidden'
    },
    imgItem: {
        // position: 'relative',
        // width: 'calc((100% - 40px) / 5)',
        width: 'calc((100% - (10px * 3 * 10)) / (4 * 10))',
        height: 0,
        // paddingTop: 'calc(((100% - 40px) / 5) * 0.7)',
        paddingTop: 'calc(((100% - (10px * 3 * 10)) / (4 * 10)) * 0.7)',
        float: 'left',
        // margin: '12px auto',
        // overflow: 'hidden',
        cursor: 'pointer',
        // marginRight: '10px',
        // 'background-color': 'rgba(0, 0, 0, .5)',
        // 'background-blend-mode': 'overlay',
        '&.active': {
            // 'background-blend-mode': 'inherit'
            'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        },
        '&:hover': {
            // 'background-blend-mode': 'inherit'
            'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        },
        // '&:not(:nth-child(5n))': {
        //     marginRight: '10px'
        // }
        '&:not(:last-of-type)': {
            marginRight: '10px'
        }
    },
    photoList: {
        width: '1000% !important',
        marginTop: '10px',
        // height: `calc((((100% - (10px * 4)) * 10) / (${photos.length} * 10)) * 0.7)`,
        transition: 'margin-left 500ms'
    },
    row: {
        width: '100%',
        overflow: 'hidden',
        display: 'block',
        position: 'relative',
        marginBottom: '10px'
    },
    uploadedVideo: {
        'object-fit': 'cover',
        'object-position': 'center',
        position: 'absolute',
        top: 0, //'calc(-103px - 50px)',
        left: 0,
        width: '100%',
        height: '100%',
        'z-index': 0
    },
    videoSlider: {
        width: '100vw',
        height: 0,
        'padding-top': '60vw',
        position: 'relative',
        margin: '0 auto',
        overflow: 'hidden',
        [theme.breakpoints.up(600)]: {
            width: '50vw',
            'padding-top': 'calc(60vw / 2)',
        },
        '& $gridList': {
            position: 'absolute',
            top: 0,
            left: 0,
            'margin-top': '0 !important',
            '& $gridListTile': {
                width: '100vw !important',
                margin: 0,
                [theme.breakpoints.up(600)]: {
                    width: '50vw !important'
                },
                '& $titleBarImage': {
                    height: '100%',
                    paddingTop: 'inherit'
                }
            }
        }
    }
});
  
class PhotoGallery extends Component {
    state = {
        sliderOffset: 0,
        selectedImage: 0,
        offset: 0,
        viewMode: 'photo'
    };
    
    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if(this.props.key !== prevProps.key) {
            this.setState({
                sliderOffset: 0,
                selectedImage: 0,
                offset: 0,
                viewMode: 'photo'
            });
        }
    }

    render() {
        const { classes, photos, videos } = this.props;
        const { selectedImage, offset, sliderOffset, viewMode } = this.state;

        return (
            <div className={classes.galleryRoot}>
                <div className={classes.slider} style={{backgroundColor: viewMode === 'video' ? '#1E1E1E' : '#FFF'}}>
                    {/* {sliderOffset > 0 && (
                        <IconButton className={classes.prevButton} onClick={this.handleSliderPrev}>
                            <Icon>chevron_left</Icon>
                        </IconButton>
                    )} */}
                    {(() => {
                        switch (viewMode) {
                            case 'photo':
                                return (
                                    <GridList className={classes.gridList} cols={2} style={{marginLeft: `calc(-1 * (((100vw - 4px) / 2) + 4px) * ${sliderOffset})`}}>
                                        {photos.map((img, index) => (
                                            <GridListTile key={`${img._id || img}-${index}`} className={classes.gridListTile}>
                                                <div style={{backgroundImage: `url(${restEndpoint}${img.path})`}} className={classNames(classes.titleBarImage, 'cover')}></div>
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                );
                                break;
                            case 'video':
                                return (
                                    <div className={classes.videoSlider}>
                                        {/* <GridList className={classes.gridList} cols={1} style={{marginLeft: `calc(-1 * 50vw * ${sliderOffset})`}}>
                                            {videos.map((video, index) => (
                                                // <GridListTile key={`${video._id || video}-${index}`} className={classes.gridListTile}>
                                                //     <div className={classNames(classes.titleBarImage, 'cover')}>
                                                //         <video className={classNames(classes.uploadedVideo, 'video-overlay')}>
                                                //             <source src={`${restEndpoint}${video.path}#t=1`} type={video.mimeType} />
                                                //         </video>
                                                //     </div>
                                                // </GridListTile>
                                                <GridListTile key={`${video._id || video}-${index}`} className={classes.gridListTile}>
                                                    <video className={classes.titleBarImage} controls>
                                                        <source src={`${restEndpoint}${video.path}#t=1`} type={video.mimeType} />
                                                    </video>
                                                </GridListTile>
                                            ))}
                                        </GridList> */}
                                        <GridList className={classes.gridList} cols={1}>
                                            <GridListTile className={classes.gridListTile}>
                                                {!!videos[selectedImage] && (
                                                    <video key={videos[selectedImage]._id} className={classes.titleBarImage} controls>
                                                        <source src={`${restEndpoint}${videos[selectedImage].path}#t=1`} type={videos[selectedImage].mimeType} />
                                                    </video>
                                                )}
                                            </GridListTile>
                                        </GridList>
                                    </div>
                                );
                                break;
                        
                            default:
                                return null;
                                break;
                        }
                    })()}
                    {/* {sliderOffset < photos.length - 2 && (
                        <IconButton className={classes.nextButton} onClick={this.handleSliderNext}>
                            <Icon>chevron_right</Icon>
                        </IconButton>
                    )} */}
                    {/* <div className={classes.viewMode}>
                        <Icon className={classNames(classes.viewModeButton, viewMode === 'photo' && 'active')} onClick={this.handleChangeViewMode('photo')}>photo</Icon>
                        {videos && videos.length > 0 ? (
                            <Icon className={classNames(classes.viewModeButton, viewMode === 'video' && 'active')} onClick={this.handleChangeViewMode('video')}>videocam</Icon>
                        ) : (
                            <Icon className={classNames(classes.viewModeButton, 'disabled')}>videocam</Icon>
                        )}
                        <Icon className={classNames(classes.viewModeButton, 'disabled')}>3d_rotation</Icon>
                        <Icon className={classNames(classes.viewModeButton, 'disabled')}>explore</Icon>
                    </div> */}
                </div>
                <div className={classes.smallSlider}>
                    {offset > 0 && (
                        <IconButton className={classes.smallPrevButton} onClick={this.handleBack}>
                            <Icon>chevron_left</Icon>
                        </IconButton>
                    )}
                    <div className={classes.insideSmallSlider}>
                        <div className={classNames(classes.photoList, classes.row)} style={{marginLeft: `calc(-1 * (((100% - (10px * 3)) / 4) + 10px) * ${offset})`}}>
                            {(() => {
                                switch (viewMode) {
                                    case 'photo':
                                        return (
                                            photos.map((photo, index) => (
                                                <div
                                                    key={`item-${photo._id || photo}-${index}`}
                                                    className={classNames(classes.imgItem, 'cover', index === selectedImage && 'active')}
                                                    style={{backgroundImage: `url(${restEndpoint}${photo.path})`}}
                                                    onClick={() => this.handleIndexChange(index)}
                                                ></div>
                                            ))
                                        );
                                        break;
                                    case 'video':
                                        return (
                                            videos.map((video, index) => (
                                                <div
                                                    key={`item-${video._id || video}-${index}`}
                                                    className={classNames(classes.imgItem, 'cover', index === selectedImage && 'active')}
                                                    // style={{backgroundImage: `url(${restEndpoint}${video.path})`}}
                                                    onClick={() => this.handleIndexChange(index)}
                                                >
                                                    <video className={classNames(classes.uploadedVideo, 'video-overlay')}>
                                                        <source src={`${restEndpoint}${video.path}#t=1`} type={video.mimeType} />
                                                    </video>
                                                </div>
                                            ))
                                        );
                                        break;
                                
                                    default:
                                        break;
                                }
                            })()}
                        </div>
                    </div>
                    <div className={classes.viewMode}>
                        <Icon className={classNames(classes.viewModeButton, viewMode === 'photo' && 'active')} onClick={this.handleChangeViewMode('photo')}>photo</Icon>
                        {videos && videos.length > 0 ? (
                            <Icon className={classNames(classes.viewModeButton, viewMode === 'video' && 'active')} onClick={this.handleChangeViewMode('video')}>videocam</Icon>
                        ) : (
                            <Icon className={classNames(classes.viewModeButton, 'disabled')}>videocam</Icon>
                        )}
                        {/* <Icon className={classNames(classes.viewModeButton, 'disabled')}>3d_rotation</Icon>
                        <Icon className={classNames(classes.viewModeButton, 'disabled')}>explore</Icon> */}
                    </div>
                    {offset < photos.length - 4 && (
                        <IconButton className={classes.smallNextButton} onClick={this.handleNext}>
                            <Icon>chevron_right</Icon>
                        </IconButton>
                    )}
                </div>
            </div>
        );
    }

    handleChangeViewMode = viewMode => () => this.setState({
        viewMode,
        sliderOffset: 0,
        selectedImage: 0
    })
    
    handleSliderNext = () => {
        const { photos } = this.props;
        const { sliderOffset } = this.state;
        const newOffset = sliderOffset + 1;
        if(newOffset < photos.length - 1) this.setState({
            sliderOffset: newOffset
        });
    }

    handleSliderPrev = () => {
        const { sliderOffset } = this.state;
        const newOffset = sliderOffset - 1;
        if(newOffset >= 0) this.setState({
            sliderOffset: newOffset
        });
    }

    handleNext = () => {
        const { photos } = this.props;
        const { offset } = this.state;
        const newOffset = offset + 1;
        if(newOffset < photos.length) this.setState({
            offset: newOffset
        });
    }
    
    handleBack = () => {
        const { offset } = this.state;
        const newOffset = offset - 1;
        if(newOffset >= 0) this.setState({
            offset: newOffset
        });
    }
    
    handleIndexChange = offset => {
        const { photos } = this.props;
        this.setState({
            sliderOffset: offset > photos.length - 2 ? photos.length - 2 : offset,
            selectedImage: offset
        });
    }

}
PhotoGallery.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
export default withStyles(styles, { withTheme: true })(PhotoGallery);