import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import classNames from "classnames";
import { browserHistory } from 'react-router';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import yellow from '@material-ui/core/colors/yellow';
import Parallax from 'components/parallax';
import Footer from 'components/footer';
import Pagination from 'common/pagination';
import Hero from './sections/hero';
import { container } from 'assets/jss/theme.global';
import s from './style.scss';
import { request } from 'lib/http-services';
const { toHyphens } = require('lib/helper');
import { multilingual } from '@hoc/multilingual';
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
const noPhotoImage = require('assets/img/no-photo.jpg');

const theme = createMuiTheme({
    palette: {
        primary: {
            // light: yellow[400],
            main: yellow[500],
            // dark: yellow[600],
        },
    },
    typography: {
        useNextVariants: true,
    },
});

const styles = theme => ({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2,
      padding: '0 !important',
      position: 'relative'
    },
    paper: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        borderRadius: 0
    },
    overlayContainer: {
        // ...container,
        alignItems: 'center',
        margin: '0 auto',
    },
    mainContent: {
        margin: 0,
        'background-color': '#fff',
        margin: 0,
        // 'padding': '24px',
        '&>.container': {
            ...container,
            alignItems: 'center',
        }
    },
    loadMoreButton: {
        backgroundColor: '#2a2a2a',
        color: '#fff',
        margin: '2rem auto',
        'border-radius': 0
    },
    tileDescription: {
        display: '-webkit-box',
        'max-width': '100%',
        'max-height': 'calc(1.4em * 3)',
        margin: '0 auto 1em',
        'line-height': 1.4,
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'normal',
        'text-align': 'left'
    },
    featureGridContainer: {
        ...container,
        // maxWidth: '100% !important',
        alignItems: 'center',
        margin: '24px auto 0',
        // padding: '24px',
        padding: '0 !important'
    },
    icon: {
        '&.left': {
            'margin-right': '1rem'
        },
        '&.right': {
            'margin-left': '1rem'
        },
        '&.small': {
            'font-size': '1rem',
            color: '#565656',
        },
        '&+span': {
            color: '#565656'
        }
    },
    feature: {
        // 'margin-bottom': '54px',
        position: 'relative',
        '&>$card': {
            cursor: 'pointer'
        },
        '&:not(:last-child)': {
            'margin-bottom': '54px',
        }
    },
    featureLabel: {
        position: 'absolute',
        'text-transform': 'uppercase',
        'background-color': '#FFED38',
        color: '#4B4B4B',
        padding: '0.5rem 0',
        top: 0,
        right: '20px',
        transform: 'translateY(-1.5rem)',
        width: '55px',
        height: '56px',
        // 'line-height': '1.5rem',
        'line-height': '1.2rem',
        'font-size': '.9rem',
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'font-weight': 'bold',
        // '&.sale': {
        //     'background-color': '#FFED38',
        // },
        // '&.lease': {
        //     'background-color': '#F38D00',
        // }
    },
    card: {}
});

@multilingual
class NewsFeed extends Component {
    state = {
        pagination: {
            page: 1,
            limit: 9
        },
        posts: []
    }
    
    componentDidMount() {
        loadCSS(
            'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
            document.querySelector('#insertion-point-jss'),
        );
        // const data = JSON.parse(window.localStorage.data);
        // this.setState({
        //     news: data.news
        // });
        // this.fetchData()
        this.fetchData({ ...this.state.pagination, ...(this.props.location.query || {}) });
    }

    shouldComponentUpdate() {
        return true;
    }

    componentDidUpdate(prevProps) {
        if(this.props.location.query !== prevProps.location.query) {
            this.fetchData({ ...this.state.pagination, ...this.props.location.query });
        }
    }

    render() {
        const { classes, dictionary } = this.props;
        const { posts, pagination } = this.state;
        let tiles = [[], [], []];
        let i = 0;
        posts.forEach(tile => {
            tiles[i].push(tile);
            i = (i + 1) % tiles.length;
        });
        
        return (
            <React.Fragment>
                <MuiThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <Parallax image={require('assets/img/villa/villa1.jpg')} />
                        <Hero dictionary={dictionary} title={this.props.location.query.category === '8' ? dictionary['Event'] : dictionary['News']} />
                        <div className={classNames(classes.mainContent, s.post)}>
                            <div className={classes.featureGridContainer}>
                                <Grid container spacing={24}>
                                    {tiles.map((tileList, index) => (
                                        <Grid key={index} item xs={12} md={4}>
                                            {tileList.map(tile => {
                                                let d = new Date(tile.startDate || tile.createdAt);
                                                return (
                                                    // <Grid key={tile.key} item xs={12} md={4} className="feature">
                                                    <div key={tile._id} className={classes.feature}>
                                                        <Card className={classNames(classes.card, s.newsFeed, 'card', 'hoverable')} onClick={() => this.showDetail(tile)}>
                                                            <CardMedia
                                                                className={s.mainImage}
                                                                image={!!tile.photo ? `${restEndpoint}${tile.photo.path}` : noPhotoImage}
                                                                title={tile.title}
                                                            />
                                                            <CardActions className={s.bgSecondary}>
                                                                <Icon className={classes.icon}>remove_red_eye</Icon>
                                                                <Typography>
                                                                    {/* {tile.views} */}
                                                                    0
                                                                </Typography>
                                                                {/* <Icon className={classes.icon}>comment</Icon>
                                                                <Typography>
                                                                    {tile.comments}
                                                                </Typography> */}
                                                            </CardActions>
                                                            <CardContent className={classes.content}>
                                                                {/* <Typography gutterBottom variant="h5" component="h2" className="truncate"> */}
                                                                <Typography gutterBottom variant="h6" component="h2" className="truncate" align="left">
                                                                    {tile.title}
                                                                </Typography>
                                                                <Typography component="p" className={classes.tileDescription}>
                                                                    {tile.description}
                                                                </Typography>
                                                                <Typography component="p" align="left">
                                                                    <Button variant="contained" color="primary" className={s.detailButton} onClick={() => this.showDetail(tile)}>
                                                                        {dictionary['Detail'] || ''}
                                                                    </Button>
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                        <div className={classes.featureLabel}>{`${d.getDate()}/${d.getMonth() + 1}\n${d.getFullYear()}`}</div>
                                                    {/* </Grid> */}
                                                    </div>
                                                );
                                            })}
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                            {pagination.total > 0 && pagination.pages > 1 && (
                                <Pagination
                                    className="border-top-0"
                                    onRef={p => this.pagination = p}
                                    {...pagination}
                                    onChange={this.fetchData}
                                    location={this.props.location}
                                    pathname={this.props.location.pathname}
                                />
                            )}
                            {/* <div className={s.pagination}>
                                <IconButton className={classes.button} aria-label="Next page">
                                    <Icon>chevron_left</Icon>
                                </IconButton>
                                <IconButton className={classNames(classes.button, s.page)} aria-label="Next page">
                                    1
                                </IconButton>
                                <IconButton className={classNames(classes.button, s.page)} aria-label="Next page">
                                    2
                                </IconButton>
                                <IconButton className={classNames(classes.button, s.page)} aria-label="Next page">
                                    3
                                </IconButton>
                                <IconButton className={classes.button} aria-label="Next page">
                                    <Icon>chevron_right</Icon>
                                </IconButton>
                            </div> */}
                        </div>
                    </div>
                </MuiThemeProvider>
                <Footer dictionary={dictionary} />
            </React.Fragment>
        );
    }

    fetchData = (queries) => {
        const { page, limit } = this.state.pagination;
        const q = {
            page,
            limit,
            ...queries
        };
        request(`/news${!!q && Object.keys(q).length > 0 ? '?' + Object.keys(q).map(k => `${k}=${q[k]}`).join('&') : ''}`, {
            headers: {
                'language': localStorage.getItem('lang'),
                module: 'news-cms'
            }
        })
        // .then(res => res.json().then(data => ({
        //     status: res.status,
        //     body: data
        // })))
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else this.setState({
                    posts: res.body.result,
                    pagination: res.body.meta || {}
                });
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    showDetail = ({ _id, title, alias }) => {
        browserHistory.push(`/news/${alias || toHyphens(title)}-${_id}`);
    }
    
}

// NewsFeed.propTypes = {

// };

export default withStyles(styles)(NewsFeed);