import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
// import Avatar from '@material-ui/core/Avatar';
import { container } from 'assets/jss/theme.global';
// import { Divider } from '@material-ui/core';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
import { multilingual } from '@hoc/multilingual';
const { currencyFormating, toHyphens } = require('lib/helper');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;

const icons = {
    numOfBedrooms: 'airline_seat_individual_suite',
    numOfBathrooms: 'airline_seat_legroom_normal',
    floorArea: 'photo_size_select_small',
    bedroom: 'airline_seat_individual_suite',
    toilet: 'airline_seat_legroom_normal',
    area: 'photo_size_select_small'
}

const styles = theme => ({
    root: {
        width: '100%',
        margin: '0 auto',
        // padding: '24px 0 0',
    },
    container: {
        ...container,
        margin: 'inherit',
        width: '100%',
        padding: '10px !important'
    },
    flexContent: {
        display: 'flex'
    },
    media: {
        height: 0,
        paddingTop: '75%'//'56.25%'
    },
    actions: {
        // display: 'block',
        // overflow: 'hidden',
        // padding: '1rem 20px',
        borderTop: '1px solid #ddd',
        display: 'flex',
        '& .truncate': {
            display: '-webkit-box',
            overflow: 'hidden',
            'line-height': '1.4',
            'white-space': 'normal',
            'text-overflow': 'ellipsis',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            'text-align': 'left'
        }
    },
    actionButton: {
        // margin: 0,
        // paddingLeft: 0,
        // paddingRight: 0
    },
    // address: {
    //     float: 'left',
    //     textAlign: 'left'
    // },
    address: {
        width: '100%'
    },
    price: {
        // float: 'right',
        textAlign: 'right',
        marginLeft: 'auto',
        color: '#FF8F35',
        fontWeight: 'bold',
        // width: '80px',
        // width: '240px',
        width: '150px'
    },
    buttonIcon: {
        '&.left': {
            marginRight: '0.4rem'
        },
        '&.right': {
            marginLeft: '0.4rem'
        }
    },
    loadMoreButton: {
        backgroundColor: '#2a2a2a',
        color: '#fff',
        margin: '2rem auto',
        'border-radius': 0
    },
    tileDescription: {
        display: '-webkit-box',
        'max-width': '100%',
        'max-height': 'calc(1.4em * 3)',
        margin: '0 auto',
        'line-height': '1.4em',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'normal',
        '& p': {
            margin: 0
        }
    },
    featureGridContainer: {
        ...container,
        // maxWidth: '100% !important',
        alignItems: 'center',
        // margin: '24px auto 0',
        margin: '0 auto',
        // padding: '24px',
        maxWidth: 'calc(100% - 15px * 2)'
    },
    row: {
        width: '100%'
    },
    card: {
        'border-radius': 0,
        // '&:hover': {
        //     'box-shadow': '0 8px 16px 0 rgba(0,0,0,0.2)'
        // }
    },
    feature: {
        // 'margin-bottom': '54px',
        position: 'relative',
        '&>$card': {
            cursor: 'pointer'
        },
        '&:not(:last-child)': {
            'margin-bottom': '54px',
        }
    },
    featureLabel: {
        position: 'absolute',
        'text-transform': 'uppercase',
        'background-color': '#FFED38',
        color: '#4B4B4B',
        padding: '0.5rem 0',
        top: 0,
        right: '20px',
        transform: 'translateY(-1.5rem)',
        width: '55px',
        height: '56px',
        'line-height': '1.5rem',
        'font-size': '.9rem',
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        '&.sale': {
            'background-color': '#FFED38',
        },
        '&.lease': {
            'background-color': '#F38D00',
        }
    },
    content: {
        textAlign: 'left',
        // '&:not(first-child)': {
        //     borderTop: '1px solid #ddd'
        // }
        borderTop: '1px solid #ddd'
    },
    tileDescription: {
        display: '-webkit-box',
        'max-width': '100%',
        'max-height': 'calc(1.4em * 3)',
        margin: '0 auto',
        'line-height': 1.4,
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'normal'
    },
    loadMoreButton: {
        backgroundColor: '#2a2a2a',
        color: '#fff',
        margin: '2rem auto',
        'border-radius': 0
    },
    media: {
        height: 0,
        paddingTop: '75%'//'56.25%'
    },
    // price: {
    //     // float: 'right',
    //     // textAlign: 'right'
    //     marginLeft: 'auto',
    //     color: '#FF8F35',
    //     fontWeight: 'bold'
    // },
    flexRow: {
        // display: 'block',
        // overflow: 'hidden',
        // padding: '1rem 20px',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
    },
    actions: {
        // display: 'block',
        // overflow: 'hidden',
        // padding: '1rem 20px',
        borderTop: '1px solid #ddd',
        display: 'flex'
    },
    button: {
        borderRadius: 0,
        width: '100px',
        '&>span': {
            width: '100%'
        },
        '&>span>span': {
            // position: 'absolute',
            // left: '24px',
            'font-size': '1rem',
            'margin-right': '5px'
        },
        '&.secondary': {
            marginTop: '10px'
        },
        '&.red': {
            backgroundColor: '#FF0000',
            color: '#fff'
        },
        '&.yellow': {
            backgroundColor: '#FFED38',
            color: '#f00'
        },
        '&.default': {
            backgroundColor: '#ABABAB',
            color: '#fff'
        }
    },
    fullWidthRow: {
        // width: 'calc(100vw - 14px - 8rem)',
        width: 'calc(100vw - 3rem * 2 - 16px * 2)',
        'margin-left': '50%',
        transform: 'translateX(-50%)',
        'text-align': 'left',
        // padding: '2rem 4rem',
        padding: '1rem 3rem'
    },
    summarySpacing: {
        lineHeight: '3rem',
        'font-size': '1.3rem',
        'text-align': 'left',
        padding: '10px'
    },
    card: {
        'border-radius': 0,
        cursor: 'pointer'
        // '&:hover': {
        //     'box-shadow': '0 8px 16px 0 rgba(0,0,0,0.2)'
        // }
    },
    feature: {
        // 'margin-bottom': '54px',
        position: 'relative',
        '&>$card': {
            cursor: 'pointer'
        },
        '&:not(:last-child)': {
            'margin-bottom': '54px',
        }
    },
    featureLabel: {
        position: 'absolute',
        'text-transform': 'uppercase',
        'background-color': '#FFED38',
        color: '#4B4B4B',
        padding: '0.5rem 0',
        top: 0,
        right: '20px',
        transform: 'translateY(-1.5rem)',
        width: '55px',
        height: '56px',
        'line-height': '1.5rem',
        'font-size': '.9rem',
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        '&.sale': {
            'background-color': '#FFED38',
        },
        '&.lease': {
            'background-color': '#F38D00',
        }
    },
    actionIconButton: {
        padding: '8px'
    }
});

@multilingual
class RelatedRealEstate extends Component {
    
    shouldComponentUpdate() {
        return true;
    }

    render() {
        const { classes, data, dictionary } = this.props;
        return (
            <React.Fragment>
                <div className={classes.root}>
                    <Typography variant="h5" component="h2" className={classes.summarySpacing}>
                        {dictionary['Related property']}
                    </Typography>
                    <Grid container className={classes.container} spacing={16}>
                        {data.slice(0, 3).map((tile, index) => (
                            <Grid key={index} item xs={12} md={4}>
                                <Card className={classNames(classes.card, 'card', 'hoverable')} onClick={() => this.showDetail(tile)}>
                                    <CardMedia
                                        className={classes.media}
                                        image={tile.photos && tile.photos.length > 0 ? `${restEndpoint}${tile.photos[0].path}` : require('assets/img/no-photo.jpg')}
                                        title={tile.title}
                                    />
                                    {tile.numOfBedrooms && tile.numOfBathrooms && tile.floorArea ? (
                                        <CardActions className={classes.actions}>
                                            {tile.numOfBedrooms && (
                                                <React.Fragment>
                                                    <IconButton className={classes.actionIconButton}>
                                                        <Icon>{icons['numOfBedrooms']}</Icon>
                                                    </IconButton>
                                                    <Typography>
                                                        {tile.numOfBedrooms}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                            {tile.numOfBathrooms && (
                                                <React.Fragment>
                                                    <IconButton className={classes.actionIconButton}>
                                                        <Icon>{icons['numOfBathrooms']}</Icon>
                                                    </IconButton>
                                                    <Typography>
                                                        {tile.numOfBathrooms}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                            {tile.floorArea && (
                                                <React.Fragment>
                                                    <IconButton className={classes.actionIconButton}>
                                                        <Icon>{icons['floorArea']}</Icon>
                                                    </IconButton>
                                                    <Typography>
                                                        {tile.floorArea.value} {tile.floorArea.unit.title}
                                                    </Typography>
                                                </React.Fragment>
                                            )}
                                        </CardActions>
                                    ) : null}
                                    <CardContent className={classes.content}>
                                        {/* <Typography gutterBottom variant="h5" component="h2" className="truncate"> */}
                                        <Typography gutterBottom variant="h5" component="h2" className="truncate">
                                            {tile.title}
                                        </Typography>
                                        {/* <Typography component="p" className={classNames(classes.tileDescription, 'truncate')}> */}
                                        <Typography component="div" className={classes.tileDescription}>{tile.shortDescription || ''}</Typography>
                                    </CardContent>
                                    <CardActions className={classes.actions}>
                                        <IconButton className={classes.actionIconButton}>
                                            <Icon>location_on</Icon>
                                        </IconButton>
                                        <Typography className={classNames(classes.address, 'truncate')}>
                                            <span>{tile.location ? this.getFormattedAddress(tile.location) : ''}</span>
                                        </Typography>
                                        <Typography className={classes.price}>
                                            {tile.price ? `${tile.price.value.toLocaleString('en')} ${tile.price.currency.name}${tile.group && tile.group._id === 'rent' ? ` / ${tile.price.unit.title}` : ''}` : dictionary['Contact']}
                                        </Typography>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                {/* <div className={classes.row}>
                    <Button variant="contained" size="large" className={classes.loadMoreButton}>
                        Xem thêm
                    </Button>
                </div> */}
            </React.Fragment>
        );
    }

    getFormattedAddress = location => {
        let strs = []
        // if(location.address) strs.push(location.address)
        if(location.district) strs.push(location.district.name)
        if(location.province) strs.push(location.province.name)
        return strs.join(', ')
    }

    showDetail = ({ _id, title }) => {
        browserHistory.push(`/real-estate/${toHyphens(title)}-${_id}`);
    }
    
}

export default withStyles(styles)(RelatedRealEstate);