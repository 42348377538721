import React, { Component } from "react";
import { Link, browserHistory } from "react-router";
import {
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { multilingualProvider, multilingual } from "@hoc/multilingual";
import Fab from "@material-ui/core/Fab";
import logo from "assets/img/logo.png";
import Header from "components/header";
import headerStyle from "components/header/style.js";
import Modal from "common/modal";
import Toast from "common/toast";
import layoutStyle from "./style.js";
import ContactOverlay from "./partials/contact-overlay";
import "rc-slider/assets/index.css";
import { request } from "lib/http-services";
var $ = window.$;

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: yellow[400],
      main: "#FFE850",
      // dark: yellow[600],
    },
  },
  typography: {
    useNextVariants: true,
  },
});

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

const styles = (theme) => ({
  ...layoutStyle,
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: "0 !important", //theme.spacing.unit * 2,
    textAlign: "center",
  },
  ...headerStyle,
  topNavItem: {
    ...headerStyle.topNavItem,
    "font-weight": "inherit",
  },
  logo: {
    //   filter: 'grayscale(0.2)',
    marginRight: "2rem",
    width: "83px",
    height: "83px",
  },
  logoLink: {
    height: "83px",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  withoutLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  textField: {
    flexBasis: 200,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  languageSelectWrapper: {
    position: "absolute",
    top: "5px",
    right: "15px",
    width: "auto",
  },
  languageSelect: {
    float: "left",
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
    "& fieldset": {
      borderColor: "#ffe850 !important",
      "&+div>div": {
        padding: "8px 25px 8px 10px",
      },
    },
    "& *": {
      color: "#ddd",
      fontSize: "0.75rem",
    },
    "& svg": {
      top: "50%",
      transform: "translate(-60%,-50%)",
    },
    "& *:focus": {
      backgroundColor: "transparent",
    },
  },
  languageSelectInside: {
    color: "#ddd",
    fontSize: "0.875rem",
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
    "& fieldset": {
      borderColor: "#ffe850 !important",
    },
    "& *:focus": {
      backgroundColor: "transparent",
    },
  },
  rightLink: {
    borderRadius: 0,
    textTransform: "inherit",
    "&>span": {
      "z-index": 0,
    },
  },
  // topMenu: {
  //     marginTop: '0.7rem'
  // },
  mainContent: {
    // 'minHeight': 'calc(100vh - 218px)',
    // 'minHeight': 'calc(100vh - 51px - 103px)',
    minHeight: "calc(100vh - (83px + 0.625rem))",
    display: "flex",
    "flex-direction": "column",
    "justify-content": "space-between",
    "&>div:first-child": {
      minHeight: "calc(100vh - (83px + 0.625rem) - 120px)",
    },
  },
  sideNav: {
    width: "250px",
  },
  sideNavItem: {
    width: "100%",
  },
  searchButton: {
    float: "right",
    backgroundColor: "#FFED38",
    color: "rgba(0, 0, 0, 0.87)",
    "text-transform": "inherit",
    "font-weight": "inherit",
    "margin-right": "8px",
    width: "140px",
    "&>span>span": {
      "margin-left": "5px",
    },
    "&.side-nav-item": {
      // width: 'calc(260px - 16px * 2)',
      width: "155px",
      margin: "0 16px",
      float: "left",
      "margin-left": "calc(260px /2)",
      transform: "translateX(-50%)",
      "margin-top": "1rem",
      "&>span": {
        "margin-left": "1rem",
      },
    },
  },
  realEstateSearchWrapper: {
    position: "relative",
    // width: '100vw',
    width: "100%",
    // height: '100vh',
    height: "100%",
    "&>.btn-close": {
      position: "absolute",
      // top: 'calc(2rem - 24px)',
      top: "24px",
      right: "24px",
    },
  },
  popupModal: {
    "max-width": "calc(100vw - 2rem)",
    width: "calc(100vw - 2rem)",
    "max-height": "50vh",
    // overflow: 'hidden',
    [theme.breakpoints.up("sm")]: {
      "max-width": "inherit",
      // width: '50vw',
      "max-height": "calc(100vh - 2rem)",
    },
    "& img": {
      "max-width": "100%",
      "object-fit": "contain",
      width: "100%",
      height: "100%",
    },
  },
  popupModalContent: {
    padding: 0,
  },
  popupPaper: {
    width: "100%",
    height: "100%",
    "background-color": "transparent",
    "border-radius": 0,
  },
  topNavRoot: {
    "&>div": {
      display: "flex",
      "flex-direction": "row",
      "align-items": "flex-end",
      "justify-content": "space-between",
    },
  },
  popoverHeaderSubMenu: {
    pointerEvents: "none",
  },
  paddedPaper: {
    padding: theme.spacing.unit,
  },
  parentNavItem: {
    cursor: "default",
    "&:hover": {
      "&::after": {
        content: '""',
        width: "16px",
        height: "16px",
        position: "absolute",
        bottom: "-13px",
        left: "15px",
        "background-color": "#fff",
        transform: "rotate(45deg)",
        "z-index": -1,
      },
      "& $headerSubmenu": {
        display: "inherit",
      },
    },
  },
  headerSubmenu: {
    position: "absolute",
    top: "100%",
    left: 0,
    "background-color": "#fff",
    display: "none",
    "& *": {
      "font-size": "0.8rem",
      "text-transform": "none",
    },
    "& $subNavItem": {
      width: "200px",
    },
  },
  subNavItem: {},
  fabContactUs: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    "z-index": 999,
    cursor: "pointer",
    color: "inherit",
    "text-decoration": "none",
    "&:hover": {
      "& button": {
        "background-color": "rgb(178, 162, 56)",
      },
      "& $fabHotline": {
        "background-color": "rgb(178, 162, 56)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      right: "50%",
      transform: "translateX(50%)",
    },
  },
  fabHotline: {
    "background-color": "#FFE850",
    "border-radius": "0 20px 20px 0",
    "margin-left": "-1rem",
    padding: "10px 10px 10px calc(1rem + 10px)",
    "box-shadow":
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
});

@multilingualProvider
@multilingual
class Layout extends Component {
  state = {
    locales: [
      {
        value: "vi",
        label: "Tiếng Việt",
      },
      {
        value: "en",
        label: "English",
      },
      {
        value: "zh",
        label: "中国",
      },
    ],
    language: localStorage.getItem("lang"),
    isRealEstateSearchFormShow: false,
    contactOverlayShow: false,
    dictionary: {},
    // lastUpdate: Date.now()
    menu: [],
    headerSubmenu: [],
    popups: [],
    randomPopup: null,
    popupOpened: false,
    popupShowed: false,
    headerSubMenuAnchorEl: null,
    companyProfile: {},
  };
  headerId = `sticky-header-${Date.now()}`;

  componentDidMount() {
    this.getCompanyProfile();
    this.getMenu();
    const popupSeen = JSON.parse(
      window.sessionStorage.getItem("popup") || "{}"
    );
    if (!popupSeen[window.location.pathname]) this.getPopups();
  }
  shouldComponentUpdate() {
    return true;
  }

  componentWillReceiveProps(nextProps) {
    // if we changed routes...
    if (nextProps.location.pathname !== this.props.location.pathname) {
      // save the old children (just like animation)
      this.headerId = `sticky-header-${Date.now()}`;
    }
    if (nextProps.children !== this.props.children)
      $("html, body").animate({ scrollTop: 0 });
  }

  componentDidUpdate(prevProps, prevState) {
    const popupSeen = JSON.parse(
      window.sessionStorage.getItem("popup") || "{}"
    );
    if (!popupSeen[window.location.pathname]) this.getPopups();
  }

  render() {
    const { children, classes, languages, language, dictionary } = this.props;
    const { menu, randomPopup, popupOpened, companyProfile } = this.state;
    const { headerColor } = children.props.route;
    const contactOverlayShow = "listing" in this.props.location.query;
    const headerMenu = menu.find(
      (m) => m.type._id === "header" && m.language._id === language
    );

    return (
      <MuiThemeProvider theme={theme}>
        <Grid container className={classes.root}>
          <Grid item xs={12} className={classes.paper}>
            <Header
              key={`${this.headerId}-2`}
              menuButton
              sticky
              color={headerColor || "fade-out-grey"}
              className={classNames(
                headerColor || "fade-out-grey",
                "padded",
                classes.topNavRoot
              )}
              changeColorOnScroll={{
                height: 83,
                invertHeight: 83,
                color: "savills-grey",
              }}
              logo={
                <Link className={classes.logoLink} to="/">
                  <img src={logo} alt="Salvills" className={classes.logo} />
                </Link>
              }
              leftLinks={
                <Hidden smDown implementation="css">
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.topNav}
                  >
                    {!!headerMenu &&
                      headerMenu.items
                        .filter((mi) => !mi.parent)
                        .map((mi) => {
                          const headerSubmenuItems = headerMenu.items.filter(
                            (smi) => smi.parent === mi._id
                          );

                          if (headerSubmenuItems.length > 0)
                            return (
                              <span
                                key={mi._id}
                                className={classNames(
                                  classes.topNavItem,
                                  classes.parentNavItem
                                )}
                              >
                                {mi.text}
                                <div className={classes.headerSubmenu}>
                                  <List component="nav">
                                    {headerSubmenuItems.map((smi) => (
                                      <ListItem
                                        key={smi._id}
                                        button
                                        component={Link}
                                        to={smi.url}
                                        className={classes.subNavItem}
                                      >
                                        <ListItemText primary={smi.text} />
                                      </ListItem>
                                    ))}
                                  </List>
                                </div>
                              </span>
                            );
                          return (
                            <Link
                              key={mi._id}
                              className={classes.topNavItem}
                              activeClassName="active"
                              to={mi.url}
                            >
                              {mi.text}
                            </Link>
                          );
                        })}
                    {/* <Link className={classes.topNavItem} activeClassName="active" to="/real-estates?group=sale">{dictionary['Sales']}</Link>
                                        <Link className={classes.topNavItem} activeClassName="active" to="/real-estates?group=rent">{dictionary['Lease']}</Link>
                                        <Link className={classes.topNavItem} activeClassName="active" to="/projects">{dictionary['Project']}</Link>
                                        <Link className={classes.topNavItem} activeClassName="active" to="/news">{dictionary['News']}</Link>
                                        <span className={classes.topNavItem} onClick={this.showContactOverlay}>{dictionary['Contact']}</span> */}
                    {/* <span className={classes.topNavItem} onClick={this.showContactOverlay}>{dictionary['Contact']}</span> */}
                  </Typography>
                </Hidden>
              }
              rightLinks={
                <div
                  className={classNames(
                    classes.topNav,
                    classes.languageSelectWrapper
                  )}
                >
                  <TextField
                    variant="outlined"
                    select
                    className={classNames(
                      classes.margin,
                      classes.textField,
                      classes.languageSelect,
                      "select-invert"
                    )}
                    value={language}
                    onChange={this.handleChangeLanguage}
                  >
                    {languages.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              }
              sideNavLinks={
                <List component="nav">
                  {!!headerMenu &&
                    headerMenu.items
                      .filter((mi) => !mi.parent)
                      .map((mi) => (
                        <ListItem
                          key={mi._id}
                          button
                          component={Link}
                          to={mi.url}
                        >
                          <ListItemText primary={mi.text} />
                        </ListItem>
                      ))}
                </List>
              }
            />
            <div className={classes.mainContent}>{children}</div>
            <Hidden smDown>
              <div
                className={classes.fabContactUs}
                onClick={() => browserHistory.push("/contact")}
              >
                <Fab color="primary">
                  <Icon>phone</Icon>
                </Fab>
                {!!companyProfile && !!companyProfile.hotline && (
                  <span className={classes.fabHotline}>
                    {companyProfile.hotline}
                  </span>
                )}
              </div>
            </Hidden>
            <Hidden mdUp>
              <a
                href={`tel:${companyProfile.tel || ""}`}
                className={classes.fabContactUs}
              >
                <Fab color="primary">
                  <Icon>phone</Icon>
                </Fab>
                {!!companyProfile && !!companyProfile.hotline && (
                  <span className={classes.fabHotline}>
                    {companyProfile.hotline}
                  </span>
                )}
              </a>
            </Hidden>
          </Grid>
        </Grid>
        {/* <Popover
                    className={classes.popoverHeaderSubMenu}
                    classes={{
                        paper: classes.paddedPaper,
                    }}
                    open={Boolean(headerSubMenuAnchorEl) && headerSubmenu.length > 0}
                    anchorEl={headerSubMenuAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    onClose={this.handleCloseHeaderSubMenu}
                    // disableRestoreFocus
                >
                    <List component="nav">
                        {headerSubmenu.map(mi => (
                            <ListItem key={mi._id} button component={Link} to={mi.url}>
                                <ListItemText primary={mi.text} />
                            </ListItem>
                        ))}
                    </List>
                </Popover> */}
        {/* <Dialog
                    fullScreen
                    open={isRealEstateSearchFormShow}
                    onClose={this.hideRealEstateSearchForm}
                    TransitionComponent={Transition}
                >
                    <div className={classes.realEstateSearchWrapper}>
                        <RealEstateSearch dictionary={dictionary} onClose={this.hideRealEstateSearchForm} />
                        <IconButton className="btn-close" aria-label="Close" onClick={this.hideRealEstateSearchForm}>
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                </Dialog> */}
        <Dialog
          fullScreen
          open={contactOverlayShow}
          onClose={this.hideContactOverlay}
          TransitionComponent={Transition}
        >
          <div className={classes.realEstateSearchWrapper}>
            <ContactOverlay
              theme={theme}
              dictionary={dictionary}
              onClose={this.hideContactOverlay}
              location={this.props.location}
              pathname={this.props.location.pathname}
            />
            <IconButton
              className="btn-close"
              aria-label="Close"
              onClick={this.hideContactOverlay}
            >
              <Icon>close</Icon>
            </IconButton>
          </div>
        </Dialog>
        {!!randomPopup && (
          <ClickAwayListener onClickAway={this.handleClosePopup}>
            <Modal
              open={popupOpened}
              onClose={this.handleClosePopup}
              theme={theme}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                ...(randomPopup.size || {}),
              }}
              noAction
            >
              <div
                dangerouslySetInnerHTML={{ __html: randomPopup.content }}
              ></div>
            </Modal>
          </ClickAwayListener>
        )}
        <Toast />
      </MuiThemeProvider>
    );
  }

  getCompanyProfile = () => {
    request("/company-profile")
      .then(async (res) => {
        if (!res.stack) {
          if (res.status >= 400);
          else
            this.setState({
              companyProfile: res.body.result,
            });
          const { location } = res.body.result;
          if (!!this.map && !!location && location.location) {
            this.map.clearMarkers();
            await this.map.addMarker({
              location: {
                lat: location.location.latitude,
                lng: location.location.longitude,
              },
            });
            this.map.getBounds();
            this.map.setZoom(15);
          }
        }
        // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
      })
      .catch((err) => {
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };

  getMenu = () => {
    request("/menu", {
      headers: {
        language: this.props.language,
      },
    })
      .then((res) => {
        if (!res.stack) {
          if (res.status >= 400);
          else {
            if (res.body.result.length > 0)
              this.setState({
                menu: res.body.result,
              });
          }
        }
        // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
      })
      .catch((err) => {
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };

  getPopups = () => {
    if (this.state.popupShowed) return;
    this.setState({
      popupShowed: true,
    });
    request("/pop-ups", {
      params: {
        page: 1,
        limit: 10,
      },
      headers: {
        language: this.props.language,
      },
    })
      .then((res) => {
        if (!res.stack) {
          if (res.status >= 400);
          else {
            const matchedPopup = res.body.result.filter(
              (p) =>
                p.url.split("?")[0] ===
                window.location.origin + window.location.pathname
            );
            // console.log(window.location.pathname)
            if (matchedPopup.length > 0) {
              this.setState(
                {
                  popups: matchedPopup,
                  // randomPopup: res.body.result[getRandomInt(0, res.body.result.length - 1)],
                  randomPopup:
                    matchedPopup[
                      Math.floor(Math.random() * matchedPopup.length)
                    ],
                },
                () =>
                  setTimeout(() => {
                    this.setState({
                      popupOpened: true,
                    });
                    let popupSeen = JSON.parse(
                      window.sessionStorage.getItem("popup") || "{}"
                    );
                    popupSeen[window.location.pathname] = true;
                    window.sessionStorage.setItem(
                      "popup",
                      JSON.stringify(popupSeen)
                    );
                  }, 3000)
              );
            }
          }
        }
        // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
      })
      .catch((err) => {
        // notify({
        //     variant: 'error',
        //     message: err.message
        // });
      });
  };

  handleOpenHeaderSubMenu = (headerSubmenu) => (event) =>
    this.setState({
      headerSubmenu,
      headerSubMenuAnchorEl: event.currentTarget,
    });

  handleCloseHeaderSubMenu = () =>
    this.setState({
      headerSubMenuAnchorEl: null,
      headerSubmenu: [],
    });

  handleClosePopup = () =>
    this.setState({
      popupOpened: false,
      popupShowed: false,
    });

  handleChangeLanguage = (event) => {
    this.setState({ language: event.target.value });
    // localStorage.setItem('lang', event.target.value);
    this.props.changeLanguage(event.target.value);
    this.getMenu();
    // location.reload();
  };

  showRealEstateSearchForm = () =>
    this.setState({
      isRealEstateSearchFormShow: true,
    });

  hideRealEstateSearchForm = () =>
    this.setState({
      isRealEstateSearchFormShow: false,
    });

  showContactOverlay = () =>
    browserHistory.push({
      search: "?listing",
      state: { fallbackPathname: this.props.location.pathname },
    });

  // hideContactOverlay = () => this.setState({
  //     contactOverlayShow: false
  // })

  hideContactOverlay = () =>
    this.props.location.state
      ? browserHistory.replace(this.props.location.state.fallbackPathname)
      : browserHistory && browserHistory.back
      ? browserHistory.back()
      : browserHistory.replace("/");
}

export default withStyles(styles)(Layout);
