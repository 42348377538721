import * as types from './constants';

function notifySuccess(props) {
  return {
    type: types.NOTIFY_SUCCESS,
    payload: {
      ...props,
      open: true
    }
  }
}

function dismissSuccess() {
  return {
    type: types.NOTIFY_SUCCESS,
    payload: {
      variant: 'default',
      message: '',
      open: false
    }
  }
}

export function notify(props) {
  return function(dispatch) {
    dispatch(notifySuccess(props))
  }
}

export function dismiss() {
  return function(dispatch) {
    dispatch(dismissSuccess())
  }
}
