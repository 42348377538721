import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import MenuItem from '@material-ui/core/MenuItem';
// import TextField from '@material-ui/core/TextField';
// import Slider from '@material-ui/lab/Slider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import { multilingual } from '@hoc/multilingual';
import MapLocation from './map-location';
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
// const icons = {
//     'Tòa nhà': 'location_city',
//     'Phòng ngủ': 'airline_seat_individual_suite',
//     'Phòng tắm': 'airline_seat_legroom_normal',
//     'Diện tích': 'photo_size_select_small'
// }

const styles = theme => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
    flex: {
        display: 'flex'
    },
    tabsRoot: {
        // borderBottom: '1px solid #e8e8e8',
        padding: '0 24px'
    },
    tabsIndicator: {
        backgroundColor: '#1890ff',
        display: 'none'
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing.unit * 4,
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: '#F9F9F9',
        color: '#7A7979',
        opacity: 1,
        'border-top': '1px solid #EAEAEA',
        'border-right': '1px solid #EAEAEA',
        'border-left': '1px solid #EAEAEA',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:not(:last-child)': {
            marginRight: '2px'
        },
        '&>span>span>span': {
            // fontSize: '1.5rem',
            fontSize: '1rem'
        },
        // '&:hover': {
        //     // color: '#40a9ff',
        //     // opacity: 1,
        //     fontWeight: 'bold',
        // },
        '&$tabSelected': {
            // color: '#1890ff',
            // fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.background.paper
        },
        // '&:focus': {
        //     color: '#40a9ff',
        // },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
    tabContent: {
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        // width: 200,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        textAlign: 'left',
        paddingLeft: '1rem',
        marginLeft: `calc(${theme.spacing.unit}px - 1rem)`
    },
    searchButton: {
        float: 'right',
        marginRight: '-8px',
        backgroundColor: '#FFED38'
    },
    slider: {
        backgroundColor: '#FF0000'
        // '&>div': {
        //     backgroundColor: '#FF0000'
        // }
    },
    groupBox: {
        textAlign: 'left',
        marginTop: '24px',
        '&>$legend': {
            fontSize: '1.2rem'
        },
        '& $detailLine': {
            'font-size': '1rem',
            'line-height': '2rem',
            'border-bottom': '1px solid #D6D6D6',
            'margin-top': '1rem',
            color: '#7A7979'
        },
        '& $detailDefault': {
            'font-size': '1rem',
            // 'line-height': '2rem',
            'line-height': '1.5rem',
            color: '#7A7979',
            padding: '1rem 0 0',
            '& p': {
                'text-align': 'justify'
            }
        }
    },
    legend: {},
    detailLine: {},
    detailDefault: {},
    photoList: {
        width: '100%',
        // marginTop: '5px'
    },
    imgItem: {
        // position: 'relative',
        width: 'calc((100% - 10px) / 3)',
        height: 0,
        paddingTop: 'calc(((100% - 10px) / 3) * 0.75)',
        float: 'left',
        // margin: '12px auto',
        // overflow: 'hidden',
        cursor: 'pointer',
        // 'background-color': 'rgba(0, 0, 0, .5)',
        // 'background-blend-mode': 'overlay',,
        marginTop: '5px',
        '&.large': {
            width: 'calc((100% - 5px) / 2)',
            paddingTop: 'calc(((100% - 5px) / 2) * 0.75)',
        },
        '&.full-width': {
            width: '100%',
            paddingTop: 'calc(100% * 0.6)'
        },
        // '&.active': {
        //     // 'background-blend-mode': 'inherit'
        //     'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        // },
        // '&:hover': {
        //     // 'background-blend-mode': 'inherit'
        //     'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        // },
        '&:not(:nth-child(2)):not(:nth-child(5))': {
            marginRight: '5px'
        }
    },
    filterButtons: {
        textAlign: 'left'
    },
    button: {
        borderRadius: 0,
        // '&>span': {
        //     marginRight: 'inherit !important'
        // },
        '&.yellow': {
            backgroundColor: '#FFED38'
        }
    },
    filteredButton: {
        '&:not(:last-child)': {
            marginRight: '24px'
        }
    },
    productList: {
        margin: '24px auto',
        '& $product': {
            padding: '24px 0',
            'border-top': '1px solid #D6D6D6',
            overflow: 'hidden'
        }
    },
    product: {
        fontSize: '1rem',
        '&.product-name': {},
        // '&:not(:last-child)'
    },
    productPhoto: {
        width: '150px',
        height: 0,
        paddingTop: '100px',
        marginRight: '24px',
        float: 'left',
        '&+div': {
            // float: 'left',
            overflow: 'hidden'
        }
    },
    detailRow: {
        width: 'calc(100% - 6px)',
        overflow: 'hidden',
        padding: '5px',
        lineHeight: '1.8rem',
        textAlign: 'left',
        '& > span': {
            float: 'left',
            marginRight: '1rem'
        },
        '& .price.text-red': {
            color: '#FF0007',
            fontSize: '1.3rem'
        }
    },
    businessName: {
        'font-size': '1.2rem',
        'font-weight': 'bold',
        color: '#595959'
    },
    projectDetailButton: {
        color: '#7A7979'
    },
    utilList: {
        '& span': {
            'font-size': '.9rem'
        }
    },
    brandLogo: {
        padding: '1rem 1rem 0 0'
    },
    amenities: {
        margin: '1rem auto',
        padding: 0,
        display: '-webkit-box',
        overflow: 'hidden',
        'max-width': '100%',
        'max-height': 'calc(46px + 1rem)',
        'white-space': 'normal',
        'text-overflow': 'ellipsis',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        '&.expanded': {
            '-webkit-line-clamp': 'inherit',
            'max-height': 'inherit'
        },
        '&>p': {
            margin: 0
        }
    },
    description: {
        margin: '1rem auto',
        padding: '0 !important',
        display: '-webkit-box',
        overflow: 'hidden',
        'max-width': '100%',
        'max-height': 'calc(1.5rem * 3)',
        'line-height': '1.5rem',
        'white-space': 'normal',
        'text-overflow': 'ellipsis',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        '&.expanded': {
            '-webkit-line-clamp': 'inherit',
            'max-height': 'inherit'
        },
        '&>p': {
            margin: 0
        }
    },
    expandDescLink: {
        cursor: 'pointer',
        'font-weight': 'bold',
        'text-decoration': 'underline',
    },
    mapLocation: {
        bottom: 0,
        padding: 0,
        margin: 0,
        left: 0,
        width: 'calc(100% + 24px * 2)',
        height: '350px !important',
        transform: 'translate(-24px, 24px)'
    }
});

function TabContainer({ children, className }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }} className={className}>
            {children}
        </Typography>
    );
}

@multilingual
class DetailTabs extends Component {
    state = {
        tab: 0,
        area: 50,
        price: 4,
        amenitiesExpanded: false,
        descriptionExpanded: false,
        projectDescriptionExpanded: false
    }
    
    render() {
        const { className, classes, data, dictionary } = this.props;
        const { tab, amenitiesExpanded, descriptionExpanded, projectDescriptionExpanded } = this.state;
        const { photos } = data || {};
        const amenitiesCols = [[], []];
        data.amenities.forEach((amns, i) => {
            const j = i % 2;
            amenitiesCols[j].push(amns);
        });
        return (
            <div className={classNames(className, classes.root)}>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                >
                    <Tab
                        disableRipple
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label={dictionary['Unit infor']}
                    />
                    {data.project && (
                        <Tab
                            disableRipple
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={dictionary['Project']}
                        />
                    )}
                </Tabs>
                {tab === 0 && (
                    <TabContainer className={classes.tabContent}>
                        <Grid container spacing={24}>
                            <Grid item xs>
                                <div className={classNames(classes.groupBox, 'group-box')}>
                                    <span className={classNames(classes.legend, 'legend')}>{dictionary['Details']}</span>
                                    <Grid container>
                                        {!!data.numOfBedroom && (
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>{dictionary['Bedroom']}: <b>{data.numOfBedroom}</b></span>
                                            </Grid>
                                        )}
                                        {!!data.numOfBathroom && (
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>{dictionary['Bathroom']}: <b>{data.numOfBathroom}</b></span>
                                            </Grid>
                                        )}
                                        {!!data.floorArea && (
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                {/* <span>{dictionary['Unit area']}: <b>{data.floorArea.value} {data.floorArea.unit.title}</b></span> */}
                                                <span>{dictionary['Unit area']}: <b>{data.floorArea.value} m<sup>2</sup></b></span>
                                            </Grid>
                                        )}
                                        {!!data.furniture && (
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>{dictionary['Interior']}: <b>{data.furniture.title || ''}</b></span>
                                            </Grid>
                                        )}
                                        {!!data.uses && (
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>{dictionary['Using purpose']}: <b>{data.uses.title}</b></span>
                                            </Grid>
                                        )}
                                        {!!data.sovereignty && (
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>{dictionary['Ownership']}: <b>{data.sovereignty.title}</b></span>
                                            </Grid>
                                        )}
                                        {!!data.project && (
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>{dictionary['Project']}: <b>{data.project.title}</b></span>
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        {data.amenities && data.amenities.length > 0 && (
                            <Grid container spacing={24}>
                                <Grid item xs>
                                    <div className={classNames(classes.groupBox, 'group-box')}>
                                        <span className={classNames(classes.legend, 'legend')}>{dictionary['Amentity']}</span>
                                        <Grid container>
                                            <Grid item xs={12} className={classNames(classes.detailDefault, classes.amenities, amenitiesExpanded && 'expanded')}>
                                                <Grid container>
                                                    <Grid item xs={12} md={6} className={classes.detailUtil}>
                                                        <List className={classes.utilList}>
                                                            {amenitiesCols[0].map(am => am.title && (
                                                                <ListItem key={am._id} button>
                                                                    <ListItemIcon>
                                                                        <Icon>check</Icon>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={am.title} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Grid>
                                                    <Grid item xs={12} md={6} className={classes.detailUtil}>
                                                        <List className={classes.utilList}>
                                                            {amenitiesCols[1].map(am => am.title && (
                                                                <ListItem key={am._id} button>
                                                                    <ListItemIcon>
                                                                        <Icon>check</Icon>
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={am.title} />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <a className={classes.expandDescLink} onClick={this.toggleCollapseDescription('amenitiesExpanded')}>{amenitiesExpanded ? dictionary['Collapse'] : dictionary['Expand']}</a>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container spacing={24}>
                            <Grid item xs>
                                <div className={classNames(classes.groupBox, 'group-box')}>
                                    <span className={classNames(classes.legend, 'legend')}>Giới thiệu căn hộ</span>
                                    <Grid container>
                                        <Grid item xs={12} className={classNames(classes.detailDefault, classes.description, descriptionExpanded && 'expanded')} dangerouslySetInnerHTML={{__html: data.description}}></Grid>
                                        <Grid item xs={12}>
                                            <a className={classes.expandDescLink} onClick={this.toggleCollapseDescription('descriptionExpanded')}>{descriptionExpanded ? dictionary['Collapse'] : dictionary['Expand']}</a>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <div className={classes.photoList}>
                                                {photos.slice(0, 2).map((photo, index) => (
                                                    <div
                                                        key={`item-${photo._id || photo}-${index}`}
                                                        className={classNames(classes.imgItem, 'cover', 'large')}
                                                        style={{backgroundImage: `url(${restEndpoint}${photo.path})`}}
                                                        // onClick={() => this.handleIndexChange(index)}
                                                    ></div>
                                                ))}
                                                {photos.slice(2, photos.length).map((photo, index) => {
                                                    if(index > 2) return null;
                                                    let moreProps = {};
                                                    let leftItems = photos.length - 5;
                                                    if(index === 2 && leftItems > 0) moreProps['data-title'] = `${leftItems}+`;
                                                    return (
                                                        <div
                                                            key={`item-${photo._id || photo}-${index}`}
                                                            className={classNames(classes.imgItem, 'cover', index === 2 && leftItems > 0 && 'more-items')}
                                                            style={{backgroundImage: `url(${restEndpoint}${photo.path})`}}
                                                            // onClick={() => this.handleIndexChange(index)}
                                                            {...moreProps}
                                                        ></div>
                                                    );
                                                })}
                                            </div>
                                        </Grid> */}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        {/* {!!(data.location && data.location.location) && <MapLocation className={classes.mapLocation} location={{lat: data.location.location.latitude, lng: data.location.location.longitude}} />} */}
                        <MapLocation className={classes.mapLocation} location={!!(data.location && data.location.location) ? {lat: data.location.location.latitude, lng: data.location.location.longitude} : null} />
                    </TabContainer>
                )}
                {tab === 1 && data.project && (
                    <TabContainer className={classes.tabContent}>
                        <Grid container spacing={24}>
                            <Grid item xs>
                                <div className={classNames(classes.groupBox, 'group-box')}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <div className={classes.photoList}>
                                                {data.project.photos && data.project.photos.length > 0 && data.project.photos.slice(0, 1).map((photo, index) => (
                                                    <div
                                                        key={`item-${photo._id || photo}-${index}`}
                                                        className={classNames(classes.imgItem, 'cover', 'full-width')}
                                                        style={{backgroundImage: `url(${restEndpoint}${photo.path})`}}
                                                        // onClick={() => this.handleIndexChange(index)}
                                                    ></div>
                                                ))}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className={classes.detailDefault}>
                                            <span className ={classes.businessName}>{data.project.title}</span>
                                            {/* <div dangerouslySetInnerHTML={{__html: data.project.description}}></div> */}
                                            <div className={classNames(classes.detailDefault, classes.description, projectDescriptionExpanded && 'expanded')} dangerouslySetInnerHTML={{__html: data.project.description}}></div>
                                            <div>
                                                <a className={classes.expandDescLink} onClick={this.toggleCollapseDescription('projectDescriptionExpanded')}>{projectDescriptionExpanded ? dictionary['Collapse'] : dictionary['Expand']}</a>
                                            </div>
                                        </Grid>
                                        <Grid container item xs={12} className={classes.flex} justify="flex-end">
                                            <Button variant="outlined" className={classNames(classes.button, classes.projectDetailButton)} onClick={() => this.showDetail(data.project._id)}>
                                                {dictionary['Details']}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                        {data.project.inveslopers && data.project.inveslopers.length > 0 && (
                            <Grid container spacing={24}>
                                <Grid item xs>
                                    <div className={classNames(classes.groupBox, 'group-box')}>
                                        <span className={classNames(classes.legend, 'legend')}>Chủ đầu tư</span>
                                        {data.project.inveslopers.map(inv => (
                                            <Grid container key={`invesloper-${inv._id}`}>
                                                <Grid item xs={12} md={4} className={classes.brandLogo}>
                                                    <div className={classes.photoList}>
                                                        <div
                                                            className={classNames(classes.imgItem, 'cover', 'full-width')}
                                                            style={{backgroundImage: `url(${inv.photos && inv.photos.length > 0 ? `${restEndpoint}${inv.photos[0].path}` : require('assets/img/no-photo.jpg')})`}}
                                                            // onClick={() => this.handleIndexChange(index)}
                                                        ></div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} md={8} className={classes.detailDefault}>
                                                    <span className={classes.businessName}>{inv.name}</span>
                                                    <div dangerouslySetInnerHTML={{__html: inv.description}}></div>
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </TabContainer>
                )}
            </div>
        );
    }
    
    toggleCollapseDescription = descriptionExpanded => () => {
        this.setState({
            [descriptionExpanded]: !this.state[descriptionExpanded]
        });
    }
    
    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    }

    handleAreaChange = (event, value) => {
        this.setState({ area: value });
    }

    handlePriceChange = (event, value) => {
        this.setState({ price: value });
    }

    showDetail = id => {
        browserHistory.push(`/project/${id}`);
    }
}

export default withStyles(styles)(DetailTabs);