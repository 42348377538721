import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import classNames from "classnames";
// import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import NativeSelect from '@material-ui/core/NativeSelect';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
// import MenuItem from '@material-ui/core/MenuItem';
// import TextField from '@material-ui/core/TextField';
// import Slider from '@material-ui/lab/Slider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
// import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';
import { container } from 'assets/jss/theme.global';
import Pagination from 'common/pagination';
import PhotoGallery from './photo-gallery';
import MapLocation from '../map-location';
import s from './style.scss';
import { request } from 'lib/http-services';
const { currencyFormating, toHyphens } = require('lib/helper');
// const lang = 'vi';
const config = require('config');
const restEndpoint = process.env.REST_ENDPOINT || config.restEndpoint;
;
const noPhotoImage = require('assets/img/no-photo.jpg');

function Transition(props) {
    return <Fade {...props} />;
}

const icons = {
    'Tòa nhà': 'location_city',
    'Phòng ngủ': 'airline_seat_individual_suite',
    'Phòng tắm': 'airline_seat_legroom_normal',
    'Diện tích': 'photo_size_select_small'
}
const propertyGroups = {
    'basic': 'Sơ cấp',
    'sale': 'Thứ cấp',
    'rent': 'Cho thuê'
};

const RenderProductUtilInList = ({data}) => {
    let uts = {
        ...data.utils,
        'Diện tích': data.area
    };
    return Object.keys(uts).map(util => (
        <React.Fragment key={`${data.key}-${util}`}>
            <Icon>{icons[util]}</Icon>
            <span>{uts[util]}</span>
        </React.Fragment>
    ));
};
const RenderProductUtilInMOdal = ({data}) => {
    let utss = {
        ...data.utils,
        'Diện tích': data.area
    };
    return Object.keys(utss).map(util => (
        <Grid container key={util} className="info-row" direction="row" alignItems="center" justify="flex-start">
            <Grid item xs={6}>
                <b>{util}</b>
            </Grid>
            <Grid item xs={6}>
                {utss[util]}
            </Grid>
        </Grid>
    ));
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        // backgroundColor: theme.palette.background.paper,
    },
    tabsRoot: {
        // borderBottom: '1px solid #e8e8e8',
        padding: '0 24px'
    },
    tabsIndicator: {
        backgroundColor: '#1890ff',
        display: 'none'
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing.unit * 4,
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: '#F9F9F9',
        color: '#7A7979',
        opacity: 1,
        'border-top': '1px solid #EAEAEA',
        'border-right': '1px solid #EAEAEA',
        'border-left': '1px solid #EAEAEA',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:not(:last-child)': {
            marginRight: '2px'
        },
        '&>span>span>span': {
            // fontSize: '1.5rem',
            fontSize: '1rem'
        },
        // '&:hover': {
        //     // color: '#40a9ff',
        //     // opacity: 1,
        //     fontWeight: 'bold',
        // },
        '&$tabSelected': {
            // color: '#1890ff',
            // fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.background.paper
        },
        // '&:focus': {
        //     color: '#40a9ff',
        // },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
    tabContent: {
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
    },
    selectEmpty: {
        marginTop: theme.spacing.unit * 2,
    },
    formContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        // width: 200,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        textAlign: 'left',
        paddingLeft: '1rem',
        marginLeft: `calc(${theme.spacing.unit}px - 1rem)`
    },
    searchButton: {
        float: 'right',
        marginRight: '-8px',
        backgroundColor: '#FFED38'
    },
    slider: {
        backgroundColor: '#FF0000'
        // '&>div': {
        //     backgroundColor: '#FF0000'
        // }
    },
    groupBox: {
        textAlign: 'left',
        marginTop: '24px',
        '&>$legend': {
            fontSize: '1.2rem'
        },
        '& $detailLine': {
            'font-size': '1rem',
            'line-height': '2rem',
            'border-bottom': '1px solid #D6D6D6',
            'margin-top': '1rem',
            color: '#7A7979'
        },
        '& $detailDefault': {
            'font-size': '1rem',
            // 'line-height': '2rem',
            'line-height': '1.5rem',
            color: '#7A7979',
            padding: '1rem 0 0',
            '& p': {
                'text-align': 'justify'
            }
        }
    },
    legend: {},
    detailLine: {},
    detailDefault: {},
    photoList: {
        width: '100%',
        // marginTop: '5px'
    },
    imgItem: {
        // position: 'relative',
        width: 'calc((100% - 10px) / 3)',
        height: 0,
        paddingTop: 'calc(((100% - 10px) / 3) * 0.75)',
        float: 'left',
        // margin: '12px auto',
        // overflow: 'hidden',
        cursor: 'pointer',
        // 'background-color': 'rgba(0, 0, 0, .5)',
        // 'background-blend-mode': 'overlay',,
        marginTop: '5px',
        '&.large': {
            width: 'calc((100% - 5px) / 2)',
            paddingTop: 'calc(((100% - 5px) / 2) * 0.75)',
        },
        '&.full-width': {
            width: '100%',
            paddingTop: 'calc(100% * 0.6)'
        },
        // '&.active': {
        //     // 'background-blend-mode': 'inherit'
        //     'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        // },
        // '&:hover': {
        //     // 'background-blend-mode': 'inherit'
        //     'box-shadow': 'inset 0 0 50px 25px rgba(0, 0, 0, 0.7)'
        // },
        '&:not(:nth-child(2)):not(:nth-child(5))': {
            marginRight: '5px'
        }
    },
    filterButtons: {
        textAlign: 'left'
    },
    button: {
        borderRadius: 0,
        // '&>span': {
        //     marginRight: 'inherit !important'
        // },
        '&.yellow': {
            backgroundColor: '#FFED38'
        }
    },
    filteredButton: {
        '&:not(:last-child)': {
            // marginRight: '24px',
            marginRight: '1rem'
        },
        '&.active': {
            'background-color': 'rgba(0, 0, 0, 0.23)',
            border: 'none'
        }
    },
    productList: {
        // margin: '24px auto',
        '& $product': {
            overflow: 'hidden',
            '&:not(:first-child)': {
                'border-top': '1px solid #D6D6D6',
                'padding-top': '24px',
            },
            '&:not(:last-child)': {
                'padding-bottom': '24px',
            }
        }
    },
    product: {
        fontSize: '1rem',
        '&.product-name': {},
        // '&:not(:last-child)'
    },
    productName: {
        cursor: 'pointer'
    },
    productPhoto: {
        // width: '150px',
        width: 'calc(118px * 9 / 6)',
        height: 0,
        // paddingTop: '100px',
        paddingTop: '118px',
        marginRight: '24px',
        float: 'left',
        cursor: 'pointer',
        '&+div': {
            // float: 'left',
            overflow: 'hidden'
        }
    },
    detailRow: {
        width: 'calc(100% - 6px)',
        overflow: 'hidden',
        padding: '5px',
        lineHeight: '1.8rem',
        textAlign: 'left',
        '& > span': {
            float: 'left',
            marginRight: '1rem'
        },
        '& .price.text-red': {
            color: '#FF0007',
            fontSize: '1.3rem'
        }
    },
    businessName: {
        'font-size': '1.2rem',
        'font-weight': 'bold',
        color: '#595959',
        cursor: 'pointer'
    },
    projectDetailButton: {
        color: '#7A7979'
    },
    brandLogo: {
        padding: '1rem 1rem 0 0'
    },
    detailModalWrapper: {
        position: 'relative',
        // width: '100vw',
        width: '100%',
        // height: '100vh',
        height: '100%',
        '&>.btn-close': {
            position: 'absolute',
            // top: 'calc(2rem - 24px)',
            top: '24px',
            right: '24px'
        }
    },
    detailModal: {
        ...container,
        // display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        // alignItems: 'flex-start',
        // width: 'calc(100% - 15px * 2)',
        // 'margin-bottom': '2rem',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        'background-color': '#F4F4F9',
        padding: '0 !important',
        '& .product-title': {
            'background-color': '#E0E1E4',
            margin: 0,
            padding: '1rem .8rem',
        },
        '& .padding-row': {
            margin: 0,
            padding: '1rem .8rem',
        },
        '& .info-row': {
            'font-size': '.9rem',
            'line-height': '2rem'
        }
    },
    dialogBackground: {
        'background-color': 'rgba(0, 0, 0, 0.5)'
    },
    flexColumms: {
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'flex-start'
    },
    contactButton: {
        'border-radius': 0
    },
    invesloper: {
        margin: '1em 0'
    },
    utilList: {
        padding: 0
    },
    amenities: {
        margin: '1rem auto',
        padding: 0,
        display: '-webkit-box',
        overflow: 'hidden',
        'max-width': '100%',
        'max-height': 'calc(46px + 1rem)',
        'white-space': 'normal',
        'text-overflow': 'ellipsis',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        '&.expanded': {
            '-webkit-line-clamp': 'inherit',
            'max-height': 'inherit'
        },
        '&>p': {
            margin: 0
        }
    },
    description: {
        margin: '1rem auto',
        padding: 0,
        display: '-webkit-box',
        overflow: 'hidden',
        'max-width': '100%',
        'max-height': 'calc(1.8rem * 3)',
        'white-space': 'normal',
        'text-overflow': 'ellipsis',
        '-webkit-line-clamp': 3,
        '-webkit-box-orient': 'vertical',
        '&.expanded': {
            '-webkit-line-clamp': 'inherit',
            'max-height': 'inherit'
        },
        '&>p': {
            margin: 0
        }
    },
    expandDescLink: {
        cursor: 'pointer',
        'font-weight': 'bold',
        'text-decoration': 'underline',
    },
    mapLocation: {
        bottom: 0,
        padding: 0,
        margin: 0,
        left: 0,
        width: 'calc(100% + 24px * 2)',
        height: '350px !important',
        transform: 'translate(-24px, 24px)'
    }
});

function TabContainer({ children, className }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }} className={className}>
            {children}
        </Typography>
    );
}
  
class DetailTabs extends Component {
    state = {
        tab: 0,
        area: 50,
        price: 4,
        productTab: 'basic',
        isDetailModalShow: false,
        basicDetail: null,
        amenities: [],
        lang: localStorage.getItem('lang'),
        properties: {},
        amenitiesExpanded: false,
        descriptionExpanded: false,
        pagination: {
            sale: {},
            rent: {}
        },
    }

    async componentDidMount () {
        await this.fetchData({ group: 'sale' })({ page: 1, limit: 10 });
        await this.fetchData({ group: 'rent' })({ page: 1, limit: 10 });
    }
    
    shouldComponentUpdate() {
        return true;
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        await this.fetchData({ group: 'sale' })({ page: 1, limit: 10 });
        await this.fetchData({ group: 'rent' })({ page: 1, limit: 10 });
    }

    componentWillUnmount() {
        this.handleCloseDetailModal()
    }

    render() {
        const { className, classes, data, dictionary, language } = this.props;
        const { lang, tab, productTab, isDetailModalShow, basicDetail, properties, amenitiesExpanded, descriptionExpanded, pagination } = this.state;
        const dateOfBuilding = new Date(data.dateOfBuilding);
        const dateOfDelivery = new Date(data.dateOfDelivery);
        const hasProperties = Object.keys(properties).filter(p => properties[p].length > 0).length > 0;
        const amenitiesCols = [[], []];
        data.amenities.forEach((amns, i) => {
            const j = i % 2;
            amenitiesCols[j].push(amns);
        });
        return (
            <React.Fragment>
                <div className={classNames(className, classes.root)}>
                    <Tabs
                        value={hasProperties ? tab : 0}
                        onChange={this.handleTabChange}
                        classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                    >
                        <Tab
                            disableRipple
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label={dictionary['Project information']}
                        />
                        {properties['sale'] && properties['sale'].length > 0 && (
                            <Tab
                                disableRipple
                                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                label={`${dictionary['Unit']} (${dictionary['Sales']})`}
                            />
                        )}
                        {properties['rent'] && properties['rent'].length > 0 && (
                            <Tab
                                disableRipple
                                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                label={`${dictionary['Unit']} (${dictionary['Lease']})`}
                            />
                        )}
                    </Tabs>
                    {tab === 0 && (
                        <TabContainer className={classes.tabContent}>
                            <Grid container spacing={24}>
                                <Grid item xs>
                                    <div className={classNames(classes.groupBox, 'group-box')}>
                                        <span className={classNames(classes.legend, 'legend')}>{dictionary['Details']}</span>
                                        <Grid container>
                                            {/*<Grid item xs={12} md={6} className={classes.detailLine}>*/}
                                            {/*    /!* <span>Thời gian xây dựng: <b>{toDateString(dateOfBuilding, 'dd/mm/yyyy')}</b></span> *!/*/}
                                            {/*    <span>{dictionary['Construction time']}: <b>{dateOfBuilding.toLocaleDateString(lang)}</b></span>*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                {/* <span>Thời gian giao nhà: <b>{toDateString(dateOfDelivery, 'dd/mm/yyyy')}</b></span> */}
                                                <span>{dictionary['Estimated handover']}: <b>{dictionary['Quarter']} {Math.floor((dateOfDelivery.getMonth() - 1) / 3) + 1} - {dateOfDelivery.getFullYear()}</b></span>
                                            </Grid>
                                            {/* <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>Diện tích xây dựng: <b>2000m<sup>2</sup></b></span>
                                            </Grid> */}
                                            {/*<Grid item xs={12} md={6} className={classes.detailLine}>*/}
                                            {/*    <span>{dictionary['Building density']}: <b>{data.densityOfBuilding || 0}%</b></span>*/}
                                            {/*</Grid>*/}
                                            {data.numOfBlocks && (
                                                <Grid item xs={12} md={6} className={classes.detailLine}>
                                                    <span>{dictionary['Block']}: <b>{data.numOfBlocks}</b></span>
                                                </Grid>
                                            )}
                                            {data.numOfFloors && (
                                                <Grid item xs={12} md={6} className={classes.detailLine}>
                                                    <span>{dictionary['Floor']}: <b>{data.numOfFloors}</b></span>
                                                </Grid>
                                            )}
                                            {data.numOfProperties && (
                                                <Grid item xs={12} md={6} className={classes.detailLine}>
                                                    <span>{dictionary['Unit']}: <b>{data.numOfProperties}</b></span>
                                                </Grid>
                                            )}
                                            {data.floorArea && (
                                                <Grid item xs={12} md={6} className={classes.detailLine}>
                                                    {/* <span>{dictionary['Unit area']}: <b>{data.floorArea.min.value.toLocaleString(lang || 'en')} - {data.floorArea.max.value} {data.floorArea.max.unit.title}</b></span> */}
                                                    <span>{dictionary['Unit area']}: <b>{data.floorArea.min.value.toLocaleString(lang || 'en')} - {data.floorArea.max.value} m<sup>2</sup></b></span>
                                                </Grid>
                                            )}
                                            {/* <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>Diện tích bãi xe: <b>2000m<sup>2</sup></b></span>
                                            </Grid> */}
                                            {/*<Grid item xs={12} md={6} className={classes.detailLine}>*/}
                                            {/*    <span>{dictionary['Landscape']}: <b>{data.greenSpace || 0}%</b></span>*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12} md={6} className={classes.detailLine}>
                                                <span>{dictionary['Developer']}: <b>{data.inveslopers.length > 0 ? data.inveslopers[0].name : 'Chưa có'}</b></span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                            {data.amenities && data.amenities.length > 0 && (
                                <Grid container spacing={24}>
                                    <Grid item xs>
                                        <div className={classNames(classes.groupBox, 'group-box')}>
                                            <span className={classNames(classes.legend, 'legend')}>{dictionary['Amentity']}</span>
                                            <Grid container>
                                                <Grid item xs={12} className={classNames(classes.detailDefault, classes.amenities, amenitiesExpanded && 'expanded')}>
                                                    <Grid container>
                                                        <Grid item xs={12} md={6} className={classes.detailUtil}>
                                                            <List className={classes.utilList}>
                                                                {amenitiesCols[0].map(am => am.title && (
                                                                    <ListItem key={am._id} button>
                                                                        <ListItemIcon>
                                                                            <Icon>check</Icon>
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={am.title} />
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Grid>
                                                        <Grid item xs={12} md={6} className={classes.detailUtil}>
                                                            <List className={classes.utilList}>
                                                                {amenitiesCols[1].map(am => am.title && (
                                                                    <ListItem key={am._id} button>
                                                                        <ListItemIcon>
                                                                            <Icon>check</Icon>
                                                                        </ListItemIcon>
                                                                        <ListItemText primary={am.title} />
                                                                    </ListItem>
                                                                ))}
                                                            </List>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <a className={classes.expandDescLink} onClick={this.toggleCollapseDescription('amenitiesExpanded')}>{amenitiesExpanded ? dictionary['Collapse'] : dictionary['Expand']}</a>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={24}>
                                <Grid item xs>
                                    <div className={classNames(classes.groupBox, 'group-box')}>
                                        <span className={classNames(classes.legend, 'legend')}>{dictionary['Project introduction']}</span>
                                        <Grid container>
                                            <Grid item xs={12} className={classNames(classes.detailDefault, classes.description, descriptionExpanded && 'expanded')} dangerouslySetInnerHTML={{__html: data.description}}></Grid>
                                            <Grid item xs={12}>
                                                <a className={classes.expandDescLink} onClick={this.toggleCollapseDescription('descriptionExpanded')}>{descriptionExpanded ? dictionary['Collapse'] : dictionary['Expand']}</a>
                                            </Grid>
                                            {/* <Grid item xs={12}>
                                                <div className={classes.photoList}>
                                                    {data.photos.slice(0, 2).map((photo, index) => (
                                                        <div
                                                            key={`item-${photo._id || photo}-${index}`}
                                                            className={classNames(classes.imgItem, 'cover', 'large')}
                                                            style={{backgroundImage: `url(${restEndpoint}${photo.path})`}}
                                                            // onClick={() => this.handleIndexChange(index)}
                                                        ></div>
                                                    ))}
                                                    {data.photos.slice(2, data.photos.length).map((photo, index) => {
                                                        if(index > 2) return null;
                                                        let moreProps = {};
                                                        let leftItems = data.photos.length - 5;
                                                        if(index === 2 && leftItems > 0) moreProps['data-title'] = `${leftItems}+`;
                                                        return (
                                                            <div
                                                                key={`item-${photo._id || photo}-${index}`}
                                                                className={classNames(classes.imgItem, 'cover', index === 2 && leftItems > 0 && 'more-items')}
                                                                style={{backgroundImage: `url(${restEndpoint}${photo.path})`}}
                                                                // onClick={() => this.handleIndexChange(index)}
                                                                {...moreProps}
                                                            ></div>
                                                        );
                                                    })}
                                                </div>
                                            </Grid> */}
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                            {data.inveslopers && data.inveslopers.length > 0 && (
                                <Grid container spacing={24}>
                                    <Grid item xs>
                                        <div className={classNames(classes.groupBox, 'group-box')}>
                                            <span className={classNames(classes.legend, 'legend')}>{dictionary['Developer']}</span>
                                            {data.inveslopers.map(inv => (
                                                <Grid key={inv._id} container className={classes.invesloper}>
                                                    <Grid item xs={12} md={4} className={classes.brandLogo}>
                                                        <div className={classes.photoList}>
                                                            <div
                                                                className={classNames(classes.imgItem, 'cover', 'full-width')}
                                                                style={{backgroundImage: `url(${inv.photo ? `${restEndpoint}${inv.photo.path}` : noPhotoImage})`}}
                                                                // onClick={() => this.handleIndexChange(index)}
                                                            ></div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={8} className={classes.detailDefault}>
                                                        <span className={classes.businessName} onClick={this.showProjectsByInvesloper(inv._id)}>{inv.name}</span>
                                                        <div dangerouslySetInnerHTML={{__html: inv.description}}></div>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                            {/* {!!(data.location && data.location.location) && <MapLocation className={classes.mapLocation} location={{lat: data.location.location.latitude, lng: data.location.location.longitude}} />} */}
                            <MapLocation className={classes.mapLocation} location={!!(data.location && data.location.location) ? {lat: data.location.location.latitude, lng: data.location.location.longitude} : null} />
                        </TabContainer>
                    )}
                    {tab === 1 && properties['sale'] && properties['sale'].length > 0 && (
                        <TabContainer className={classes.tabContent}>
                            <div className={classes.productList}>
                                {properties['sale'].map(product => (
                                    <div key={product._id} className={classes.product}>
                                        <div className={classNames('cover', classes.productPhoto)} style={{backgroundImage: `url(${product.photos && product.photos.length > 0 ? `${restEndpoint}${product.photos[0].path}` : noPhotoImage})`}} onClick={() =>  this.showProductDetail(product)} />
                                        <div className={classes.productDetail}>
                                            <div className={classes.detailRow}>{dictionary['Unit']}: <b className={classes.productName} onClick={() =>  this.showProductDetail(product)}>{product.title}</b></div>
                                            <div className={classes.detailRow}>
                                                <RenderProductUtilInList data={product} />
                                            </div>
                                            <div className={classes.detailRow}>
                                                <span>{dictionary['Price']}: <b className="price text-red">{product.price.value.toLocaleString(language || 'en')} {product.price.currency.name}</b></span>
                                                <Button variant="contained" className={classNames(classes.button, 'yellow', 'floated right')}>
                                                    {dictionary['Contact']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {pagination['sale'].total > 0 && pagination['sale'].pages > 1 && (
                                <Pagination
                                    className="border-top-0"
                                    // onRef={p => this.pagination = p}
                                    {...pagination['sale']}
                                    onChange={this.fetchData({ group: 'sale' })}
                                    // location={this.props.location}
                                    // pathname={this.props.location.pathname}
                                />
                            )}
                        </TabContainer>
                    )}
                    {tab === 2 && properties['rent'] && properties['rent'].length > 0 && (
                        <TabContainer className={classes.tabContent}>
                            <div className={classes.productList}>
                                {properties['rent'].map(product => (
                                    <div key={product._id} className={classes.product}>
                                        <div className={classNames('cover', classes.productPhoto)} style={{backgroundImage: `url(${product.photos && product.photos.length > 0 ? `${restEndpoint}${product.photos[0].path}` : noPhotoImage})`}} onClick={() =>  this.showProductDetail(product)} />
                                        <div className={classes.productDetail}>
                                            <div className={classes.detailRow}>{dictionary['Unit']}: <b className={classes.productName} onClick={() =>  this.showProductDetail(product)}>{product.title}</b></div>
                                            <div className={classes.detailRow}>
                                                <RenderProductUtilInList data={product} />
                                            </div>
                                            <div className={classes.detailRow}>
                                                <span>{dictionary['Price']}: <b className="price text-red">{product.price.value.toLocaleString(language || 'en')} {product.price.currency.name}{product.price.unit ? ` / ${product.price.unit.title}` : ''}</b></span>
                                                <Button variant="contained" className={classNames(classes.button, 'yellow', 'floated right')}>
                                                    {dictionary['Contact']}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {pagination['rent'].total > 0 && pagination['rent'].pages > 1 && (
                                <Pagination
                                    className="border-top-0"
                                    // onRef={p => this.pagination = p}
                                    {...pagination['rent']}
                                    onChange={this.fetchData({ group: 'rent' })}
                                    // location={this.props.location}
                                    // pathname={this.props.location.pathname}
                                />
                            )}
                        </TabContainer>
                    )}
                </div>
                <Dialog
                    fullScreen
                    open={isDetailModalShow}
                    onClose={this.handleCloseDetailModal}
                    TransitionComponent={Transition}
                    classes={{
                        paper: classes.dialogBackground
                    }}
                >
                    <div className={classes.detailModalWrapper}>
                        <IconButton className={classNames('btn-close', s.btnClose)} aria-label="Close" onClick={this.handleCloseDetailModal}>
                            <Icon>close</Icon>
                        </IconButton>
                        <Grid container direction="row" className={classes.detailModal}>
                            <Grid item xs={12} sm={8}>
                                {basicDetail && basicDetail.photos && basicDetail.photos.length > 0 && <PhotoGallery data={basicDetail.photos} />}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className={classes.flexColumms}>
                                    <Grid item xs={12}>
                                        <p className="product-title">{basicDetail && basicDetail.title}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="padding-row">
                                            {basicDetail && basicDetail.utils && <RenderProductUtilInMOdal data={basicDetail} />}
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                    <Grid item>
                                        <div className="padding-row">
                                            <Button variant="contained" color="primary" fullWidth className={classes.contactButton}>
                                                {dictionary['Contact']}
                                            </Button>
                                        </div>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }

    fetchData = ({ group }) => async ({ page, limit }) => {
        const { properties } = this.state;
        await this.getProperties({ group }, { pagination: { page, limit } });
        if(Object.keys(properties).filter(p => properties[p].length > 0).length > 0) {
            this.setState({
                productTab: Object.keys(properties)[0] || null
            });
        } else this.setState({
            tab: 0
        });
    }

    toggleCollapseDescription = descriptionExpanded => () => {
        this.setState({
            [descriptionExpanded]: !this.state[descriptionExpanded]
        });
    }
    
    // toggleCollapseDescription = () => {
    //     const { descriptionExpanded } = this.state;
    //     this.setState({
    //         descriptionExpanded: !descriptionExpanded
    //     });
    // }
    
    getProperties = ({ group }, { pagination }) => {
        const { page, limit } = pagination;
        return request('/properties', {
            params: {
                project: this.props.data._id,
                group,
                page,
                limit
            },
            headers: {
                'language': localStorage.getItem('lang'),
            }
        })
        .then(res => {
            if(!res.stack) {
                if(res.status >= 400);
                else {
                    this.setState({
                        properties: {
                            ...this.state.properties,
                            [group]: res.body.result
                        },
                        pagination: {
                            ...this.state.pagination,
                            [group]: res.body.meta
                        }
                    });
                }
            }
            // else dispatch(failure('Vui lòng kiểm tra kết nối mạng.'));
        }).catch(err => {
            // notify({
            //     variant: 'error',
            //     message: err.message
            // });
        });
    }

    handleTabChange = (event, value) => {
        this.setState({ tab: value });
    }

    handleAreaChange = (event, value) => {
        this.setState({ area: value });
    }

    handlePriceChange = (event, value) => {
        this.setState({ price: value });
    }

    activeProductTab = (productTab) => () => this.setState({
        productTab
    })

    showProductDetail = ({  _id, title }) => {
        browserHistory.push(`/real-estate/${toHyphens(title)}-${_id}`);
    }

    showProjectsByInvesloper = (invesloperId) => () => {
        browserHistory.push(`/projects?invesloper=${invesloperId}`);
    }

    handleOpenDetailModal = basicDetail => {
        this.setState({ isDetailModalShow: true, basicDetail });
    }
    
    handleCloseDetailModal = () => {
        this.setState({ isDetailModalShow: false });
    }
}

export default withStyles(styles)(DetailTabs);
