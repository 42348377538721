import * as types from './constants';
import initialState from './initialState';

export default(state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST_START:
            return {
                ...state,
                ...action.payload
            };
        case types.UPLOAD_PHOTO_SUCCESS:
            return {
                ...state,
                ...action.payload
            };
        case types.ERROR:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}