import {combineReducers} from 'redux';
import toast from 'common/toast/reducer';
import listing from './views/layout/partials/contact-overlay/reducer';
// import session from 'session/reducer';

const rootReducer = combineReducers({
    toast,
    listing
    // session
});

export default rootReducer;